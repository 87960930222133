import React from 'react';
import Menu from '../../common/Menu';
import  { useEffect } from 'react';
import Footer from '../../common/Footer'
import './telecom.css';
import tone from './../../images/tone.png'
import sme from './../../images/smes.png'
import emerg from './../../images/emreg-tech.avif'
import auto from './../../images/automation-left.jpg'
import girl from './../../images/robot_ani.gif'
import remote from './../../images/remote-testing.gif'
import iphone from './../../images/iphone-mock.png'
import telcomeleads from './../../images/automation-testing-services.jpg'
import Location from '../../common/contact/Location';
import cybersml from './../../images/lock.jpg'
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Map from '../../subpages/Map';
import Book from './book';
function AutomationLeads() {


    
  
return (
<>
<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Cyber Security Services in USA, UK, UAE | IT Security Solutions" />
	<meta property="og:description" content="Procal offers cybersecurity consulting services &amp; solutions in USA, UK, UAE, to embed security across your ecosystem to reduce risk in the future by securing today" />
	<meta property="og:url" content="https://www.procal.ai/solutions/cybersecurity-leads/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>


<Menu />



<section className='1telecom-sec-6 pt100 pb-5 tw-bg-gradient-to-r tw-from-slate-100 tw-to-slate-200' id='cybersecurity'>
<div className="hero-container " id="hero-sec">
  <div className="container-fluid ">
    <div className="row d-flex">
      <div className="col offset-lg-1 col-lg-4 align-middle">
        <div className="px-2 py-2">
          <img
            src={telcomeleads}
            className="img-fluid"
            alt="..."
          />
        </div>
      </div>
      <div className="col col-lg-6">
        <div className="px-5 py-5">
          <div className="px-2 py-2 align-middle">
        
            <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Welcome to Procal</h2>
            <p>
            With over 15 years of experience, Procal Technologies empowers businesses through cutting-edge technology services that drive growth and innovation. Our customer-centric approach ensures that we deliver transformative and scalable solutions tailored to your unique needs. We collaborate closely with our clients, leveraging our expertise and commitment to excellence to help you achieve your strategic objectives and gain a competitive edge in the digital landscape.
            </p>
          </div>
          {/* <div className="px-2 py-2">
            <button type="button" className="btn btn-outline-primary">
              Checkout Our Courses
            </button>
          </div> */}
        </div>
      </div>
    </div>


  </div>
  {/* main container */}
  <div className="main-container ">
  <div className="card-container " id="team">
    <div className="container px-3 py-5">
      <div className="row center mx-4 my-4 text-black text-center">
        <h2></h2>
        <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Automation Testing Services</h2>
        <p>Comprehensive Automation Testing Services to Ensure Your Success</p>
      </div>
      <div className="container">

  <div className="row mb-5">
    <div className="col-md-3 offset-lg-3">
    <div className="dark:tw-bg-gray-950">
  <div className="tw-max-w-md tw-rounded-3xl tw-p-px tw-bg-gradient-to-b tw-from-blue-300 tw-to-pink-300 dark:tw-from-blue-800 dark:tw-to-purple-800">
    <div className="tw-rounded-[calc(1.5rem-1px)] tw-p-10 tw-bg-white dark:tw-bg-gray-900">
    <img
          src={remote}
          className="card-img-top"
          alt="..."
        />
    <h5 className="card-title">Automation and Remote Testing</h5>
          <p className="card-text">
          Automated testing is a technique that leverages software tools and frameworks to automate the execution of test cases and validate the behavior and functionality of software applications.
          </p>
 
          <Link to="/solutions/telecom" className="btn btn-sm btn-primary">  Read More.</Link>
 
    </div>
  </div>
</div>

    </div>

    <div className="col-md-3">
    <div className="dark:tw-bg-gray-950">
  <div className="tw-max-w-md tw-rounded-3xl tw-p-px tw-bg-gradient-to-b tw-from-blue-300 tw-to-pink-300 dark:tw-from-blue-800 dark:tw-to-purple-800">
    <div className="tw-rounded-[calc(1.5rem-1px)] tw-p-10 tw-bg-white dark:tw-bg-gray-900">
    <img
          src={girl}
          className="card-img-top"
          alt="..."
        />
    <h5 className="card-title">Robot Processing Automation</h5>
          <p className="card-text">
          With the increasing demand for Robotic Process Automation (RPA) services, organizations face the challenge of effectively automating their business processes to improve efficiency and productivity.
          </p>
 
          <Link to="/solutions/telecom" className="btn btn-sm btn-primary">  Read More.</Link>
 
    </div>
  </div>
</div>

    </div>



  </div>



</div>

    </div>
  </div>
  </div>
  {/*  Card container  */}

  {/* <div class="aside-container">
  <div class="container-fluid">
 <aside>
<p>The Epcot center is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
</aside>
</div>
</div> */}
  {/*  testimonals container  */}

  {/* overlay box */}
  {/*   <div class="mt-5 mx-1">
  <div class="card bg-black text-white ">
  <div class="card-body px-4 py-4">
    
    </div>
   </div>
  </div> */}
  {/*  banner container  */}
  <div className="banner-container mt-5 mb-5" id="featured">
    <div className="container-fluid px-4 py-4">
      <div className="card border-none shadow-none">
      <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Why Choose Procal Technologies?</h2>
        <div className="card-body">
          {/*     <h5 class="card-title">Special Teachers for Students</h5>
    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
    <a href="#" class="btn org-btn center">Learn More</a> */}
          <div className="conatiner">
            <div className="row d-flex justify-content-around">
              <div className="col ">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Advanced Technologies</div>
                  <div className="card-body">
              
                    <p className="card-text">
                    We use the latest tools and methodologies to provide top-notch automation testing services.
                    </p>
                    {/*     <a href="#" class="btn btn-outline-primary center">View our curriculum</a> */}
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Cutting-Edge Technology</div>
                  <div className="card-body">
 
                    <p className="card-text">
                    We leverage the latest advancements in technology to provide innovative solutions that keep our clients ahead of the curve.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Experienced Professionals</div>
                  <div className="card-body">

                    <p className="card-text">
                    Our team of experts has extensive experience and industry recognition.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Customer-Centric Approach</div>
                  <div className="card-body">

                    <p className="card-text">
                    We tailor our solutions to meet your unique needs, ensuring maximum impact and satisfaction.
                    </p>
                  </div>
                </div>
              </div>

              <div className='clear-fix'></div>

              <div className="col">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Scalable Solutions</div>
                  <div className="card-body">

                    <p className="card-text">
                    Our services are designed to grow with your business, providing ongoing support and enhancements.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Cost-Effective Services</div>
                  <div className="card-body">

                    <p className="card-text">
                    We deliver high-quality solutions at competitive prices, ensuring you get the best value for your investment.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


{/* tailwind html */}
<div class="tw-bg-white tw-py-24 sm:tw-py-32">
  <div class="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">
    <div class="tw-mx-auto tw-max-w-2xl lg:tw-mx-0">
      <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Industry Expertise</h2>
      <p class="tw-mt-2 tw-text-lg/8 tw-text-gray-600">With extensive experience across various industries, we understand the unique cybersecurity challenges each sector faces. Trust us to safeguard your organization’s digital assets, regardless of your industry.</p>
    </div>
    <div class="tw-mx-auto tw-mt-10 tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-11 tw-border-t tw-border-gray-200 tw-pt-10 sm:tw-mt-16 sm:tw-pt-16 lg:tw-mx-0 lg:tw-max-w-none lg:tw-grid-cols-4">
      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-medium tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm">Healthcare</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Procal is at the forefront of exceptional services in the healthcare and life sciences sector. Our company offers a comprehensive suite...</p>
        </div>
    
      </article>
      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-medium tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm tw-text-blue-800">Finance</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Procal is a trusted provider of comprehensive services tailored to meet the specific needs of the manufacturing and supply chain sector...</p>
        </div>
    
      </article>

      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-medium tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm">E-Commerce</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Procal is a leading provider of specialized solutions for companies in the retail and consumer goods sector, catering to their distinct requirements...</p>
        </div>
    
      </article>

      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-large tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm">Education</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Support the functionality and usability of educational platforms.</p>
        </div>
    
      </article>
      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-large tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm">Travel and Hospitality</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Optimize your travel and booking systems for the best user experience.</p>
        </div>
    
      </article>
    </div>

    
  </div>
</div>



{/* tailwind html end */}
</div>

</section>

<Book/>

<Footer />
</>
)
}

export default AutomationLeads
