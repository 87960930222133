import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/sports-left.jpg'
import Book from './book';
import fea2 from './../../images/sport-side.jpg'
import fea4 from './../../images/sport-center.png'

import Slider from "react-slick";
import './../solutions/robot-processing.css'
import './../solutions/sport-analytics.css'


import cloud from './../../images/network-ana.jpg'

import cutting from './../../images/sport-slide.png'


// apple ani end
function SportAnalytics() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)

.from('.image-box-center img', {
    yPercent: 15,
    ease: "none",
    scrollTrigger: {
    trigger: ".outline-box-center",
    start: "top center",
    scrub: 1,
    
    },
    ease: 'power.inOut',
    }, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Sport Analytics | Procal" />
	<meta property="og:description" content="Procal is a leading provider of cutting-edge sports analytics solutions, catering to professional and collegiate teams and leagues across the globe, including the NBA, NFL, and MLB. Our comprehensive suite of tools and services empowers teams to extract valuable insights from complex data, enabling them to make informed decisions and gain a competitive edge." />
	<meta property="og:url" content="https://www.procal.ai/solutions/sport-analytics/" />
	<meta property="og:site_name" content="Procal Technologies" />
  </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
Sport 
</h1>

<h2 class="title-outline">
Analytics
</h2>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
</div>
</div>
<div className='col-lg-5'>
<div className='sidetext'>
<div class="image-box">
<img src={girl} className='img-fluid' />     
</div>
</div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
<div class="container">

<div class="row d-flex align-items-center1">


<div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
<img
ref={imageRef}
src={cloud}
alt="Your Image"
className="img-fluid"
/>
</div>

<div class="col-md-8 col-lg-8">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Sport Analytics</h2>
<p>Procal is a leading provider of cutting-edge sports analytics solutions, catering to professional and collegiate teams and leagues across the globe, including the NBA, NFL, and MLB. Our comprehensive suite of tools and services empowers teams to extract valuable insights from complex data, enabling them to make informed decisions and gain a competitive edge.</p>

<p>At Procal, we understand the immense significance of data in the realm of sports. Our expertise allows us to seamlessly integrate with existing data sources, including game footage, player statistics, and other relevant information, to generate a holistic view of performance metrics. By leveraging advanced algorithms and machine learning techniques, including computer vision (CV), natural language processing (NLP), and other predictive models, we transform raw data into actionable insights, facilitating evidence-based decision-making and strategy development.</p>

<p>Our sports analytics solution provides teams with in-depth player and team analysis. We delve into individual player performance metrics, such as shooting efficiency, defensive effectiveness, and overall contribution to the team's success. Our sophisticated models, built using ML techniques, can identify patterns and trends within the data, allowing teams to identify strengths and weaknesses, optimize player rotations, and devise tailored training regimens. By analyzing team-level data, including offensive and defensive strategies, play execution, and performance against different opponents, we help teams identify areas for improvement, enhance overall team efficiency, and develop game plans.</p>

<p>In addition to player and team analysis, our sports analytics solution offers advanced ball and player tracking capabilities. Through our cutting-edge technology, we track the movement of the ball and capture detailed metrics such as shot trajectory, spin rate, and ball speed. This information, combined with our ML models, allows coaches and players to analyze shooting accuracy, passing efficiency, and court positioning, giving them a comprehensive understanding of ball movement and player interactions. Such insights can be instrumental in refining offensive and defensive strategies, improving ball distribution, and maximizing scoring opportunities.</p>





</div>
</div>

<div class="col-md-4 col-lg-4 image-box">
<img src={fea2} className='img-fluid'  />
</div>

<div class="col-md-12 col-lg-12">






</div>
</div>
</div>
</section>

{/* center section */}
<div class="container-rze u-position-21c">
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <h2 class="style-GAVo4" id="style-GAVo4">
                <div class="1text-vn6 sidefont" >At the heart</div>
                <div class="1snipcss0-4-5-7 sidefont" > of our data analytics</div>
                <div class="1text-ggq sidefont"> solutions</div>
            </h2>
        </div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-z42">
            <h3 class="des-5xp text-ggq" style={{textAlign: 'left'}}>Driving Innovation with Our Cutting-Edge Data Analytics Solutions</h3>
        </div>
    </div>
    <div class="row-8oo">
        <div class="col-wjg col-b63"></div>
        <div class="col-wjg col-b63">
            <div class="u-img-a9d image-box-center"><img src={fea4} class="u-img-hcf" /></div>
        </div>
        <div class="col-wjg col-b63"></div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-s8h" ></div>
        <div class="col-wjg col-e7o">
            <p class="style-CgO1k" id="style-CgO1k"></p>
            <div class="text-vn6 ygqvp" >Unlocking Performance</div>
            <div class="text-vn6 ygqvp" > Insights </div>
            <div class="text-vn6 ygqvp" > with Sports</div>
          
            <div class="text-vn6 ygqvp" > Analytics</div>
        </div>
    </div>
    <div class="row-jsz u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <p class="style-RWfkB" id="style-RWfkB"></p>
            <div class="ygqvp" >from </div>
            <div class="ygqvp " > start</div>
            <div class="ygqvp" >to </div>
            <div class="ygqvp" > finish</div>
            <p></p>
        </div>
    </div>
</div>
{/* center section end */}
<section className="container-rom container-sport ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fhi">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
<h2 className="gb-headline-6nz">Sports analytics solution</h2>
<p className="col-oe2" style={{ fontSize: 16 }}>
Sports analytics solutions leverage data-driven insights to enhance performance, strategy, and decision-making in sports. By analyzing player statistics, game patterns, and real-time data, teams can optimize training, tactics, and overall gameplay. These solutions help coaches, athletes, and management make informed decisions, leading to improved results and competitive advantages. With advancements in AI and machine learning, sports analytics continues to revolutionize the way teams operate and succeed.
</p>


</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Sport Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Data Collection and Integration</h4>

<p>Procal's data collection and integration capabilities encompass aggregating data from diverse sources, including sports events, wearable devices, social media, and online platforms. The process entails seamless integration and standardization of data formats, enabling comprehensive analysis. By efficiently gathering information from these channels, we establish a comprehensive data repository for further examination and insights.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Sport Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Predictive Modeling</h4>

<p>At Procal, we possess the expertise to create advanced predictive models leveraging historical data, enabling us to accurately forecast future game outcomes, player performance, and team rankings. Our cutting-edge models harness machine learning algorithms like regression, classification, and time series analysis, empowering us to make precise predictions.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Sport Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Performance Analysis</h4>

<p>Procal offers comprehensive solutions for assessing individual and team performance by analyzing player statistics, game footage, and performance metrics. Our advanced technology allows us to identify key performance indicators (KPIs), evaluate player efficiency, and make insightful comparisons across various players or teams. With Procal's robust evaluation capabilities, we provide a deeper understanding of player and team dynamics, enabling better decision-making and strategic planning.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Sport Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Ball & Player Tracking</h4>

<p>Procal's sports analytics solution incorporates advanced ball and Player tracking capabilities. Through cutting-edge technology, we track the movement of the ball and capture detailed metrics such as shot trajectory, spin rate, and ball speed. Our sports tracking data include 2D spatial coordinates of player locations and 3D spatial coordinates of ball locations. Our sophisticated algorithms analyze this information to provide insights into shooting accuracy, passing efficiency, and court positioning. By understanding ball movement and player interactions at a granular level, teams can refine offensive and defensive strategies, improve ball distribution, and maximize scoring opportunities</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Sport Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Fan Engagement and Marketing</h4>

<p>Procal is primed to harness the power of sports analytics to revolutionize fan engagement and optimize marketing strategies. By delving deep into fan behavior analysis, leveraging social media sentiment analysis, and tailoring personalized marketing campaigns to individual preferences, Procal can create an immersive and unforgettable experience for sports enthusiasts. Our cutting-edge approach ensures that fans feel truly connected, fostering a stronger bond between them and their favorite teams. With Procal's advanced analytics, we redefine the future of fan engagement and open up new avenues for marketing success.</p>

</div>
</div>
</div>



</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default SportAnalytics
