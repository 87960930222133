import React from 'react';
import Menu from '../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from '../../common/Footer'
import girl from './../../images/application-top.jpg'
import Book from './book';
import fea2 from './../../images/app-center.jpg'
import fea4 from './../../images/application-center.jpg'

import Slider from "react-slick";
import './../solutions/managed-security.css'
import './../solutions/vulnerability.css'

import cloud from './../../images/threat-slide.jpg'

import cutting from './../../images/app-slide.jpg'


// apple ani end
function ApplicationSecurity() {



    const imageRef = useRef(null);
    const ref = useRef(null);

    useGSAP(() => {

        gsap.registerPlugin(ScrollTrigger);

        const targets = document.querySelectorAll('.list li');

        gsap.set('.list', { autoAlpha: 1 });

        const timeline = gsap.timeline({
            defaults: {
                overwrite: 'auto',
                ease: 'power1',
            },
            scrollTrigger: {
                end: '+=300%',
                pin: true,
                pinSpacing: true,
                scrub: true,
                start: 'top top',
                trigger: '.list',
            },
        });

        const stagger = 0.8;
        const duration = 1;

        gsap.set('.list', { autoAlpha: 1 });

        timeline.set(targets, { transformOrigin: 'center bottom' });

        timeline
            .from(targets, {
                duration: duration,
                opacity: 0,
                rotationX: 0,
                rotationY: 0,
                scale: 1,
                stagger: stagger,
                yPercent: 90,
            })
            .to(
                targets,
                {
                    duration: duration,
                    opacity: 0,
                    rotationX: 20,
                    rotationY: 2,
                    scale: 0.75,
                    stagger: stagger,
                    y: -100,
                },
                stagger
            );

        // Animation paragraph

        const element = ref.current;

        const pl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: 'top bottom-=20%',
                end: 'bottom top-=20%',
                scrub: true,
            },
        });

        pl.from(element, {
            opacity: 0,
            x: -100,

            duration: 1,
            ease: 'power2.out',
        });


        // Animation for the image reveal effect
        gsap.from(imageRef.current, {
            clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
            duration: 0.5, // Adjust duration
            ease: 'power4.in', // Adjust easing
            scrollTrigger: {
                trigger: '.erer',
                start: "top bottom",
                end: ".z-index-fyv",
                scrub: true,
                onUpdate: self => {
                    const progress = self.progress;
                    const points = [
                        `0% 0%`,
                        `${progress * 100}% 0%`,
                        `${progress * 100}% 100%`,
                        `0% 100%`
                    ];
                    gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
                },
            },
        });




        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.section-head-outline',
                start: 'top top',
                end: 'bottom top',
                scrub: 0.2,
            },
            ease: 'power.inOut',
        });

        tl.to('.title-outline', {
            yPercent: 100,
        })
            .to('.title--hello', {
                yPercent: 100,
            }, 0)
            .to('.title--outline', {
                yPercent: (i) => (i + 1) * 15 + 100,
            }, 0)
            .from('.image-box img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)

            .from('.image-box-center img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box-center",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)
            .from('.image-box0 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box0",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)


            .from('.image-box1 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box1",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box2 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box2",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box3 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box3",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0);



    });

    // slick slider code start
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings2 = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        waitForAnimate: false
    };
    // slick slider end
    useEffect(() => {


    });




    return (
        <>

            <Helmet>
                <title>AI and machine learning  | Procal</title>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Application Security" />
                <meta property="og:description" content="In today's digital era, where software applications drive business success, securing your applications is paramount. Procal specializes in robust application security, protecting your software from diverse threats, from data breaches to unauthorized access. Our solutions defend against vulnerabilities, ensuring compliance, and safeguarding sensitive information." />
                <meta property="og:url" content="https://www.procal.ai/application-security/" />
                <meta property="og:site_name" content="Procal Technologies" />
            </Helmet>

            <Menu />




            <section className='relative1  five-sec-2 start'>


                <div className='container'>
                    <div className="outline-box">
                        <section className="section-outline section-head-outline">
                            {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


                            <div className='col-lg-7'>
                                <div class="section-head__title flex-basis-666">

                                    <h1 class="title-outline">
                                        Application
                                    </h1>

                                    <h2 className="title-outline">
                                        Security
                                    </h2>
                                    <span className="title--outline green">
                                        Security
                                    </span>
                                    <span className="title--outline green">
                                        Security
                                    </span>
                                    <span className="title--outline green">
                                        Security
                                    </span>
                                    <span className="title--outline green">
                                        Security
                                    </span>
                                    <span className="title--outline green">
                                        Security
                                    </span>
                                    <span className="title--outline green">
                                        Security
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='sidetext'>
                                    <div className="image-box">
                                        <img src={girl} className='img-fluid' />
                                    </div>
                                </div></div>


                        </section>
                    </div>





                </div>


            </section>

            <section className="container iot-sec4 ptop  pb-5 erer">
                <div className="container">

                    <div className="row d-flex align-items-center1">


                        <div className="col-md-12 col-lg-12" style={{ display: 'none' }}>
                            <img
                                ref={imageRef}
                                src={cloud}
                                alt="Your Image"
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-8 col-lg-8">
                            <div className="txt-block right-column wwhite-color wow fadeInLeft" >
                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Application Security</h2>
                                <p>In today's digital era, where software applications drive business success, securing your applications is paramount. Procal specializes in robust application security, protecting your software from diverse threats, from data breaches to unauthorized access. Our solutions defend against vulnerabilities, ensuring compliance, and safeguarding sensitive information.</p>

                                <p>At Procal, we grasp the complexity of application security's role in your software's success. Our commitment lies in delivering innovative solutions that empower organizations to fortify applications and preserve digital assets.</p>

                                <p>We offer a comprehensive suite of tools and expert services to identify and address security vulnerabilities, fostering a security culture within your organization. Partnering with Procal instills confidence that your applications can withstand evolving security threats, maintaining customer trust and your brand's reputation.</p>




                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 image-box">
                            <img src={fea2} className='img-fluid' alt='app center' />
                        </div>

                        <div className="col-md-12 col-lg-12">






                        </div>
                    </div>
                </div>
            </section>

            {/* center section */}
            <div className="container-rze u-position-21c">
                <div className="row-jsz u-mb-17o u-z-index-vag">
                    <div className="col-wjg col-phw"></div>
                    <div className="col-wjg col-e7o">
                        <h2 className="style-GAVo4" id="style-GAVo4">
                            <div className="1text-vn6 sidefont" >application security's</div>
                            <div className="1snipcss0-4-5-7 sidefont" > role in your</div>
                            <div className="1text-ggq sidefont">  software's success</div>
                        </h2>
                    </div>
                </div>
                <div className="row-jsz u-mb-17o u-z-index-vag outline-box-center">
                    <div className="col-wjg col-phw"></div>
                    <div className="col-wjg col-z42">
                        <h6 className="des-5xpt-ggq" >Solutions that empower organizations to fortify applications and preserve digital assets.</h6>
                    </div>
                </div>
                <div className="row-8oo">
                    <div className="col-wjg col-b63"></div>
                    <div className="col-wjg col-b63">
                        <div className="u-img-a9d image-box-center"><img src={fea4} className="u-img-hcf" alt='app center' /></div>
                    </div>
                    <div className="col-wjg col-b63"></div>
                </div>
                <div className="row-jsz u-mb-17o u-z-index-vag">
                    <div className="col-wjg col-s8h" ></div>
                    <div className="col-wjg col-e7o">
                        <p className="style-CgO1k" id="style-CgO1k"></p>
                        <div className="text-vn6 ygqvp" >Our solutions defend</div>
                        <div className="text-vn6 ygqvp " > against vulnerabilities,   </div>
                        <div className="text-vn6 ygqvp"  >ensuring compliance </div>
                        <p></p>
                    </div>
                </div>
                <div className="row-jsz u-z-index-vag">
                    <div className="col-wjg col-phw"></div>
                    <div className="col-wjg col-e7o">
                        <p className="style-RWfkB" id="style-RWfkB"></p>
                        <div className="ygqvp text-ggq" >from start</div>
                        <div className="ygqvp" >to finish</div>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* center section end */}
            <section className="container-rom container-vul ali-fxl">
                <div className="container-d1s">
                    <div className="container-rom container-2e2">
                        <div className="container-d1s">
                            <div className="wrapper-ti2">
                                <div className="column-o8o column-fhi">
                                    <section className="container-rom container-coh ani-lcm fa-6jh">
                                        <div className="container-d1s" style={{ backgroundColor: '#898989', padding: '28px' }}>
                                            <h2 className="gb-headline-6nz">Maintaining customer trust</h2>
                                            <p className="col-oe2" style={{ fontSize: 16 }}>
                                                Unlock the full potential of your applications with Procal's expert application security practices. Our tailored approach encompasses a spectrum of activities designed to safeguard your software and fortify its resilience
                                            </p>



                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* whats new section */}
            <section className="container sec10 py-5 p-5 pb-5">
                <div className="row py-lg-5">
                    <div className="col-lg-12 col-md-8 mx-auto">


                        <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


                    </div>


                </div>

                {/* slicky slider */}
                <div className="slider-container">

                    <Slider {...settings2}>



                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Precision Code Review and Static Analysis</h4>

                                    <p>Thoroughly examining and analyzing code using state-of-the-art tools to ensure the highest level of precision in identifying vulnerabilities and weaknesses within the software.</p>

                                </div>


                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Dynamic Application Security Testing (DAST)</h4>

                                    <p>Conducting real-world attack simulations and dynamic testing to meticulously discover vulnerabilities, providing a comprehensive assessment of the application's security posture.</p>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Strategic Penetration Testing</h4>

                                    <p>Employing ethical hacking techniques to systematically identify and fortify vulnerabilities, ensuring a robust and strategic approach to enhancing overall security resilience.</p>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Empowering Training and Awareness</h4>

                                    <p>Regularly conducting in-depth training sessions aimed at instilling secure coding practices, fostering heightened awareness among team members, and empowering them with the knowledge to navigate security challenges effectively.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Agile Vulnerability Management</h4>

                                    <p>Rapidly identifying and prioritizing vulnerabilities, coupled with swift and agile patching procedures, to enhance the overall security stance by effectively addressing potential risks in a timely manner.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Advanced Authentication and Authorization Controls</h4>

                                    <p>Implementing sophisticated authentication methods and precise authorization controls to establish a robust framework that safeguards against unauthorized access and ensures the integrity of user permissions.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Cutting-edge Data Encryption</h4>

                                    <p>Employing cutting-edge encryption techniques to secure sensitive data and establish secure transmission channels, providing an advanced layer of protection against unauthorized access and potential breaches.</p>


                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Precision Code Review and Static Analysis" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Seamless Security Patching</h4>

                                    <p>Providing timely updates and patches to fortify applications against known vulnerabilities, ensuring a seamless process that minimizes potential risks and strengthens the overall security posture.</p>


                                </div>
                            </div>
                        </div>




                    </Slider>
                </div>

                {/* slicky slider end */}



            </section>


            {/* whats new section end */}



            <Book />
            <Footer />
        </>
    )
}

export default ApplicationSecurity
