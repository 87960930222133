import React from 'react';
import Menu from './../../common/Menu';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import fea5 from './../../images/fea_5.png'
import fea6 from './../../images/fea_6.png'
import fea7 from './../../images/fea_7.png'
import './../solutions/mobile.css'
import banking from './../../images/fea_3.png'
import Book from './book';

import mockupall from './../../images/smart-city.jpg'
import SmartcityExpert from './smartcityexpert';
// apple ani end
function SmartCity() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.out', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: "center center",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)
      .from('.image-box0 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box0",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)


      .from('.image-box1 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box1",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box2 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box2",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box3 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box3",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0);



  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });




  return (
    <>
<Helmet>
<title>Smart City Solutions | IoT based Solutions for Urban & Metropolitan</title>
<meta name="description" content="Procal is a leading provider of customized and cost-efficient smart city solutions. With a strong focus on innovation and sustainability, we empower cities to enhance their infrastructure and services through our cutting-edge technologies." />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Smart City Solutions | IoT based Solutions for Urban &amp; Metropolitan" />
	<meta property="og:description" content="Procal is a leading provider of customized and cost-efficient smart city solutions. With a strong focus on innovation and sustainability, we empower cities to enhance their infrastructure and services through our cutting-edge technologies." />
	<meta property="og:url" content="https://www.procal.ai/solutions/smart-city/" />
	<meta property="og:site_name" content="Procal Technologies" />
  </Helmet>
      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div>  */}



              <div class="section-head__title flex-basis-666">
                <div class="image-box">
                  {/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
                </div>
           
                <h1 class="title-outline">
                SMART 
                </h1>
          

                <h2 class="title-outline">
                CITY
                </h2>
                <span class="title--outline green">
                CITY
                </span>
                <span class="title--outline green">
                CITY
                </span>
                <span class="title--outline green">
                CITY
                </span>
                <span class="title--outline green">
                CITY
                </span>
                <span class="title--outline green">
                CITY
                </span>
                <span class="title--outline green">
                CITY
                </span>
              </div>
              <div className='sidetext'>
                <div class="image-box imgprop">
                  <img src={mockupall } className='img-fluid' />

                </div>
              </div>

            </section>
          </div>





        </div>


      </section>

      <section className="container-fluid iot-sec4 ptop  pb-5 erer best-text hero">
        <div class="container">

          <div class="row d-flex align-items-center">



            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5"></h2>
                <h1 class="display-5 lh-1 mb-3 star">Cost-Efficient Smart City</h1>
                {/* <h4>cost-efficient<span className='bluefont'> smart city </span></h4> */}
                <p>Procal is a leading provider of customized and cost-efficient smart city solutions. With a strong focus on innovation and sustainability, we empower cities to enhance their infrastructure and services through our cutting-edge technologies.</p>


    


              </div>
            </div>

            <div class="col-md-5 col-lg-5 offset-lg-2 image-box0">
              <img src={fea7} className='img-fluid' />
            </div>
          </div>

          <div class="row d-flex align-items-center mt-30">
          <div class="col-md-7 col-lg-5 image-box1">    <img src={fea5} className='img-fluid' /></div>
          <div class="col-md-5 col-lg-5 offset-lg-2">
          <h1 class="display-5 lh-1 mb-3 star">Comprehesive Range</h1>

                <p>Our comprehensive range of smart city solutions encompasses various sectors, including transportation, energy management, public safety, waste management, and urban planning. By harnessing the power of data analytics, Internet of Things (IoT), and artificial intelligence (AI), we enable cities to optimize resource utilization, improve operational efficiency, and enhance the quality of life for their citizens.</p></div>
      
          </div>

          <div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
  <div class="txt-block right-column wwhite-color wow fadeInLeft" >


<h1 class="display-5 lh-1 mb-3 star">Unique Challenges</h1>
    <p>At Procal, we understand that every city has unique needs and challenges. Therefore, we collaborate closely with local authorities, urban planners, and stakeholders to develop tailored solutions that align with their goals and budgetary constraints. Our team of experts works diligently to assess the existing infrastructure, identify key pain points, and design a holistic and scalable smart city framework.</p>



  </div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
  <img src={fea6} className='img-fluid' />
</div>
</div>

<div class="row d-flex align-items-center mt-30">
          <div class="col-md-7 col-lg-5 image-box3">    <img src={banking} className='img-fluid' /></div>
          <div class="col-md-5 col-lg-5 offset-lg-2">
          <h1 class="display-5 lh-1 mb-3 star">Traffic Management</h1>
         
                <p>We offer a range of smart solutions, including smart traffic management systems, intelligent lighting networks, waste monitoring and optimization platforms, and advanced data analytics platforms. Our solutions not only streamline city operations but also enable data-driven decision-making, leading to improved citizen satisfaction and overall sustainability.</p>
                
                </div>
      
          </div>

          <div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
  <div class="txt-block right-column wwhite-color wow fadeInLeft" >


  <h1 class="display-5 lh-1 mb-3 star">Numerous Benefits</h1>
    <p>By implementing Procal's smart city solutions, cities can enjoy numerous benefits, such as reduced energy consumption, optimized traffic flow, enhanced public safety, efficient waste management, and increased economic opportunities. We are committed to helping cities transform into intelligent, resilient, and future-ready urban centers that offer a high quality of life for their residents.</p>



  </div>
</div>



<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
  <img src={fea6} className='img-fluid' />
</div>
</div>

        </div>
      </section>

      <div class="col-lg-12 col-md-8 mx-auto mt-5"><h2 class="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2></div>


      <SmartcityExpert />



      <Book/>
      <Footer />
    </>
  )
}

export default SmartCity
