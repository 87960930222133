import React from 'react';
import Menu from './../../common/Menu';
import Location from './../../common/contact/Location';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/big-data-left.jpg'
import car from './../../images/network-brain.jpg'
import mobile from './../../images/mobile-5g.svg'
import fea2 from './../../images/big-data-lines.jpg'
import Book from './book';
import abst111 from './../../images/abst-111.jpg'
import Slider from "react-slick";
import './../solutions/robot-processing.css'
import './../solutions/bigdata.css'


import cloud from './../../images/network-ana.jpg'

import cutting from './../../images/bigdata-slide.jpg'


// apple ani end
function BigData() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)

.from('.image-box-center img', {
    yPercent: 15,
    ease: "none",
    scrollTrigger: {
    trigger: ".outline-box-center",
    start: "top center",
    scrub: 1,
    
    },
    ease: 'power.inOut',
    }, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<title>Big Data Analytics Services & Solutions | USA, UK, UAE  </title>
<meta name="description" content="By leveraging cutting-edge techniques and technologies, Procal enables organizations to unlock the full potential of their data and drive strategic growth" />
<Helmet>
<title>Data Analytics and Insights Services & Solutions | Procal</title>
<meta name="description" content="Procal data analytics & insights services & solutions can help you unlock powerful analytics insights by tapping into data you didn't even know you had." />
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Big Data Analytics Services &amp; Solutions | USA, UK, UAE | Procal" />
	<meta property="og:description" content="By leveraging cutting-edge techniques and technologies, Procal enables organizations to unlock the full potential of their data and drive strategic growth" />
	<meta property="og:url" content="https://www.procal.ai/solutions/big-data-analytics/" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">



<h1 class="title-outline">
Big Data
</h1>
<span class="title--outline green">
Big Data
</span>
<span class="title--outline green">
Big Data
</span>
<span class="title--outline green">
Big Data
</span>
<span class="title--outline green">
Big Data
</span>
<span class="title--outline green">
Big Data
</span>
<span class="title--outline green">
Big Data
</span>
</div>
</div>
<div className='col-lg-5'>
<div className='sidetext'>
<div class="image-box">
<img src={girl} className='img-fluid' />     
</div>
</div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
<div class="container">

<div class="row d-flex align-items-center1">


<div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
<img
ref={imageRef}
src={cloud}
alt="Your Image"
className="img-fluid"
/>
</div>

<div class="col-md-8 col-lg-8 hero">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >


<h1 class="display-5 lh-1 mb-3 star">Big Data Analytics</h1>
<p>In today's data-driven world, harnessing the power of big data has become essential for businesses seeking to gain valuable insights and make informed decisions. Procal, a leading provider of advanced technological solutions, offers comprehensive data analytics solutions tailored to meet the specific needs of our customers. By leveraging cutting-edge techniques and technologies, Procal enables organizations to unlock the full potential of their data and drive strategic growth.</p>

<p>At the heart of our data analytics solutions is a robust infrastructure designed to handle vast amounts of data efficiently and effectively. Leveraging scalable cloud platforms and high-performance computing, we ensure seamless data ingestion, storage, and processing. Our solutions are built to accommodate the ever-growing volume, velocity, and variety of data, providing our customers with a reliable and scalable foundation for their analytics initiatives.</p>

<p>Procal's team of highly skilled data scientists and domain experts possess deep expertise in a wide range of analytics techniques and methodologies. From descriptive and diagnostic analytics to predictive and prescriptive analytics, we leverage advanced algorithms and machine learning models to extract meaningful insights from large complex data sets. Through data visualization and interactive dashboards, we empower our customers to explore and understand their data intuitively, enabling them to make data-driven decisions with confidence.</p>

<p>With a focus on industry-specific applications, Procal caters to the unique challenges and requirements of various sectors. Whether it's optimizing supply chain operations, enhancing customer experience, improving fraud detection, or enabling personalized marketing campaigns, our solutions are tailored to deliver tangible business outcomes. We understand the importance of domain knowledge and work closely with our customers to identify key performance indicators and develop customized analytics solutions that drive measurable results.</p>

<p>By partnering with Procal for Big Data Analytics solutions, organizations gain a competitive edge in today's data-driven landscape. Our solutions enable them to uncover hidden patterns, identify trends, and make proactive decisions that drive operational efficiency, reduce cost and strategic growth. With faster time-to-market and improved data-driven decision-making capabilities, our customers can optimize their operations, enhance customer experiences, and stay ahead of the competition.</p>





</div>
</div>

<div class="col-md-4 col-lg-4 image-box">
<img src={fea2} className='img-fluid'  />
</div>

<div class="col-md-12 col-lg-12">






</div>
</div>
</div>
</section>

{/* center section */}

{/* center section end */}
<section className="container-rom container-al-big ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fhi">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>


<h2 class="display-5 fw-boldw lh-1 mb-3 text-white animate-text-2">Competitive edge</h2>
<p className="col-oe2" style={{ fontSize: 16 }}>
By partnering with Procal for Big Data Analytics solutions, organizations gain a competitive edge in today's data-driven landscape. Our solutions enable them to uncover hidden patterns, identify trends, and make proactive decisions that drive operational efficiency, reduce cost and promote strategic growth. With faster time-to-market and improved data-driven decision-making capabilities, our customers can optimize their operations, enhance customer experiences, and stay ahead of the competition.
</p>


</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Big Data"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Descriptive and Predictive Analytics</h4>

<p>Procal offers cutting-edge descriptive and predictive analytics capabilities. Our team of experts leverages historical data to develop sophisticated models that accurately predict future outcomes. Depending on your specific requirements, we specialize in tasks such as regression analysis, classification algorithms, anomaly detection, recommendation systems, and natural language processing. Through our advanced analytics techniques, we empower businesses to make data-driven decisions, optimize operations, and identify growth opportunities.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Big Data"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Data Visualization</h4>

<p>At Procal, we have mastered the art of visualizing complex datasets, transforming them into meaningful and intuitive representations. Our skilled professionals utilize state-of-the-art tools and techniques to create captivating interactive dashboards, insightful reports, and visually engaging representations of data. With our aesthitacally pleasing visualizations, users can effortlessly explore and understand intricate information, uncover patterns, and gain valuable insights that drive informed decision-making across your organization.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Big Data"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Real-time Analytics</h4>

<p>Procal is your trusted partner for real-time and near real-time analytics. With extensive expertise in processing and analyzing streaming data, our team harnesses the power of cutting-edge technologies like Apache Kafka, Apache Flink, and Apache Spark Streaming. We seamlessly handle continuous data streams, extracting timely and valuable insights. Through our real-time analytics solutions, you can respond swiftly to changing market dynamics, detect emerging trends, and make proactive decisions, ensuring your business stays ahead of the competition.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Big Data"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Data Security and Governance</h4>

<p>At Procal, we place utmost importance on data security and governance. With a deep understanding of relevant regulations and industry standards, we implement robust measures to ensure compliance and safeguard sensitive information. Our comprehensive approach includes implementing stringent data access controls, employing encryption techniques, anonymizing data, and implementing data privacy measures. With our data security and governance solutions, you can trust that your data is protected, allowing you to focus on leveraging analytics to drive business growth.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Big Data"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Cloud and Distributed Computing</h4>

<p>Procal is well-versed in leveraging cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP) for scalable and distributed data processing. Our experienced team harnesses the power of these platforms to design and implement efficient big data analytics solutions. Through technologies like Hadoop, Spark, and NoSQL databases, we ensure seamless data processing, storage, and analysis, empowering you to handle massive data volumes and derive valuable insights. With our cloud and distributed computing expertise, you can optimize resource utilization, scale your analytics capabilities, and drive operational efficiency.</p>

</div>
</div>
</div>



</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default BigData
