import React from 'react';
import Menu from './../../common/Menu';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import fea5 from './../../images/fea_5.png'
import fea6 from './../../images/fea_6.png'
import fea7 from './../../images/fea_7.png'
import './../solutions/mobile.css'
import banking from './../../images/fea_3.png'
import mockupall from './../../images/cloud-3d.jpg'
import CloudExport from './CloudExport';
import Book from './book';
// apple ani end
function Cloud() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.out', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: "center center",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)
      .from('.image-box0 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box0",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)


      .from('.image-box1 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box1",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box2 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box2",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box3 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box3",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0);



  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });




  return (
    <>
<Helmet>
<title>Cloud Solutions | Enterprise Cloud Services | Procal Technologies</title>
<meta name="description" content="Explore our comprehensive suite of cloud services tailored to your needs and embark on a journey of innovation and growth. Start your transformation today	" />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Cloud Solutions | Enterprise Cloud Services | Procal" />
	<meta property="og:description" content="Explore our comprehensive suite of cloud services tailored to your needs and embark on a journey of innovation and growth. Start your transformation today" />
	<meta property="og:url" content="https://www.procal.ai/solutions/cloud/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>
      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div>  */}



              <div class="section-head__title flex-basis-666">
                <div class="image-box">
                  {/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
                </div>
           
          

                <h1 class="title-outline">
               CLOUD
                </h1>
                <span class="title--outline green">
                Cloud
                </span>
                <span class="title--outline green">
                Cloud
                </span>
                <span class="title--outline green">
                Cloud
                </span>
                <span class="title--outline green">
                Cloud
                </span>
                <span class="title--outline green">
                Cloud
                </span>
                <span class="title--outline green">
                Cloud
                </span>
              </div>
              <div className='sidetext'>
                <div class="image-box imgprop">
                  <img src={mockupall } className='img-fluid' alt='cloud' />

                </div>
              </div>

            </section>
          </div>





        </div>


      </section>

      <section className="container-fluid iot-sec4 ptop11  pb-5 erer best-text hero">
        <div class="container">

          <div class="row d-flex align-items-center">



            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft " >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5"></h2>
           

                <h1 class="display-5 lh-1 mb-3 star">Cloud Computing  </h1>
                <p>Cloud computing has ushered in a paradigm shift in data management and storage practices for organizations. By leveraging advanced capabilities in scalability, accessibility, security, and disaster recovery, the cloud has emerged as an indispensable tool for businesses seeking to maintain their competitive edge, streamline operations, and optimize resource allocation. Through the cloud, organizations can effortlessly tap into a diverse array of resources without the need for cumbersome ownership or management of underlying network infrastructure. Procal's comprehensive suite of cloud-based offerings encompasses software-as-a-service (SaaS) for communication and collaboration, infrastructure-as-a-service (IaaS) for building and deploying custom applications and services, and platform-as-a-service (PaaS) enabling seamless development and deployment of scalable, cloud-native applications.</p>


    


              </div>
            </div>

            <div class="col-md-5 col-lg-5 offset-lg-2 image-box0">
              <img src={fea7} className='img-fluid' />
            </div>
          </div>

          <div class="row d-flex align-items-center mt-30">
          <div class="col-md-7 col-lg-5 image-box1">    <img src={fea5} className='img-fluid' alt='cloud' /></div>
          <div class="col-md-5 col-lg-5 offset-lg-2">
      
            <h1 class="display-5 lh-1 mb-3 star">Procal Boasts  </h1>

      
                <p>Procal boasts a wealth of experience in crafting and architecting cutting-edge cloud services that foster business agility. Our expertise spans across all major cloud providers, including AWS, Azure, and GCP, ensuring comprehensive coverage and adaptability. Within our robust portfolio of cloud-based solutions, we offer a suite of solutions for data migration, cloud-native or cloud-enabled application development, cloud-based automation tools for streamlined code management, and comprehensive storage solutions tailored to your needs. Our dedicated team of Procal cloud specialists consistently surpass customer expectations, infusing each design with forward-thinking principles to support future growth, flexibility, and enhanced functionality.</p></div>
      
          </div>

          <div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
  <div class="txt-block right-column wwhite-color wow fadeInLeft" >


  <h1 class="display-5 lh-1 mb-3 star">Cloud Networking  </h1>
    <p>At Procal, our cloud networking specialists possess an extensive repertoire of development tools and options essential for designing this transformative technology. These proficiencies empower us to unleash the virtually boundless possibilities inherent in cloud computing. Our cloud experts serve as trusted advisors, offering invaluable insights on virtualization technology, containerization operations, and the underlying platforms that shape its architecture (such as Docker and Kubernetes). Furthermore, they possess unrivaled expertise in security programs encompassing encryption, cloud-based identity management, firewalls, and the hardware infrastructure that underpins these components.</p>



  </div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
  <img src={fea6} className='img-fluid' />
</div>
</div>

<div class="row d-flex align-items-center mt-30">
          <div class="col-md-7 col-lg-5 image-box3">    <img src={banking} className='img-fluid' alt='cloud' /></div>
          <div class="col-md-5 col-lg-5 offset-lg-2">
        
            <h1 class="display-5 lh-1 mb-3 star">Light Exponential  </h1>
                <p>Cloud Light Exponential harnesses the power of cloud technology to deliver rapid scalability and flexibility for businesses. This innovative solution empowers organizations to effortlessly adapt to changing demands, streamline operations, and optimize resource allocation. With enhanced data processing and storage capabilities, businesses can accelerate their digital transformation journey while ensuring a secure and efficient cloud environment. Experience exponential growth and agility with Cloud Light Exponential.</p>
                
                </div>
      
          </div>

        </div>
      </section>

      {/* whats new section */}
      <section className="container">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">


            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


          </div>


        </div>




      </section>



      <CloudExport />


      <Book/>


      <Footer />
    </>
  )
}

export default Cloud
