import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import logowhite from './../images/footer-logo.svg';
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import iphone from './../images/oursolution-icon-footer.png'
function Footer() {
  return (
    <footer className="footer-section">

<div className="copyright-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 text-center text-lg-left">
           
            </div>

            <div className="col-lg-3 offset-lg-0 offset-lg-1 d-none d-lg-block text-right mt-3 img24">

            <div>
            <img src={iphone}  classname="x-auto "/>
        <span class="text-lg tw-text-white inline-block align-middle ml-2 tw-text-3xl "> Procal Solution</span>
    </div>
   <div className='align-center'> <span>  </span>   </div>
         
             
              </div>
            <div className="col-xl-8 col-lg-8 offset-lg-0 d-none d-lg-block text-right mt-3">
            <Link to="/solutions/cybersecurity-leads"><span className="tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-blue-900 dark:tw-text-blue-300">
            Cybersecurity Leads
</span></Link>

<Link to="/solutions/automation-testing-leads"><span className="tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-green-900 dark:tw-text-green-300 mt5">
Automation Testing Leads
</span></Link>

<Link to="/solutions/emerging-tech-leads"><span className="tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-red-900 dark:tw-text-red-300">
Emerging Tech Leads
</span></Link>
<div className='clear-fix mt-4'></div>
<Link to="/solutions/telecom-leads"><span className="tw-bg-gray-100 tw-text-gray-800 tw-text-xs tw-font-medium tw-me-2 tw-px-5 tw-py-3 tw-rounded-full dark:tw-bg-gray-700 dark:tw-text-gray-300">
Telecom Leads
</span></Link>
<Link to="/solutions/data-analytics-insights-leads"><span className="tw-bg-yellow-100 tw-text-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-yellow-900 dark:tw-text-yellow-300 mt-3">
Data Analytics and Insights Leads
</span></Link>
<Link to="/solutions/digital-transformation-leads"><span className="tw-bg-indigo-100 tw-text-indigo-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-indigo-900 dark:tw-text-indigo-300 mt-3">
Digital Transformation Solutions
</span></Link>



                </div>
          </div>
        </div>
      </div>
      <div className="container">

  
        <div className="footer-cta pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span>92 East Main Street Suite:405<br />
                    Somerville, New Jersey 08876<br />
                    United States</span>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2 mb-30">
              <div className="single-cta">
                <i className="fas fa-phone"></i>
                <div className="cta-text">
                  <h4>Call us</h4>
                  <span>(732)-568-4786</span>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2 mb-30">
              <div className="single-cta">
                <i className="far fa-envelope-open"></i>
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>info@procaltech.com</span>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4 mb-30 pl3 ">

              <Link to="/contact-us#consult" ><button type="button" class="btn btn-warning mobiletopp">Free Client Consultation</button></Link>

            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-3 col-lg-3 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <img src={logowhite} />
                </div>
                <div className="footer-text">
                  <p>At Procal, our mission is to empower businesses with cutting-edge technology solutions and services that drive growth and innovation</p>
                </div>
                {/* <div className="footer-social-icon">
                      <span>Follow us</span>
                      <a href="#"><i className="fab fa-facebook-f facebook-bg"></i></a>
                      <a href="#"><i className="fab fa-twitter twitter-bg"></i></a>
                      <a href="#"><i className="fab fa-google-plus-g google-bg"></i></a>
                    </div> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="footer-widget">
                {/* <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div> */}
                <ul>
                  <li> <Link to="/who-we-are/">Who We Are</Link></li>
                  <li><Link to="/what-we-do/">What We Do</Link></li>
                  <li><Link to="/products">Catalogue</Link></li>
                  <li> <Link to="/blog">Blog</Link></li>
                  <li>  <Link to="/careers">Careers</Link></li>
                  <li>  <Link to="/DigitalBanking">Digital Banking</Link></li>
                  <li>   <Link to="/products/snap-automation">Snap Automation</Link></li>
                  <li> <Link to="/products/artemis">Artemis</Link></li>
                  <li>  <Link to="/products/spot-finder/">Spot Finder</Link></li>

                </ul>


              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="footer-widget">
                {/* <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div> */}
                <ul>
                  <li> <Link to="/solutions/robot-processing-automation/">Automation Testing</Link></li>
                  <li><Link to="/solutions/cyber-security">Cyber Security</Link></li>


                  <li>   <Link to="/solutions/telecom">Telecom</Link></li>
                  <li> <Link to="/solutions/fintech">Fintech</Link></li>
                  <li><Link to="/solutions/data-analytics-and-insights">Data Analytics And Insights</Link></li>
                  <li> <Link to="/solutions/emerging-technologies">Emerging Tech XR/AR/VR</Link></li>
                  <li> <Link to="/contact-us"> Contact Us</Link></li>

                </ul>





              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="footer-text mb-25">
                  <p className='1text-center'>Be sure to subscribe to our newsletter for latest updates by filling out the form below</p>
                </div>
                <div className="subscribe-form">
                  <form action="#" id='subscribe'>
                    <input type="text" placeholder="Email Address" />
                    <button> <FontAwesomeIcon icon={faPen} /></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 text-center text-lg-left">
              <div className="copyright-text">
                <p>Copyright &copy; 2024, All Right Reserved </p>
              </div>
            </div>
            {/* <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                  <div className="footer-menu">
                    <ul>
                      <li><a href="#">Home</a></li>
                      <li><a href="#">Terms</a></li>
                      <li><a href="#">Privacy</a></li>
                      <li><a href="#">Policy</a></li>
                      <li><a href="#">Contact</a></li>
                    </ul>
                  </div>
                </div> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
