import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/car-lines.svg'
import car from './../../images/car-lines-green.svg'
import Book from './book';
import Slider from "react-slick";
import './../solutions/mobile.css'
import './../solutions/automotive.css'


import cloud from './../../images/cloud-solution.jpg'

import cutting from './../../images/car.png'
import mockupall from './../../images/automotive-side.jpg'

// apple ani end
function Automotive() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.out', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: "center center",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
        <title>AI and machine learning  | Procal</title>
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Automotive industry" />
	<meta property="og:description" content="Procal is a renowned leader in the automotive industry, dedicated to providing cutting-edge solutions that drive innovation and exceed expectations. With a team of automotive experts at the forefront of their field, we specialize in developing, producing, and building customer-centric solutions that leverage the latest advancements in technology." />
	<meta property="og:url" content="https://www.procal.ai/solutions/automotive/" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-5'> 
<div class="section-head__title flex-basis-666">




<h1 class="title-outline">
AUTOMOTIVE
</h1>
<span class="title--outline green">
AUTOMOTIVE
</span>
<span class="title--outline green">
AUTOMOTIVE
</span>
<span class="title--outline green">
AUTOMOTIVE
</span>
<span class="title--outline green">
AUTOMOTIVE
</span>
<span class="title--outline green">
AUTOMOTIVE
</span>
<span class="title--outline green">
AUTOMOTIVE
</span>
</div>
</div>
<div className='col-lg-7'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={girl} className='img-fluid' />     
                </div>
              </div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center1">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Automotive industry</h2>
                <p>Procal is a renowned leader in the automotive industry, dedicated to providing cutting-edge solutions that drive innovation and exceed expectations. With a team of automotive experts at the forefront of their field, we specialize in developing, producing, and building customer-centric solutions that leverage the latest advancements in technology.</p>

                <p>At the heart of our offerings is C-V2X technology, a groundbreaking communication format that revolutionizes how vehicles interact with their surroundings. By seamlessly integrating C-V2X into vehicles, we enhance safety, efficiency, and the overall driving experience. This remarkable technology enables smart vehicles to connect with roadside infrastructure and pedestrians, creating a comprehensive ecosystem that promotes seamless and secure communication.</p>

                <p>Recognizing the immense potential of autonomous driving, we firmly believe it to be the future of automotive technology. Procal's experts have designed solutions that incorporate a blend of existing and proprietary technologies to enable and support autonomous driving. Our state-of-the-art client application and web-based UI control system empower drivers with complete control over autonomous vehicles. Seamlessly installed within vehicles, the client application works harmoniously with the web-based UI "dashboard," providing drivers with comprehensive access to vehicle and fleet information. Moreover, our technology includes real-time performance measurement outputs, allowing for immediate adjustments to driver behavior, enhancing both safety and efficiency.</p>

                <p>At Procal our technical subject matter experts develop state-of-the-art solutions for real-time traffic management such as Signal Phase and Timing (SPaT) and Red-Light Violation Warning (RLVW). We also specialize in real time emergency alert and remote-operated vehicle solutions such as Vehicle-to-Network and Network-to-Vehicle service, and Teleoperated driving.</p>
                <p>Our team of experts boasts an extensive track record of delivering highly customized solutions that are not only cost-effective but also perfectly aligned with the unique needs of our automotive clients. We take pride in our ability to provide automotive solutions that push boundaries, fuel innovation, and create lasting impact. With Procal as your partner, you can be confident in our commitment to driving the future of automotive technology forward.</p>



              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={mockupall} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>


      <section class="section-byh u-bg-vpx">
    <div class="container-opd u-position-yg5">
        <div class="row-ctw u-mb-4eh u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-vkd">
                <h2 class="style-dYtmF" id="style-dYtmF">
                    <div class="1text-zog sidefont">renowned leader</div>
                    <div className='sidefont'> in the automotive </div>
                    <div class="1text-axz sidefont">industry</div>
                </h2>
            </div>
        </div>
        <div class="row-ctw u-mb-4eh u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-zzz">
                <h3 class="des-fpq text-axz" style={{textAlign: 'left'}}>See behind the scenes of real client projects for companies like these</h3>
            </div>
        </div>
        <div class="row-kac">
            <div class="col-zh4 col-lyc"></div>
            <div class="col-zh4 col-lyc">
                <div class="u-img-559"><img src={car} class="u-img-6k2" /></div>
            </div>
            <div class="col-zh4 col-lyc"></div>
        </div>

        <div class="row-jsz u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <p class="style-RWfkB" id="style-RWfkB"></p>
            <div class="ygqvp text-ggq" >from start</div>
            <div class="ygqvp" >to finish.</div>
            <p></p>
        </div>
        <div class="col-zh4 col-vkd">
                <p class="style-evCsm" id="style-evCsm"></p>
                <div class="text-zog tcilv">+ C-V2X technology</div>
                <div class="text-zog tcilv">Seamlessly integrating C-V2X </div>
                <div class="text-zog tcilv">vehicles</div>
                <p></p>
            </div>
    </div>
   
        
    </div>
</section>
{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">
<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Automotive"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>C-V2X Technology Solutions</h4>

<p>Procal specializes in the development and seamless integration of Cellular Vehicle-To-Everything (C-V2X) technology, revolutionizing vehicle communication that is designed to offer low-latency vehicle-to-vehicle (V2V), vehicle-to-roadside infrastructure (V2I), vehicle-to-pedestrian (V2P) and vehicle-to-cloud (V2C) creating a comprehensive and dynamic ecosystem. Our expertise in C-V2X empowers clients to harness the full potential of advanced vehicle connectivity, enhancing safety, efficiency, and the overall driving experience. We provide a 360º non-line-of-sight awareness and a higher level of predictability for enhanced road safety and autonomous driving.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Automotive"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Autonomous Driving Solutions</h4>

<p>When it comes to 5G, Procal is at the forefront of designing and implementing cutting-edge software solutions for autonomous driving. Our experts leverage a combination of existing and proprietary technologies to create state-of-the-art systems that enable and support autonomous driving. Through our sophisticated client application and web-based UI control system, drivers maintain complete control over autonomous vehicles. Seamlessly integrated within the vehicle, this comprehensive system offers real-time information and facilitates immediate adjustments for enhanced safety, reliability and efficiency.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Automotive"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Advanced Infotainment Systems</h4>

<p>Procal's advanced infotainment system solutions redefine the driver-vehicle interface, providing an immersive and intuitive experience. These solutions incorporate an array of advanced features, including high-precision GPS navigation, intelligent safety sensors, seamless multimedia streaming capabilities, live parking camera assistance, voice messaging, and seamless internet connectivity. With support for true 4K, 10-bit displays in various sizes, our infotainment systems deliver unparalleled visual clarity, versatility, and seamless integration of entertainment, communication, and information services.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Automotive"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Collision Avoidance</h4>

<p>Procal provides collision avoidance systems solution that detect vehicles or pedestrians, anticipate a collision, and automatically apply the brakes or take corrective steering actions. These technologies have proven to be very effective at reducing traffic fatalities and improves road safety for drivers. With our, C-V2X technology, we can make collision avoidance systems even more accurate by receiving inputs from nearby vehicles in real-time to better predict their actions. Each vehicle could broadcast its position, speed, direction and identity to other nearby vehicles to build a real-time map of their surroundings.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Automotive"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Hazard/Construction Zone Warnings</h4>

<p>Our construction/hazard warning solutions inform drivers about everything from vehicle crashes ahead to evolving weather conditions. By incorporating information from networks, vehicles, and infrastructure, warnings can be localized and highly accurate. A car that hit a hazard debris in middle of the road could alert other nearby vehicles of the road hazard and help them avoid making the same mistake.</p>

</div>
</div>
</div>

</Slider>

</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default Automotive
