/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Menu from "./../common/Menu";
import Footer from "../common/Footer";
import "./../blogs/blog.css";
import girl from "./../images/1.jpg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  useNavigate
} from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

function Blog() {
  const [recentBlog, setRecentBlog] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [catgry, setCatgry] = useState([]);
  const [authr, setAuthr] = useState([]);
  const navigate = useNavigate();
  const getLatestBlog = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/allPage?nocache=true`)
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          setRecentBlog(res?.data?.[0]);
          res?.data?.shift();
          setBlogs(res?.data);
        }
      });
  };

  const loadAuthor = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/allauthor`).then((res) => {
      setAuthr([...res.data]);
    });
  };
  const categoryByCount = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/categorycount?nocache=true`)
      .then((res) => {
        if (res?.status === 200) {
          setCatgry(res.data);
        }
      });
  };
  const handleReadMore = (id, url) => {
    // const mtitle = title.split(' ').join('-');
    const pathUrl = url || id;
    navigate(`/blog/${pathUrl}`, {state:{id}})
  };

  useEffect(() => {
    getLatestBlog();
    loadAuthor();
    categoryByCount();
  }, []);

  return (
    <>
      <Helmet>
        <title>Blogs | Procal</title>
        <meta name="description" content="Blog Procal" />
      </Helmet>
      <Menu />
      <main>
        <section className="section pt-5" id="blog">
          <div className="container">
            <div className="row no-gutters-lg">
              <div className="col-12">
                <h2 className="section-title">Latest Articles</h2>
              </div>
              <div className="col-lg-8 mb-5 mb-lg-0">
                <div className="row">
                  <div className="col-12 mb-4">
                    <article className="card article-card">
                      <a href="article.html">
                        <div className="card-image card-img-top"  id="cardimg">
                          <div className="post-info">
                            <span className="text-uppercase">
                              {recentBlog.createdDate}
                            </span>
                            {/* <span className="text-uppercase">15 minutes read</span> */}
                          </div>
                          <img
                            loading="lazy"
                            decoding="async"
                            src={recentBlog.img}
                            alt="Post Thumbnail"
                            className="w-100"
                          />
                        </div>
                      </a>
                      <div className="card-body px-0 pb-1">
                        <ul className="post-meta mb-2">
                          <li>
                            <a href="#!">{recentBlog?.category?.[0]?.name}</a>
                            {/* <a href="#!">news</a> */}
                          </li>
                        </ul>
                        <h2 className="h1">
                          {/* <Link to="/art_1" className="post-title"> */}
                            {recentBlog?.title}
                          {/* </Link> */}
                        </h2>

                        <p className="card-text">{recentBlog?.overView}</p>
                        <div className="content read-more-btn pointerhand" onClick={() => handleReadMore(recentBlog?._id, recentBlog?.url)}>
                            Read Full Article
                        </div>
                      </div>
                    </article>
                  </div>
                    {blogs.map((b) => (
                  <div className="col-md-6 mb-4">
                      <article className="card article-card article-card-sm">
                        <a href="article.html">
                          <div className="card-image card-img-top w-100">
                            <div className="post-info">
                              <span className="text-uppercase">
                                {b.createdDate}
                              </span>
                              {/* <span className="text-uppercase">2 minutes read</span> */}
                            </div>
                            <img
                              loading="lazy"
                              decoding="async"
                              src={b.img}
                              alt="Post Thumbnail"
                              className="w-100"
                            />
                          </div>
                        </a>
                        <div className="card-body px-0 pb-0">
                          <ul className="post-meta mb-2">
                            <li>
                              <a href="#!">{b.category[0].name}</a>
                            </li>
                          </ul>
                          <h2>
                            <a className="post-title" onClick={() => handleReadMore(b?._id, b?.url)}>
                              {b.title}
                            </a>
                          </h2>
                          <p className="card-text">{b.overView}</p>
                          <div className="content read-more-btn pointerhand" onClick={() => handleReadMore(b?._id, b?.url)}>
                              Read Full Article 
                          </div>
                        </div>
                      </article>
                  </div>
                    ))}
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <nav className="mt-4">
                          {/* pagination */}
                          <nav className="mb-md-50">
                            <ul className="pagination justify-content-center">
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  href="#!"
                                  aria-label="Pagination Arrow"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={26}
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                                    />
                                  </svg>
                                </a>
                              </li>
                              <li className="page-item active ">
                                {" "}
                                <a href="index.html" className="page-link">
                                  1
                                </a>
                              </li>
                              <li className="page-item">
                                {" "}
                                <a href="#!" className="page-link">
                                  2
                                </a>
                              </li>
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  href="#!"
                                  aria-label="Pagination Arrow"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={26}
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                    />
                                  </svg>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="widget-blocks">
                  <div className="row">

                     {/* <div className="col-lg-12">
                <div className="widget">
                  <div className="widget-body">
                    <img
                      loading="lazy"
                      decoding="async"
                      src={girl}
                      alt="About Me"
                      className="w-100 author-thumb-sm d-block"
                    />
                    <h2 className="widget-title my-3">Hootan Safiyari</h2>
                    <p className="mb-3 pb-2">
                      Hello, I’m Hootan Safiyari. A Content writter, Developer
                      and Story teller. Working as a Content writter at CoolTech
                      Agency. Quam nihil …
                    </p>{" "}
                    <a
                      href="about.html"
                      className="btn btn-sm btn-outline-primary"
                    >
                      Know More
                    </a>
                  </div>
                </div>
              </div> */}
{/* 
<div className="media">
  <div className="media-left">
    <a href="#">
      <img
        className="media-object"
        src="https://placehold.co/100x100"
        alt="profile-image"
        style={{ width: 48, height: 48 }}
      />
    </a>
  </div>
  <div className="media-body">
    <h4 className="media-heading">Media heading</h4>
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting
      industry....
    </p>
  </div>
</div> */}

                    <div className="col-lg-12 col-md-6">
                      <div className="widget" id="media">
                        <h2 className="section-title mb-3">Author</h2>
                        <div className="widget-body">
                          <div className="widget-list">
                            {/* <article className="card mb-4">
                        <div className="card-image">
                          <div className="post-info">
                            {" "}
                            <span className="text-uppercase">
                              1 minutes read
                            </span>
                          </div>
                          <img
                            loading="lazy"
                            decoding="async"
                            src={post9}
                            alt="Post Thumbnail"
                            className="w-100"
                          />
                        </div>
                        <div className="card-body px-0 pb-1">
                          <h3>
                            <a
                              className="post-title post-title-sm"
                              href="article.html"
                            >
                              Portugal and France Now Allow Unvaccinated
                              Tourists
                            </a>
                          </h3>
                          <p className="card-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor …
                          </p>
                          <div className="content">
                            {" "}
                            <a className="read-more-btn" href="article.html">
                              Read Full Article
                            </a>
                          </div>
                        </div>
                      </article> */}
                            {authr.map((a) => (
                              <a
                                className="media align-items-center"
                                href="article.html"
                              >
                                <img
                                  loading="lazy"
                                  decoding="async"
                                  src={a.profilePicture}
                                  alt="Post Thumbnail"
                                  className="w-100"
                                />
                                <div className="media-body ml-3">
                                  <h3 style={{ marginTop: "5px", textAlign:"left" }}>
                                    {a.name}
                                  </h3>
                                  <p className="mb-0 small">{a.bioText}</p>
                                </div>
                              </a>
                             
                            ))}
                             <div className="clear-fix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <div className="widget">
                        <h2 className="section-title mb-3">Categories</h2>
                        <div className="widget-body">
                          <ul className="widget-list">
                            {catgry?.map((c) => (
                              <li>
                                <a href="#!">
                                  {c?.data?.[0]?.categoryName}
                                  <span className="ml-auto">({c.count})</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Blog;
