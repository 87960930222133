import React from 'react';
import Menu from '../../common/Menu';
import girl from './../../images/Applications.jpg'
import item2 from './../../images/savelives.jpg'
import item3 from './../../images/securing.jpg'
import item4 from './../../images/enhancing-iot.jpg'
import item5 from './../../images/revolutionizing-5g.jpg'
import item6 from './../../images/cost-efficiency.jpg'
import item7 from './../../images/tracking-system.jpg'
import item8 from './../../images/streamlining.jpg'
import item9 from './../../images/comcasts.jpg'
import item10 from './../../images/diagnostics.jpg'
import Footer from '../../common/Footer'
import './../casestudy/case.css'
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
function Caselisting() {



return (
<>
<Menu />


<div className="img-container outline-box" >
<div className='1overlayer-case'></div>

<div id="casestudy">
<p className='caseH d-block d-sm-none'>Case studies</p>
<div className='headingtop white'>

<h2 className='blackheading d-lg-block d-none'>Case studies</h2>
<p className='1text-white lead casepara'>What’s New at Procal?</p>
</div>
</div>
</div>


<section className="container container-listing-1 ali-fxl">



<div className="row">
<div className="dd">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="tt">
<section className="1container-case-1">

<div>
<div className="band-case">
<div className="item-1">
    <Link to="/case-studies/securing-web-applications-global-insurance-company/" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${girl})` }}
/>
<article>
<h1>Securing web applications</h1>
<span>Case Study</span>
</article>

</Link>
</div>
<div className="item-2">
<Link to="/case-studies/ransomware-attacks-on-hospitals" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item2})` }}
/>
<article>
<h1>Saving Lives and Data: Procal Bolsters Hospital's Ransomware Defenses</h1>
<p>A leading healthcare provider, acutely aware of the potential devastation of a ransomware attack, partnered with Procal to conduct a</p>
<span>Case Study</span>
</article>
</Link>
</div>
<div className="item-3">
<Link to="/case-studies/environmental-monitoring-system/" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item3})` }}
/>
<article>
<h1>Environmental Monitoring System</h1>
<p>
A prominent technology solutions provider, aware of the ever-present cyber threat, engaged PCL to conduct a comprehensive network penetration test.
</p>
<span>Case Study</span>
</article>
</Link>
</div>
<div className="item-4">
<Link to="/Enhancing" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item4})` }}
/>
<article>
<h1>Environmental Monitoring System</h1>
<p>
The Environmental Protection Agency (EPA) sought AT&T's expertise to explore the potential of IoT sensor data in monitoring air quality,
</p>
<span>Case Study</span>
</article>
</Link>
</div>
<div className="item-5">
<Link to="/case-studies/5g-internet-of-things" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item5})` }}
/>
<article>
<h1>
Enhancing Environmental Management through Procal's IoT Sensor Solution
</h1>
<p>A prominent US telecommunications operator, was tasked by the NYC Department of Transportation to demonstrate the value of harnessing network,</p>
<span>Case Study</span>
</article>
</Link>
</div>
<div className="item-6">
<Link to="/case-studies/transforming-atts-operations-for-technological-advancement-and-cost-efficiency/" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item6})` }}
/>
<article>
<h1>Transforming AT&T's Operations for Technological Advancement and Cost Efficiency</h1>
<p>AT&T has successfully evolved from a residential telephone service provider to a multifaceted enterprise, offering mobile networking, connectivity</p>
<span>Case Study</span>
</article>
</Link>
</div>
<div className="item-7">
<Link to="/case-studies/leader-tracking-systems-turning-data-information-school-leadership-turning-data-information" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item7})` }}
/>
<article>
<h1>
Leader Tracking Systems
</h1>
<p>The District of Columbia Public Schools (DCPS) embarked on a transformative journey to refine their professional development and leadership pathways.</p>
<span>Case Study</span>
</article>
</Link>
</div>

<div className="item-8">
<Link to="/case-studies/commodity-trades-with-advanced-automation" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item8})` }}
/>
<article>
<h1>
Streamlining Commodity Trades with Advanced Automation
</h1>
<p>When Kinder Morgan, Inc. realized the limitations of their manual, Excel-based trading protocol, they turned to Procal for an innovative</p>
<span>Case Study</span>
</article>
</Link>
</div>
<div className="item-9">
<Link to="/case-studies/cloud-technology-for-real-time-adaptability" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item9})` }}
/>
<article>
<h1>
Leveraging Cloud Technology for Real-time Adaptability: Comcast's Operational Transformation
</h1>
<p>The COVID-19 pandemic posed numerous challenges to retailers and service providers, necessitating rapid adaptation of their operations and processes</p>
<span>Case Study</span>
</article>
</Link>
</div>
<div className="item-10">
<Link to="/case-studies/ extended-reality-5g-network-performance-diagnostics-tool/" className="card-case">
<div
className="thumb"
style={{ backgroundImage: `url(${item10})` }}
/>
<article>
<h1>
Procal's XR 5G Network Performance Diagnostics Tool
</h1>
<p>In the advent of 5G technology, applications that heavily rely on remote access with low latency, such as Extended Reality</p>
<span>Case Study</span>
</article>
</Link>
</div>
</div>

</div>
</section>
</div>
</div>
</div>
</div>
</div>


</section>
<Footer />


</>
)
}

export default Caselisting
