import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css';
import './../solutions/esim.css'
import Book from './book';

import { Helmet } from "react-helmet";
const Networkexpert = () => {

const imagesRef = useRef([]);
const sideImagesRef = useRef([]);


// Initialize ScrollTrigger
useEffect(() => {
let ctx = gsap.context(() => {

gsap.registerPlugin(ScrollTrigger);

gsap.set(".image", { zIndex: (i, target, targets) => targets.length - i });
gsap.set(".side-image", { zIndex: (i, target, targets) => targets.length - i });

const images = imagesRef.current;
images.forEach((image, i) => {
const nextImage = image.nextElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "100%" }, { height: "0%", ease: "none" }, 0);
// You can add more animations here if needed
});

const sideImages = sideImagesRef.current;
sideImages.forEach((image, i) => {
const prevImage = image.previousElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "0%", scale: 1.00 }, { height: "100%", scale: 1.125, ease: "none" }, 0)
.fromTo(prevImage, { scale: 1.125 }, { scale: 1.25, ease: "none" }, 0);
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((images.length) * window.innerHeight),
pin: '.image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((sideImages.length) * window.innerHeight),
pin: '.side-image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});
});
return () => ctx.revert();
}, []); // Empty dependency array to run effect only once


return (

<>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Network Analytics Solutions | USA, UAE, UK | Procal" />
	<meta property="og:description" content="Our highly skilled team of analysts and engineers possess exceptional expertise in collecting and analyzing data from diverse sources, including network devices, applications, and users." />
	<meta property="og:url" content="https://www.procal.ai/solutions/network-analytics/" />
	<meta property="og:site_name" content="Procal Technologies" />

            </Helmet>

<div className="scroller">

<div id='loc'>
<section className="showcase">
<div className="text-wrap">
<div className="text-wrapper">



<div className="textL blue">
<h4>Manufacturing and Industrial</h4>

<p>Our PN solutions address the essential needs for private networking in manufacturing facilities. By leveraging cellular technology, we effectively overcome Wi-Fi interference challenges commonly faced on factory floors. With our reliable and secure network, you can experience peace of mind and minimize network outages. Our PN solutions not only provide a seamless connection but also ensure the utmost security and reliability, eliminating concerns about network disruptions. Say goodbye to Wi-Fi issues and embrace a more robust and efficient private networking solution tailored specifically for manufacturing environments.</p>
</div>
<div className="textL c">
<h4>Healthcare</h4>

<p>Procal specializes in developing custom solutions tailored to mission-critical life and health support devices, high-security requirements, and high occupancy environments. As the healthcare industry embraces digital advancements like remote patient monitoring and telehealth, the demand for increased bandwidth becomes crucial. Private networks offer a viable solution, and Procal is committed to assisting organizations in meeting these growing needs. With our expertise, we ensure the development of reliable and efficient custom solutions that align with the evolving healthcare landscape, empowering healthcare providers to deliver optimal care and support to their patients.</p>
</div>
<div className="textL green"><h4>Higher Education</h4>

<p>College and university campuses require reliable cellular coverage for seamless connectivity. Private networks play a crucial role in extending coverage and eliminating the challenges posed by public networks or unreliable Wi-Fi. By implementing private network solutions, campuses can consolidate operations, providing students and faculty with better control. Additionally, this approach ensures enhanced system security, preserves application integrity, and guarantees a seamless user experience. Procal, a trusted partner in developing tailored solutions, collaborates with institutions to deliver customer-specific network solutions, addressing the unique needs of each campus community. Together, these efforts foster an optimized and secure campus environment.</p></div>

<div className="textL purple"><h4>Offices and Corporate Campuses</h4>

<p>Private networks are a superior choice over Wi-Fi, offering enhanced security, reliability, and broader reach. Unstable Wi-Fi can lead to lost profits and damage reputation, making private networks a compelling alternative. They provide extensive coverage and scalability, ensuring uninterrupted connectivity. Employing proactive insights and productivity-boosting applications, private networks guarantee a dependable network infrastructure, safeguarding against disruptions and optimizing operational efficiency. Consider leveraging private networks to mitigate the risks associated with Wi-Fi instability, enabling seamless communication and minimizing the impact of potential network downtime. This strategic shift can yield substantial benefits, bolstering both financial outcomes and brand reputation.</p></div>

<div className="textL blue"><h4>Stadiums and Arenas</h4>

<p>The number of attendees relying on public cellular networks can significantly affect network capabilities and degrade in-stadium experience due to poor application performance. To mitigate this risk, private networks emerge as an excellent choice for large sports and convention centers. By implementing Procals solutions, these venues can enhance the customer experience within the stadium, ensuring seamless connectivity and improved application performance. Private networks not only alleviate strain on public networks but also provide a reliable and efficient solution for optimizing in-stadium experiences, making them an ideal choice for such venues.</p></div>

<div className="textL red"><h4>Private Cloud Connectivity</h4>

<p>Procal empowers clients with dedicated connections to top public cloud providers, including AWS, Azure, and GCP. This ensures secure and reliable access to their cloud resources. With our specialized knowledge in establishing and managing these connections, clients can fully leverage the advantages of public cloud services. They can enjoy the benefits while maintaining privacy and control, akin to a private network environment. Our commitment lies in enabling seamless integration between clients and leading cloud platforms, allowing them to maximize their cloud capabilities with confidence and peace of mind.</p></div>

<div className="textL reblued"><h4>Validation and Certification</h4>

<p>To ensure cost-effective testing and timely deployment, it is crucial to conduct thorough testing well in advance of network deployment. This guarantees a satisfactory return on investment for both the enterprise and the operator. By partnering with Procal, a trusted expert, right from the design phase, you can ensure that your private networks are not only capable of supporting a wide range of scenarios but also highly secure. This proactive approach allows you to address potential issues and achieve a seamless roll-out with confidence.</p></div>

</div>
</div>

<div className="image-wrap">
<div className="image " ref={el => imagesRef.current.push(el)}></div>
<div className="image uic" ref={el => imagesRef.current.push(el)}></div>
<div className="image ddd" ref={el => imagesRef.current.push(el)}></div>
<div className="image orange" ref={el => imagesRef.current.push(el)}></div>
<div className="image purple" ref={el => imagesRef.current.push(el)}></div>
<div className="image dddw" ref={el => imagesRef.current.push(el)}></div>
<div className="image red" ref={el => imagesRef.current.push(el)}></div>
<div className="image reblued" ref={el => imagesRef.current.push(el)}></div>
</div>



</section>
</div>

</div>

</>
)
}

export default Networkexpert;