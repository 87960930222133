import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Book from './book';
import Footer from './../../common/Footer'

import fea5 from './../../images/fea_5.png'
import fea6 from './../../images/fea_6.png'
import fea7 from './../../images/fea_7.png'

import Slider from "react-slick";
import './../solutions/mobile.css'


import cutting from './../../images/fea11.png'
import mockupall from './../../images/arvr-left.png'

// apple ani end
function Xrvr() {



  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.out', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: "center center",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)
      .from('.image-box0 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box0",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)


      .from('.image-box1 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box1",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box2 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box2",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box3 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box3",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0);



  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });




  return (
    <>
    <Helmet>
    <title>Extended Reality (XR) Development Solutions | AR VR Solutions</title>
    <meta name="description" content="we aim to enable businesses and organizations to leverage XR/AR/VR technologies, creating captivating, interactive, and unforgettable experiences for their target audiences." />
    <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Extended Reality (XR) Development Solutions | AR VR Solutions" />
	<meta property="og:description" content="we aim to enable businesses and organizations to leverage XR/AR/VR technologies, creating captivating, interactive, and unforgettable experiences for their target audiences." />
	<meta property="og:url" content="https://www.procal.ai//solutions/xr-vr" />
	<meta property="og:site_name" content="Procal Technologies" />
        </Helmet>
      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div>  */}



              <div class="section-head__title flex-basis-666">
                <div class="image-box">
                  {/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
                </div>
           
                <h1 class="title-outline">
                XR 
                </h1>
          

                <h2 class="title-outline">
                AR/VR
                </h2>
                <span class="title--outline green">
                AR/VR
                </span>
                <span class="title--outline green">
                AR/VR
                </span>
                <span class="title--outline green">
                AR/VR
                </span>
                <span class="title--outline green">
                AR/VR
                </span>
                <span class="title--outline green">
                AR/VR
                </span>
                <span class="title--outline green">
                AR/VR
                </span>
              </div>
              <div className='sidetext'>
                <div class="image-box imgprop">
                  <img src={mockupall } className='img-fluid' />

                </div>
              </div>

            </section>
          </div>





        </div>


      </section>

      <section className="container-fluid iot-sec4 ptop  pb-5 erer best-text">
        <div class="container">

          <div class="row d-flex align-items-center">



            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5"></h2>
                <h4>XR/AR/VR</h4>
                <p>Extended reality (XR) technologies have revolutionized our interaction with digital information and the world, offering immense potential across diverse industries. At Procal, we have a team of skilled developers and designers who are deeply passionate about crafting cutting-edge immersive experiences. Our primary objective is to enable businesses and organizations to leverage XR/AR/VR technologies, creating captivating, interactive, and unforgettable experiences for their target audiences. By closely collaborating with our clients, we gain a thorough understanding of their unique requirements and objectives. Leveraging our team of software developers, AR/VR predictive analysts, design/graphic engineers, and game developers, we then develop tailored solutions that not only meet but exceed expectations. Our Procal SMEs are proficient in crafting next-generation solutions for all enterprises, including education, training, entertainment, manufacturing, automotive, military, healthcare, and gaming.</p>


    


              </div>
            </div>

            <div class="col-md-5 col-lg-5 offset-lg-2 image-box0">
              <img src={fea7} className='img-fluid' />
            </div>
          </div>

          <div class="row d-flex align-items-center mt-30">
          <div class="col-md-7 col-lg-5 image-box1">    <img src={fea5} className='img-fluid' /></div>
          <div class="col-md-5 col-lg-5 offset-lg-2">
            <h4>Action  <span className='bluefont'>360 </span></h4>
                <p>Procal's team excels in VR content creation, using both CGI/VFX and live action 360 methodologies to deliver fully virtual and immersive experiences. With CGI/VFX, we harness computer-generated imagery and visual effects to construct virtual environments that offer limitless creative possibilities. Conversely, our live action 360 approach enables viewers to experience a 360-degree view of real-life environments or events. By seamlessly stitching together panoramic views, we create immersive and engaging virtual experiences.</p></div>
      
          </div>

          <div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
  <div class="txt-block right-column wwhite-color wow fadeInLeft" >

<h4>Reality <span className='bluefont'>Experiences</span></h4>

    <p>With web and mobile augmented reality (AR), we deliver enhanced reality experiences to users' personal devices, creating engaging opportunities for user engagement, marketing, training and learning. Our experienced team collaborates closely with clients to develop customized AR solutions, from virtual showrooms to AR-infused games, apps, and educational tools. We provide end-to-end services, including consultation, concept development, design, programming, testing, and ongoing support, leveraging the latest AR development tools and technologies. Additionally, Procal excels in leveraging XR/AR/VR for interactive data visualization, crafting immersive and intuitive tools and dashboards for exploring complex data sets. By seamlessly integrating data visualization into the XR/AR/VR landscape, we enhance innovation, accessibility, and the overall user experience, ensuring our solutions are fast, reliable, and user-friendly.</p>



  </div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
  <img src={fea6} className='img-fluid' />
</div>
</div>




        </div>
      </section>



    {/* whats new section */}
    <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">


            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>
         

          </div>


        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>


            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="XR VR" />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>VR Content Creation</h4>
                
                  <p>Procal's team excels in crafting fully virtual and immersive experiences through VR content creation. Utilizing both CGI/VFX and live action 360 methodologies, we construct virtual environments that offer limitless creative possibilities. With CGI/VFX, we leverage computer-generated imagery and visual effects to bring virtual worlds to life. Our live action 360 approach enables viewers to experience a 360-degree view of real-life environments or events. By seamlessly stitching together panoramic views, we create immersive and engaging virtual experiences. Our expertise in VR content creation allows us to deliver captivating and interactive narratives for various industries, enhancing user engagement and leaving a lasting impact on audiences.</p>
               
                </div>


              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="XR VR" />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Web and Mobile Augmented Reality (AR)</h4>
                
                  <p>Procal specializes in delivering enhanced reality experiences directly to users' personal devices through web and mobile AR. We collaborate closely with clients to develop customized AR solutions tailored to their specific needs and goals. Whether it's creating virtual showrooms, AR-infused games and apps, or educational tools, our experienced team provides end-to-end services, including consultation, concept development, design, programming, testing, and ongoing support. By leveraging the latest AR development tools and technologies, we enable businesses to engage their audiences, enhance marketing efforts, and provide immersive learning experiences. Our user-friendly and reliable AR solutions empower clients to leverage the full potential of this emerging technology.</p>
             
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="XR VR" />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Interactive Data Visualization</h4>
               
                  <p>Procal excels in leveraging XR/AR/VR technologies for interactive data visualization. We develop immersive and intuitive tools and dashboards in virtual reality, enabling users to explore and comprehend complex data sets in a more intuitive and engaging way. By seamlessly integrating data visualization into the XR/AR/VR landscape, we enhance innovation, accessibility, and the overall user experience. Whether it's analyzing business metrics, scientific data, or market trends, our data visualization solutions provide a new level of insight and understanding. Clients benefit from our expertise in transforming data into immersive visualizations that facilitate decision-making, communication, and exploration of information in a virtual environment.</p>
               
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="XR VR" />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Immersive Learning</h4>
    
                  <p>Students can utilize XR to investigate and analyze their subject matter in an immersive 3D environment. With Procals expertise, universities and schools can help students understand complex concepts easily by visualizing them and gamifying the student experience with complete control and safety. With Procals skills, educational and training institutions can achieve higher student involvement and knowledge retention with real world examples that can be visualized easily.</p>
   
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="XR VR" />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Device Certification</h4>
    
                  <p>Procal’s device certification solution ensures your XR/VR devices achieve seamless compliance and reliability through thorough testing and evaluation. Our efficient certification process accelerates time-to-market, providing you with a competitive advantage in the rapidly changing landscape of immersive technology. With rigorous assessments against industry standards and regulations, you can have confidence that your devices meet the highest quality benchmarks, promoting customer satisfaction and simplifying regulatory compliance. Our dedication to excellence empowers your business to thrive in the dynamic world of XR/VR.</p>
   
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="XR VR" />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Device Diagnostics</h4>
    
                  <p>Procal’s device diagnostics solution transforms business analysis by delivering comprehensive performance insights specifically for XR/VR technology. Utilizing advanced algorithms and state-of-the-art technology, our solution enables real-time monitoring, precise fault detection, proactive maintenance alerts, and in-depth analytics. By leveraging our expertise, organizations can optimize operations, enhance productivity, extend the lifespan of their devices, and achieve significant cost savings. Improved customer satisfaction follows, allowing businesses to establish a strong competitive advantage in the immersive technology sector.</p>
   
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3  offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="XR VR" />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>4G/5G Standards and Certifications</h4>
    
                  <p>Procal excels in providing tailored and cost-effective solutions that comply with industry standards and certifications, such as GCF and PTCRB, for XR/VR devices utilizing 4G and 5G technology. Our knowledgeable team is committed to delivering customized solutions that meet each client's unique needs, ensuring satisfaction and success in the fast-paced technological landscape. With our meticulous approach and attention to detail, we aim to exceed expectations and deliver exceptional value to our clients in the XR/VR space.</p>
   
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}



      </section>


      {/* whats new section end */}

      <Book/>


      <Footer />
    </>
  )
}

export default Xrvr
