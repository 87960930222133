import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/managed-security-top.jpg'
import Book from './book';
import fea2 from './../../images/managed-left.jpg'
import fea4 from './../../images/managed-center.png'

import Slider from "react-slick";
import './../solutions/managed-security.css'
import './../solutions/sport-analytics.css'


import cloud from './../../images/network-ana.jpg'

import cutting from './../../images/managed-expert.jpg'


// apple ani end
function ManagedSecurity() {



    const imageRef = useRef(null);
    const ref = useRef(null);

    useGSAP(() => {

        gsap.registerPlugin(ScrollTrigger);

        const targets = document.querySelectorAll('.list li');

        gsap.set('.list', { autoAlpha: 1 });

        const timeline = gsap.timeline({
            defaults: {
                overwrite: 'auto',
                ease: 'power1',
            },
            scrollTrigger: {
                end: '+=300%',
                pin: true,
                pinSpacing: true,
                scrub: true,
                start: 'top top',
                trigger: '.list',
            },
        });

        const stagger = 0.8;
        const duration = 1;

        gsap.set('.list', { autoAlpha: 1 });

        timeline.set(targets, { transformOrigin: 'center bottom' });

        timeline
            .from(targets, {
                duration: duration,
                opacity: 0,
                rotationX: 0,
                rotationY: 0,
                scale: 1,
                stagger: stagger,
                yPercent: 90,
            })
            .to(
                targets,
                {
                    duration: duration,
                    opacity: 0,
                    rotationX: 20,
                    rotationY: 2,
                    scale: 0.75,
                    stagger: stagger,
                    y: -100,
                },
                stagger
            );

        // Animation paragraph

        const element = ref.current;

        const pl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: 'top bottom-=20%',
                end: 'bottom top-=20%',
                scrub: true,
            },
        });

        pl.from(element, {
            opacity: 0,
            x: -100,

            duration: 1,
            ease: 'power2.out',
        });


        // Animation for the image reveal effect
        gsap.from(imageRef.current, {
            clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
            duration: 0.5, // Adjust duration
            ease: 'power4.in', // Adjust easing
            scrollTrigger: {
                trigger: '.erer',
                start: "top bottom",
                end: ".z-index-fyv",
                scrub: true,
                onUpdate: self => {
                    const progress = self.progress;
                    const points = [
                        `0% 0%`,
                        `${progress * 100}% 0%`,
                        `${progress * 100}% 100%`,
                        `0% 100%`
                    ];
                    gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
                },
            },
        });




        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.section-head-outline',
                start: 'top top',
                end: 'bottom top',
                scrub: 0.2,
            },
            ease: 'power.inOut',
        });

        tl.to('.title-outline', {
            yPercent: 100,
        })
            .to('.title--hello', {
                yPercent: 100,
            }, 0)
            .to('.title--outline', {
                yPercent: (i) => (i + 1) * 15 + 100,
            }, 0)
            .from('.image-box img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)

            .from('.image-box-center img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box-center",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)
            .from('.image-box0 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box0",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)


            .from('.image-box1 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box1",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box2 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box2",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box3 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box3",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0);



    });

    // slick slider code start
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings2 = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        waitForAnimate: false
    };
    // slick slider end
    useEffect(() => {


    });




    return (
        <>
<Helmet>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Managed Security Services (MSS) | Cybersecurity | Procal" />
            <meta property="og:description" content="Leveraging our expertise and advanced tools, we offer a tailored end-to-end security solution from L1 to L3, enabling organizations to focus on core operations while entrusting security to us" />
            <meta property="og:url" content="https://www.procal.ai/solutions/managed-security-services/" />
            <meta property="og:site_name" content="Procal Technologies" />

            </Helmet>
            <Menu />




            <section className='relative1  five-sec-2 start'>


                <div className='container'>
                    <div class="outline-box">
                        <section class="section-outline section-head-outline">
                            {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


                            <div className='col-lg-7'>
                                <div class="section-head__title flex-basis-666">

                                    <h1 class="title-outline">
                                        Managed
                                    </h1>
                                    <h2 class="title-outline">
                                        Security
                                    </h2>

                                    <h2 class="title-outline">
                                        Services
                                    </h2>
                                    <span class="title--outline green">
                                        Services
                                    </span>
                                    <span class="title--outline green">
                                        Services
                                    </span>
                                    <span class="title--outline green">
                                        Services
                                    </span>
                                    <span class="title--outline green">
                                        Services
                                    </span>
                                    <span class="title--outline green">
                                        Services
                                    </span>
                                    <span class="title--outline green">
                                        Services
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='sidetext'>
                                    <div class="image-box">
                                        <img src={girl} className='img-fluid' />
                                    </div>
                                </div></div>


                        </section>
                    </div>





                </div>


            </section>

            <section className="container iot-sec4 ptop  pb-5 erer">
                <div class="container">

                    <div class="row d-flex align-items-center1">


                        <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
                            <img
                                ref={imageRef}
                                src={cloud}
                                alt="Your Image"
                                className="img-fluid"
                            />
                        </div>

                        <div class="col-md-8 col-lg-8">
                            <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Managed Security Services (MSS)</h2>
                                <p>In today's digital landscape, safeguarding organizational data, infrastructure, and operations is
                                    imperative due to the increasing frequency and sophistication of cyberattacks. Businesses,
                                    regardless of size or industry, face the ongoing challenge of protecting sensitive information
                                    and ensuring system reliability.</p>

                                <p>Understanding the multifaceted nature of cybersecurity challenges is crucial. Cybercriminals
                                    relentlessly target networks, employing various attack vectors such as malware, ransomware,
                                    phishing, social engineering, and advanced persistent threats (APTs). These well-coordinated
                                    attackers are motivated to compromise the security defenses.</p>






                            </div>
                        </div>

                        <div class="col-md-4 col-lg-4 image-box">
                            <img src={fea2} className='img-fluid' />
                        </div>

                        <div class="col-md-12 col-lg-12">






                        </div>
                    </div>
                </div>
            </section>

            {/* center section */}
            <div class="container-rze u-position-21c">
                <div class="row-jsz u-mb-17o u-z-index-vag">
                    <div class="col-wjg col-phw"></div>
                    <div class="col-wjg col-e7o">
                        {/* <h2 class="style-GAVo4" id="style-GAVo4">
                <div class="text-vn6" >At the heart</div>
                <div class="snipcss0-4-5-7" > of our data analytics</div>
                <div class="text-ggq"> solutions</div>
            </h2> */}
                    </div>
                </div>
                <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
                    <div class="col-wjg col-phw"></div>
                    <div class="col-wjg col-z42">
                        <h6 class="des-5xpt-ggq sidefont" >Cybercriminals employ</h6>
                        <h6 class="des-5xpt-ggq sidefont" >malware, ransomware</h6>
                        <h6 class="des-5xpt-ggq sidefont" >malware, ransomware</h6>
                    </div>
                </div>

                <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-z42">
            <h6 class="des-5xpt-ggq" >Phishing, social engineering, and advanced persistent threats (APTs), Cybersecurity is the key to these safeguard against these attacks</h6>
        </div>
    </div>
                <div class="row-8oo">
                    <div class="col-wjg col-b63"></div>
                    <div class="col-wjg col-b63">
                        <div class="u-img-a9d image-box-center"><img src={fea4} class="u-img-hcf" /></div>
                    </div>
                    <div class="col-wjg col-b63"></div>
                </div>
                <div class="row-jsz u-mb-17o u-z-index-vag">
                    <div class="col-wjg col-s8h" ></div>
                    <div class="col-wjg col-e7o">
                        <p class="style-CgO1k" id="style-CgO1k"></p>
                        <div class="text-vn6 ygqvp" >digital landscape,  </div>
                        <div class="text-vn6 ygqvp" >safeguarding ,</div>
                        <div class="text-vn6 ygqvp" >organizational</div>
                        <div class="text-vn6 ygqvp" >infrastructure, and operations </div>
                  
                        <p></p>
                    </div>
                </div>
                <div class="row-jsz u-z-index-vag">
                    <div class="col-wjg col-phw"></div>
                    <div class="col-wjg col-e7o">
                        <p class="style-RWfkB" id="style-RWfkB"></p>
                        <div class="ygqvp text-ggq" >from start</div>
                        <div class="ygqvp" >to finish</div>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* center section end */}
            <section className="container-rom container-managed ali-fxl">
                <div className="container-d1s">
                    <div className="container-rom container-2e2">
                        <div className="container-d1s">
                            <div className="wrapper-ti2">
                                <div className="column-o8o column-fhi">
                                    <section className="container-rom container-coh ani-lcm fa-6jh">
                                        <div className="container-d1s" style={{ backgroundColor: '#898989', padding: '28px' }}>
                                            <h2 className="gb-headline-6nz">Leveraging our expertise and advanced tools</h2>
                                            <p className="col-oe2" style={{ fontSize: 16 }}>
                                                As threats grow in scale and complexity, businesses must defend against not only known
                                                attacks but also stay vigilant against emerging vulnerabilities. The expanding attack surface,
                                                driven by remote work, connected devices, and cloud services, poses additional challenges.
                                                At Procal, we prioritize security. Our Managed Security Services (MSS) aim to strengthen
                                                defenses, mitigate risks, and ensure the confidentiality, integrity, and availability of critical
                                                assets. Leveraging our expertise and advanced tools, we offer a tailored end-to-end security
                                                solution from L1 to L3, enabling organizations to focus on core operations while entrusting
                                                security to us.
                                            </p>



                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* whats new section */}
            <section className="container sec10 py-5 p-5 pb-5">
                <div className="row py-lg-5">
                    <div className="col-lg-12 col-md-8 mx-auto">


                        <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


                    </div>


                </div>

                {/* slicky slider */}
                <div className="slider-container">

                    <Slider {...settings2}>



                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Security Monitoring and Threat Detection</h4>

                                    <p>Procal's security experts continuously monitor your network, systems, and applications for potential threats and vulnerabilities. Leveraging advanced security information and event management (SIEM) technology, we identify anomalous activities, intrusion attempts, and potential security breaches in real time. Our proactive approach allows us to detect and respond to security incidents swiftly, minimizing the impact on your organization.</p>

                                </div>


                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Incident Response and Resolution</h4>

                                    <p>Our rapid incident response team is prepared to take action in the event of a security incident. We follow established incident response protocols, containing and mitigating threats to prevent further damage. Our goal is to minimize downtime, data loss, and financial impact while providing you with a detailed post-incident analysis for continuous improvement.</p>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Security Management and Policy Enforcement</h4>

                                    <p>Procal works closely with your organization to develop and enforce robust security policies tailored to your specific needs. We help you stay compliant with industry regulations and standards while ensuring that your security measures align with your business goals. Our proactive security management approach includes regular security assessments and recommendations to enhance your overall security posture.</p>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Security Patching and Updates</h4>

                                    <p>Procal ensures that all software, operating systems, and applications are promptly updated with the latest security patches to address known vulnerabilities before impacting the network elements.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Firewall and Network Security</h4>

                                    <p>We proactively manage and configure firewalls and other network security endpoints to control and monitor incoming and outgoing network traffic, minimizing the impact on network and productivity for our clients.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Security Awareness Training</h4>

                                    <p>Procal offers security awareness training that educates your employees and stakeholders on best practices, recognizing social engineering attempts, and understanding their role in maintaining a secure environment.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Compliance and Regulatory Support</h4>

                                    <p>Maintaining compliance with industry regulations and standards can be challenging. Procal ensures that your security measures align with these requirements, providing support in achieving and maintaining compliance to protect your reputation and avoid legal consequences.</p>
                                    <p>Procal's MSS empowers your organization with the security expertise, technologies, and processes needed to address the ever-growing challenges of the digital world. By partnering with us, you can focus on your core business objectives with confidence, knowing that your data and operations are protected by a dedicated team of security professionals who are committed to your success.</p>

                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>

                {/* slicky slider end */}



            </section>


            {/* whats new section end */}


            <Book/>

            <Footer />
        </>
    )
}

export default ManagedSecurity
