import React from 'react';
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import './digtalbanking.css';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import whatsnew from './../images/satelite.png'
import butterfly from './../images/butterfly.png'
import cloud from './../images/cloud-3d.jpg'
import Footer from '../common/Footer';
import Menu from '../common/Menu';
import Company from '../pages/company/Company';
import arrow from './../images/arrow-right.svg';
import snap from './../images/cutting-edge-bg.jpg';
import girl from './../images/girl-sml.jpg'

import { Timeline } from 'gsap/gsap-core';
import Contact from '../common/contact/Contact';
import { Helmet } from 'react-helmet';

import { useGSAP } from "@gsap/react";


function DigitalBanking() {



  const imageRef = useRef(null);
  const ref = useRef(null);
  
  useGSAP(() => {
  
  gsap.registerPlugin(ScrollTrigger);
  
  const targets = document.querySelectorAll('.list li');
  
  gsap.set('.list', { autoAlpha: 1 });
  
  const timeline = gsap.timeline({
  defaults: {
  overwrite: 'auto',
  ease: 'power1',
  },
  scrollTrigger: {
  end: '+=300%',
  pin: true,
  pinSpacing: true,
  scrub: true,
  start: 'top top',
  trigger: '.list',
  },
  });
  
  const stagger = 0.8;
  const duration = 1;
  
  gsap.set('.list', { autoAlpha: 1 });
  
  timeline.set(targets, { transformOrigin: 'center bottom' });
  
  timeline
  .from(targets, {
  duration: duration,
  opacity: 0,
  rotationX: 0,
  rotationY: 0,
  scale: 1,
  stagger: stagger,
  yPercent: 90,
  })
  .to(
  targets,
  {
  duration: duration,
  opacity: 0,
  rotationX: 20,
  rotationY: 2,
  scale: 0.75,
  stagger: stagger,
  y: -100,
  },
  stagger
  );
  
  // Animation paragraph
  
  const element = ref.current;
  
  const pl = gsap.timeline({
  scrollTrigger: {
  trigger: element,
  start: 'top bottom-=20%',
  end: 'bottom top-=20%',
  scrub: true,
  },
  });
  
  pl.from(element, {
  opacity: 0,
  x: -100,
  
  duration: 1,
  ease: 'power2.out',
  });
  
  
  // Animation for the image reveal effect

  
  
  
  const tl = gsap.timeline({
  scrollTrigger: {
  trigger: '.section-head-outline',
  start: 'top top',
  end: 'bottom top',
  scrub: 0.2,
  },
  ease: 'power.inOut',
  });
  
  tl.to('.title-outline', {
  yPercent: 100,
  })
  .to('.title--hello', {
  yPercent: 100,
  }, 0)
  .to('.title--outline', {
  yPercent: (i) => (i + 1) * 5 + 100,
  }, 0)
  .from('.image-box img', {
  yPercent:25,
  ease: "none",
  scrollTrigger: {
  trigger: ".outline-box",
  start: "top center",
  scrub: 1,
  
  },
  ease: 'power.inOut',
  }, 0)
  .from('.image-box0 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box0",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0)
  
  
  .from('.image-box1 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box1",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0)
  
  .from('.image-box2 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box2",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0)
  
  .from('.image-box3 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box3",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0);
  
  
  
  });
  
  // slick slider code start
  const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
  };
  
  const settings2 = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {
  
  });
  return (
    <>

<Helmet>
      <title>Fusing Mastery with Cutting-Edge Technology | Snap Procal</title>
      <meta name="description" content="Fusing Mastery with Cutting-Edge Technology
Led by a team of seasoned experts, we amalgamate years of industry experience with the latest advancements in technology. Our skilled professionals harness the potential of AI, machine learning, blockchain, and other cutting-edge tools to create robust, scalable, and future-proof software solutions that exceed expectations." />
    </Helmet>
      <Menu />
      <section className='digi'>
  <article>
    <div className='container'>

      <div className='col-lg-12 co-12 col-xxl-12 absolute'>
        <div className='fullimg image-box'>
          <img src={cloud}/>
        </div>

        <div className='fulltxt'>  
        <div id="digital-wrap">

<h1 className='digifont'>Digital Banking Solutions Offering by Procal Technologies</h1>
  <p class="1sub_text mt-3"> Welcome to Procal, where innovation meets execution. We are a dynamic startup dedicated to transforming visionary ideas into market-ready products through cutting-edge technology and strategic development. Our comprehensive service offering covers every stage of the technology and product development lifecycle, ensuring your concept evolves into a successful product. Here’s how we can help:</p>
  {/* <img src="stickers.png" width="300px" /> */}
</div>
            
            </div>
      </div>
    </div>
  </article>
</section>


      <section className=" iot-sec4 1ptop  pb-5 erer digitalbank">
        <div class="container">

          <div class="row d-flex align-items-center1 mt123">



            <div class="col-md-6 col-lg-6 offset-lg-1">
            <div className="section-bg" >
        <div id="banking">
          <div id="1digital-wrap">

          <h2 className='digifont'>Your Digital Banking Companion</h2>
            <p class="1sub_text mt-3">At Procal Technologies, we empower financial institutions with innovative digital banking solutions. Our expertise ensures your organization stays secure and competitive in the evolving digital landscape.</p>

            <p>We provide tailored services that mitigate risks that are a major concerns regarding Banking. With a commitment to ongoing support and continuous improvement, we help you navigate the complexities of digital banking with confidence.</p>
            <p>Partner with Procal Technologies for a secure, compliant, and efficient banking future.</p>
            {/* <img src="stickers.png" width="300px" /> */}
          </div>
        </div>
      </div>
            </div>

            <div class="col-md-5 col-lg-5 image-box">
              <img src={butterfly} className='img-fluid' alt='Digital Banking'  />
            </div>
        
          </div>
        </div>
      </section>
 
  
      <section className=" iot-sec4 1ptop  pb-5 erer digitalbank-sec3">
        <div class="container">

          <div class="row d-flex align-items-center1">
      


<div class="col-md-4 col-lg-4 offset-lg-0">
<div className="section-bg" >
<div id="banking1">
<div id="1digital-wrap">

<h3 className='digi-text text-ggq'>Virtual Banking</h3>
<p class="1sub_text mt-3">Experience seamless banking from anywhere with our advanced virtual banking solutions. Procal Technologies provides secure, user-friendly platforms that empower customers to manage their finances online with ease. Our tools ensure 24/7 access to banking services, enhancing convenience and customer satisfaction.</p>
{/* <img src="stickers.png" width="300px" /> */}
</div>
</div>
</div>
</div>

<div class="col-md-4 col-lg-4 offset-lg-0">
<div className="section-bg" >
<div id="banking2">
<div id="1digital-wrap">

<h3 className='digi-text text-ggq'>Personalized Banking</h3>
<p class="1sub_text mt-3">At Procal Technologies, we believe in tailored financial solutions. Our personalized banking services leverage data analytics to offer customized products and services that meet individual customer needs. This approach fosters stronger relationships and enhances the overall banking experience.</p>
{/* <img src="stickers.png" width="300px" /> */}
</div>
</div>
</div>
</div>

<div class="col-md-4 col-lg-4 offset-lg-0">
<div className="section-bg" >
<div id="banking3">
<div id="1digital-wrap">

<h3 className='digi-text text-ggq'>Community Forum</h3>
<p class="1sub_text mt-3">Join our community forum to engage, share insights, and collaborate with fellow banking professionals and customers. This platform encourages open discussions on trends, challenges, and innovations in banking, fostering a supportive network for knowledge sharing and growth.</p>
{/* <img src="stickers.png" width="300px" /> */}
</div>
</div>
</div>
</div>

       
          </div>

          <div className='row ptop22'>
          <div>
    <section class="switch-osn switch-dip">
        <div class="sta-r86 max-4tv">
            <div class="sta-r86">
            <h2 class="digifont">Go Beyond Banking</h2>
                {/* <h2 class="hea-218"></h2> */}
                <h4><strong>Innovate like a neobank</strong></h4>
                <p>Don't just meet expectations—exceed them and become indispensable to your customers.</p>
                <p>Our digital banking platform is equipped with advanced features that truly make a difference for customers and members, including bill payments, financial wellness tools, and much more.</p>
            </div>
        </div>
        <div class="sta-r86 image-box">
            <figure class="image-y2v"><span id="style-mlF4J" class="style-mlF4J"><span id="style-IxVOk" class="style-IxVOk"></span><img src="https://www.backbase.com/_next/image?url=https%3A%2F%2Fapi-site.backbase.com%2Fimages%2FbbProduct%2Fdigital-banking-go-beyond-banking.png&amp;w=3840&amp;q=75" id="style-qfoSG" class="style-qfoSG" /></span></figure>
        </div>
    </section>
</div>



          </div>

          <div className='row 22'>
          <div>
    <section class="switch-8wm switch-v5t">
        <div class="sta-my7 max-av6">
            <div class="sta-my7">
            <h2 class="digifont">Open to fintechs</h2>
               
                <h4><strong>Tap into the best partners</strong></h4>
                <p>Our digital banking platform is pre-integrated with top-tier fintech partners, allowing you to instantly transform the customer experience.</p>
                <p>From remote deposit capture to account aggregation, get access to a vibrant ecosystem within the safety of the Our  Engagement Banking Platform.</p>
            </div>
        </div>
        <div class="sta-my7 image-box">
            <figure class="image-mqh minuz25"><span id="style-WCgKs" class="style-WCgKs"><span id="style-MYTwe" class="style-MYTwe"></span><img src="https://www.backbase.com/_next/image?url=https%3A%2F%2Fapi-site.backbase.com%2Fimages%2FbbProduct%2Fdigital-banking-open-to-fintechs.png&amp;w=3840&amp;q=75" id="style-dwbko" class="style-dwbko" /></span></figure>
        </div>
    </section>
</div>
          </div>
        </div>
      </section>

      <section className="container-rom container-vul-bank ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fhi">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
<h2 className="gb-headline-6nz">Digital Banking Solutions</h2>
<p className="col-oe2" style={{ fontSize: 16 }}>
Procal Technologies offers cutting-edge digital banking solutions designed to enhance efficiency and security for financial institutions. Our comprehensive suite of tools empowers banks to provide seamless online services, ensuring customers can access their accounts anytime, anywhere. With a focus on user experience and regulatory compliance, Procal enables banks to innovate and stay competitive in the rapidly evolving digital landscape. Partner with us to transform your banking operations and deliver exceptional value to your customers.
</p>



</div>
</section>


</div>
</div>
</div>
</div>
</div>
</section>

      {/* <Contact /> */}
      <Footer />
    </>
  )
}

export default DigitalBanking
