import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css';
import './../solutions/esim.css'
import { Helmet } from "react-helmet";
import Book from './book';
const CloudExport = () => {

const imagesRef = useRef([]);
const sideImagesRef = useRef([]);


// Initialize ScrollTrigger
useEffect(() => {
let ctx = gsap.context(() => {

gsap.registerPlugin(ScrollTrigger);

gsap.set(".image", { zIndex: (i, target, targets) => targets.length - i });
gsap.set(".side-image", { zIndex: (i, target, targets) => targets.length - i });

const images = imagesRef.current;
images.forEach((image, i) => {
const nextImage = image.nextElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "100%" }, { height: "0%", ease: "none" }, 0);
// You can add more animations here if needed
});

const sideImages = sideImagesRef.current;
sideImages.forEach((image, i) => {
const prevImage = image.previousElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "0%", scale: 1.00 }, { height: "100%", scale: 1.125, ease: "none" }, 0)
.fromTo(prevImage, { scale: 1.125 }, { scale: 1.25, ease: "none" }, 0);
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((images.length) * window.innerHeight),
pin: '.image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((sideImages.length) * window.innerHeight),
pin: '.side-image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});
});
return () => ctx.revert();
}, []); // Empty dependency array to run effect only once


return (

<>
<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Cloud Solutions | Enterprise Cloud Services | Procal" />
	<meta property="og:description" content="Explore our comprehensive suite of cloud services tailored to your needs and embark on a journey of innovation and growth. Start your transformation today" />
	<meta property="og:url" content="https://www.procal.ai/solutions/cloud/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>


<div className="scroller">

<div id='loc'>
<section className="showcase">
<div className="text-wrap">
<div className="text-wrapper">



<div className="textL blue">
<h4>Cloud Infrastructure</h4>

<p>Procal provides assistance to businesses of all sizes, helping them set up, configure, and manage their cloud infrastructure, whether they require low-latency solutions for mobile edge computing or more general cloud-based solutions. This encompasses tasks such as designing the architecture, provisioning virtual servers, and overseeing storage, networking, and security components. Our expertise lies in providing comprehensive support throughout the entire process of establishing and maintaining a reliable and secure cloud environment for businesses.</p>
</div>
<div className="textL c">
<h4>Cloud Migration</h4>

<p>Procal help organizations migrate their applications, data, and infrastructure from on-premises environments to the cloud. We assess existing systems, devise migration strategies, and execute the process with minimal disruption. Our expertise lies in seamlessly transitioning businesses to the cloud, ensuring efficient and secure transfer of assets while minimizing downtime and maintaining data integrity.</p>
</div>
<div className="textL green"><h4>Cloud Application Development/Deployment</h4>

<p>Procal specializes in cloud application development and deployment, assisting businesses in creating and launching cloud-based applications. We utilize cutting-edge cloud-native technologies like containers and serverless computing, along with the power of Platform-as-a-Service (PaaS) solutions. Our expertise ensures efficient development and deployment processes, allowing businesses to fully harness the benefits of the cloud environment for their applications.</p></div>

<div className="textL purple"><h4>Cloud Security</h4>

<p>Procal specializes in cloud security, safeguarding cloud environments and data against potential threats and vulnerabilities. Our expertise includes deploying access controls, encryption mechanisms, monitoring tools, and incident response processes. We ensure data protection and compliance with relevant security standards, offering peace of mind to our clients. With Procal, your cloud infrastructure remains secure and resilient, allowing you to focus on your core business activities.</p></div>




</div>
</div>

<div className="image-wrap d-lg-block d-none">
<div className="image cld" ref={el => imagesRef.current.push(el)}></div>
<div className="image cld" ref={el => imagesRef.current.push(el)}></div>
<div className="image cld" ref={el => imagesRef.current.push(el)}></div>
<div className="image cld" ref={el => imagesRef.current.push(el)}></div>
</div>



</section>
</div>

</div>

</>
)
}

export default CloudExport;