import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import Book from './book';
import esimleft from './../../images/esim.png'

import './../solutions/iot.css'
import './../solutions/esim.css'

import cloud from './../../images/cloud-solution.jpg'

import mockupall from './../../images/mockup-all.avif'
import Esimexpert from './../solutions/esimexpert';
// apple ani end
function Esim() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.out', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: "center center",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        }
      }, 0);

  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return; // Check if canvas element exists

    const ctx = canvas.getContext('2d');
    let animationFrameId;

    // Utils
    const mapRange = (a, b, c, d, e) => {
      return ((a - b) * (e - d)) / (c - b) + d;
    };
    const lerp = (v0, v1, t) => {
      return v0 * (1 - t) + v1 * t;
    };
    const random = (min, max) => min + Math.random() * (max - min);
    const sin = (t) => Math.sin(t);
    const cos = (t) => Math.cos(t);
    const PI = Math.PI;
    const TAO = PI * 2;
    const LOOP = 4;

    // Raf class
    class Raf {
      constructor() {
        this.raf();
      }

      raf() {
        if (this.onRaf) {
          window.requestAnimationFrame(() => {
            const o = {};
            o.time = window.performance.now() / 1000;
            o.playhead = (o.time % LOOP) / LOOP;
            this.raf();
            this.onRaf(o);
          });
        }
      }
    }

    // Canvas class
    class Canvas extends Raf {
      constructor() {
        super();
        this.canvas = canvas;
        this.ctx = ctx;
        this.resize();
        this.events();
      }

      resize() {
        this.dpr = window.devicePixelRatio;
        this.canvas.style.width = `${window.innerWidth}px`;
        this.canvas.style.height = `${window.innerHeight}px`;
        this.canvas.width = window.innerWidth * this.dpr;
        this.canvas.height = window.innerHeight * this.dpr;
      }

      events() {
        window.addEventListener('resize', this.resize);
      }

      clear() {
        this.ctx.clearRect(
          0,
          0,
          window.innerWidth * this.dpr,
          window.innerHeight * this.dpr
        );
      }

      onRaf() {
        this.clear();
      }
    }

    // Circle class
    class Circle extends Raf {
      constructor(obj) {
        super();
        Object.assign(this, obj);
        this.draw();
      }

      draw(playhead, time) {
        const breathe = sin(playhead * TAO);
        this.ctx.globalCompositeOperation = 'screen';
        this.ctx.save();
        this.ctx.translate(
          (window.innerWidth / 8) * this.dpr,
          (window.innerHeight / 8) * this.dpr - 30 * this.dpr
        );
        this.ctx.rotate(PI);

        this.ctx.strokeStyle = this.color;
        this.ctx.fillStyle = 'rgba(0, 100, 0, 0)';
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.beginPath();

        for (let i = 0; i <= this.points; i++) {
          const p = i / this.points;

          const times = 7;

          const phase = mapRange(
            cos(p * TAO),
            -1,
            1,
            1,
            mapRange(
              sin(((this.offset + time * this.speed) * 0.2 + p) * times * TAO),
              -1,
              1,
              0.5,
              0.58
            )
          );

          let x = phase * this.radius * sin(p * TAO);
          let y = phase * this.radius * cos(p * TAO);

          const type = i === 0 ? 'moveTo' : 'lineTo';
          this.ctx[type](x, y);
        }

        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.beginPath();
        this.ctx.fillStyle = this.color;
        this.ctx.fill();
        this.ctx.restore();
      }

      onRaf({ playhead, time }) {
        this.draw(playhead, time);
      }
    }

    // Initialization
    const canvasObj = new Canvas();
    for (let i = 0; i < 8; i++) {
      new Circle({
        ctx: canvasObj.ctx,
        dpr: canvasObj.dpr,
        lineWidth: 1 * canvasObj.dpr,
        points: 200,
        offset: i * 1.5,
        speed: 0.7,
        radius: (150 - i * 4) * canvasObj.dpr,
        color: `hsl(${220 + i * 10}, 60%, 70%)`,
      });
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);



  return (
    <>
  
<Helmet>
<title>eSim Technology Solutions | Customised to your needs </title>
<meta name="description" content="Our team of seasoned professionals leverages deep industry knowledge to provide scalable and secure eSIM services, ensuring your business stays ahead in the fast-evolving digital landscape." />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="eSim Technology Solutions | Customised to your needs | Procal" />
	<meta property="og:description" content="Our team of seasoned professionals leverages deep industry knowledge to provide scalable and secure eSIM services, ensuring your business stays ahead in the fast-evolving digital landscape." />
	<meta property="og:url" content="https://www.procal.ai/solutions/esim/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>
      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div>  */}



              <div class="section-head__title flex-basis-666">
                <div class="image-box">
                  {/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
                </div>
         

                <h1 class="title-outline">
                eSIM
                </h1>
                <span class="title--outline green">
                eSIM
                </span>
                <span class="title--outline green">
                eSIM
                </span>
                <span class="title--outline green">
                eSIM
                </span>
                <span class="title--outline green">
                eSIM
                </span>
                <span class="title--outline green">
                eSIM
                </span>
                <span class="title--outline green">
                eSIM
                </span>
              </div>
              <div className='sidetext'>
                <div class="image-box imgprop">
                  <img src={esimleft} className='img-fluid' />

                </div>
              </div>

            </section>
          </div>





        </div>


      </section>

      <section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft hero" >
             

                <h1 class="display-5 lh-1 mb-3 star">Embedded SIM</h1>
                <p>Procal is at the forefront of the mobile industry, offering exceptional eSIM (embedded SIM) solutions that harness the power of Subscriber Identity Modules (SIMs). With eSIM technology growing in popularity, enterprise and consumer users can enjoy unparalleled flexibility and convenience, eliminating the need for physical SIM card replacements when switching mobile network operators. Our innovative eSIM solutions are tailored to the needs of today's dynamic market, particularly benefiting enterprise and consumers who wants to switch carriers easily based on pricing, coverages and enhanced user experience</p>

                <p>eSIMs not only offer stability and reliability but also possess the unique capability to handle multiple activities across various locations, users, and networks. This versatility sets eSIMs apart from traditional SIM cards and enables Procal's solutions to provide flawless connections and user experiences. By combining the inherent advantages of eSIM technology with the robustness and agility of 5G networks, our solutions offer unmatched performance and stability.</p>

                <p>At Procal, our team of skilled developers and subject matter experts possesses extensive expertise in the entire spectrum of eSIM implementation. From application development, testing, and troubleshooting to device management, remote SIM provisioning, and secure authentication, our developers ensure seamless integration of eSIM technology into your existing infrastructure. We guide clients through the intricacies of eUICC, SMDP, SMDP+, SM-DS, and LPA development, adhering to industry standards and regulations set by the GSMA.</p>

                <p>Our commitment to excellence extends beyond technical proficiency. We collaborate closely with mobile network operators, device manufacturers, and end users to understand their unique requirements and challenges. This user-centric approach enables us to create dynamic and cost-effective eSIM solutions that address specific pain points and drive business success.</p>



              </div>
            </div>

            <div class="col-md-7 col-lg-7">
              <img src={mockupall} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      {/* whats new section */}
      <section className="container">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">


            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


          </div>


        </div>




      </section>



      <Esimexpert />



      <Book/>

      <Footer />
    </>
  )
}

export default Esim
