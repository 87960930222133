import React from 'react';
import Menu from './../../common/Menu';
import Book from './book';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'

import fea5 from './../../images/drone-ai.jpg'

import Slider from "react-slick";
import './../solutions/mobile.css'
import './../solutions/drone.css'

import cutting from './../../images/drone-girl1.png'
import mockupall from './../../images/drone.jpg'

// apple ani end
function Drone() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.out', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: "center center",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<title>Drone Technology Solutions | Unmanned Aerial Vehicles </title>
<meta name="description" content="Our team of experienced technical professionals ensures top-notch service for all your drone-related requirements. From drone deployment to data analysis, we have the expertise to elevate your operations" />
<meta property="og:locale" content="en_US" />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Data Analytics and Insights Services &amp; Solutions | Procal" />
	<meta property="og:description" content="Our team of experienced technical professionals ensures top-notch service for all your drone-related requirements. From drone deployment to data analysis, we have the expertise to elevate your operations" />
	<meta property="og:url" content="https://www.procal.ai/solutions/data-analytics-and-insights" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}



<div class="section-head__title flex-basis-666">
<div class="image-box">
{/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
</div>



<h1 class="title-outline">
DRONE
</h1>
<span class="title--outline green">
DRONE
</span>
<span class="title--outline green">
DRONE
</span>
<span class="title--outline green">
DRONE
</span>
<span class="title--outline green">
DRONE
</span>
<span class="title--outline green">
DRONE
</span>
<span class="title--outline green">
DRONE
</span>
</div>
<div className='sidetext'>
<div class="image-box imgprop">
<img src={mockupall} className='img-fluid' />

</div>
</div>

</section>
</div>





</div>


</section>

<section className="container-fluid iot-sec4 ptop  pb-5 erer best-text">
<div class="container">

<div class="row d-flex align-items-center">



<div class="col-md-5 col-lg-5">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5"></h2>
<h4>Drone Technology on Modern Industries</h4>
<p>Drone technology is revolutionizing industries by providing innovative solutions for a range of tasks, from aerial surveys and inspections to package deliveries. Equipped with advanced sensors and cameras, drones offer real-time data collection, improving efficiency and accuracy. They are increasingly used in agriculture, construction, and emergency response, enhancing productivity and safety. As regulations evolve, drones are set to play an even greater role in transforming business operations and everyday life.</p>





</div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box0">
<img src={fea5} className='img-fluid' />
</div>
</div>

<div class="row d-flex align-items-center mt-30">
<div class="col-md-7 col-lg-5 image-box1">    <img src={fea5} className='img-fluid' /></div>
<div class="col-md-5 col-lg-5 offset-lg-2">
<h4>Drone   <span className='bluefont'>Technology </span></h4>
<p>Drone technology has revolutionized the field of aerial operations by introducing unmanned aerial vehicles (UAVs) that can be controlled remotely or through programmed systems. These sophisticated machines are available in various sizes, ranging from compact quadcopters to larger aircraft, and boast a wide array of advanced sensors, cameras, and specialized equipment tailored to specific applications. The past few years have witnessed a remarkable surge in drone technology, with significant strides made in the development of flight control systems, battery life, and payload capacity. As a result, users now enjoy unparalleled experiences, while disruptions are minimized, and the integrity of data collection has reached new heights.</p></div>

</div>

<div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >

<h4>Aerial  <span className='bluefont'>Photography</span></h4>

<p>One of the primary functions of drones is the generation and collection of aerial photography and videography. When combined with Procals expert programming and artificial intelligence (AI) solutions, drone camera’s can decipher these images in real-time and initiate appropriate actions based on the analyzed visuals. The data collected by drones finds wide-ranging applications across various industries, including telecom, agriculture, construction, public safety, and environmental monitoring. In telecom, for instance, drones are utilized for troubleshooting and monitoring cell towers for any potential cable cuts or incorrect antenna tilts etc. In agriculture, drones are utilized for crop analysis, monitoring, and optimizing agricultural practices. Similarly, in construction, drones are employed for monitoring and inspecting construction sites, ensuring efficiency and safety. Moreover, drones can analyze public safety features such as traffic flow, crowd behavior, air quality, and water quality. In critical scenarios like search and rescue missions, aerial data collected by drones can serve as a crucial foundation for life-saving operations.</p>



</div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
<img src={fea5} className='img-fluid' />
</div>
</div>
<div class="row d-flex align-items-center mt-30">
<div class="col-md-7 col-lg-5 image-box1">    <img src={fea5} className='img-fluid' /></div>
<div class="col-md-5 col-lg-5 offset-lg-2">
<h4>High <span className='bluefont'>Resolution </span></h4>
<p>Procal, through our work with drones, consistently achieve successful collection of high-resolution data insights that proves invaluable across a wide range of customer solutions and business types. Our team of drone subject matter experts (SMEs) possesses extensive experience in overcoming obstacles and harnessing the power of 4G/5G networks to ensure flawless and reliable network performance. We are proficient in developing solutions for drone use cases in both line-of-sight (LOS) and non-line-of-sight (NLOS) communications scenarios. In NLOS conditions, where the communication signal must traverse obstacles, challenges such as signal attenuation, blockage, and multipath interference may arise. To overcome these limitations, Procal employs various techniques, including signal reflection, amplification, and advanced signal processing algorithms. By leveraging our expertise and harnessing the capabilities of 4G/5G networks, Procal consistently delivers exceptional results, enabling us to provide optimal solutions for our clients.</p></div>

</div>



</div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">
<Slider {...settings2}>


<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Drone"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>High-Resolution Data Collection</h4>

<p>Procal excels in the successful collection of high-resolution data using drones. With meticulous planning and execution, our team of drone subject matter experts (SMEs) ensures that clients obtain invaluable data for their specific needs. By leveraging cutting-edge technologies and advanced sensors, we capture detailed and accurate aerial imagery, facilitating precise analysis and informed decision-making processes. Our expertise in high-resolution data collection empowers clients across diverse industries, enabling them to gain valuable insights, improve operational efficiency, and drive business growth.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Drone"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Advanced Signal Processing and Optimization</h4>

<p>By employing cutting-edge algorithms and techniques, we enhance the quality and reliability of communication signals during drone operations. Our expertise in signal processing ensures that the collected data remains accurate and unaffected by attenuation, blockage, or multipath interference. Through continuous innovation and optimization, we guarantee that clients receive precise, high-quality data, enabling them to make informed decisions, optimize resource allocation, and unlock the full potential of their drone-based initiatives.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Drone"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>LOS and NLOS Communications</h4>

<p>Procal boasts proficiency in developing solutions for both line-of-sight (LOS) and non-line-of-sight (NLOS) communication scenarios. While LOS operations provide clear and direct communication pathways, NLOS conditions pose additional challenges due to obstacles that disrupt signal transmission. Our seasoned team of drone SMEs has honed their skills in tackling these obstacles, employing strategic approaches to ensure efficient communication in demanding environments. By leveraging our expertise in LOS and NLOS communications, we empower clients to accomplish their objectives effectively, irrespective of the communication scenario, and maximize the potential of their drone operations.</p>

</div>
</div>
</div>


</Slider>
</div>

{/* slicky slider end */}



</section>




{/* whats new section end */}



<Book/>
<Footer />
</>
)
}

export default Drone
