import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Book from './book';
import Footer from './../../common/Footer'

import esimleft from './../../images/asset-11-1.png'

import './../solutions/iot.css'
import './../solutions/esim.css'

import cloud from './../../images/cloud-solution.jpg'

import mockupall from './../../images/human-voice.png'


import Networkexpert from './../solutions/networkexpert'
// apple ani end
function PrivateNetwork() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.out', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: "center center",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        }
      }, 0)

      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".txt-block",
          start: "top center",
          scrub: 1,

        }
      }, 0);

  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });




  return (
    <>

<Helmet>
<title>Private Network Solution | USA, UK, UAE | Procal Technologiesy</title>
<meta name="description" content="By choosing Procal’s private network solutions, businesses benefit from enhanced data security, reduced latency, and increased network performance" />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Penetration Testing Services in USA, UK, UAE | Cybersecurity" />
	<meta property="og:description" content="By choosing Procal’s private network solutions, businesses benefit from enhanced data security, reduced latency, and increased network performance" />
	<meta property="og:url" content="https://www.procal.ai/solutions/private-networks/" />
	<meta property="og:site_name" content="Procal Technologies" />

            </Helmet>
      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div>  */}



              <div class="section-head__title flex-basis-666">
                <div class="image-box">
                  {/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
                </div>
                <h1 class="title-outline">
                  Private
                </h1>


                <h2 class="title-outline">
                  Network
                </h2>
                <span class="title--outline green">
                  Network
                </span>
                <span class="title--outline green">
                  Network
                </span>
                <span class="title--outline green">
                  Network
                </span>
                <span class="title--outline green">
                  Network
                </span>
                <span class="title--outline green">
                  Network
                </span>
                <span class="title--outline green">
                  Network
                </span>
              </div>
              <div className='sidetext'>
                <div class="image-box imgprop">
                  <img src={esimleft} className='img-fluid' />

                </div>
              </div>

            </section>
          </div>





        </div>


      </section>

      <section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft hero" >

                <h1 class="display-5 lh-1 mb-3 star">Private Network Client</h1>
                <p>Procal is a leading provider of tailored and cost-efficient private network solutions for businesses. With our expertise in network infrastructure and cutting-edge technology, we deliver custom-built solutions that meet the unique requirements of each private network client.</p>

                <p>At Procal, we understand that businesses need reliable and secure connectivity to support their operations and facilitate seamless communication. That's why we offer private network solutions designed to enhance productivity, protect sensitive data, and ensure uninterrupted connectivity. Our team of skilled professionals work closely with clients to analyze their specific needs and develop personalized solutions and insights. We leverage the latest advancements in networking technology to design robust and scalable solutions that can adapt to evolving business demands.</p>

                <p>By choosing Procal's private network solutions, businesses benefit from enhanced data security, reduced latency, and increased network performance. Our solutions are built with stringent security measures to safeguard sensitive information and protect against potential cyber threats. We optimize network resources, streamline operations, and implement intelligent traffic management techniques to maximize efficiency while minimizing costs.</p>

                <p>With Procal's custom and cost-efficient private network solutions, businesses can experience seamless connectivity, improved productivity, proactive insights and enhanced security. Partner with us to unlock the full potential of your network infrastructure and propel your business forward in today's digital landscape.</p>



              </div>
            </div>

            <div class="col-md-7 col-lg-7 image-box">
              <img src={mockupall} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      {/* whats new section */}
      <section className="container">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">


            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


          </div>


        </div>




      </section>



      <Networkexpert />




      <Book/>
      <Footer />
    </>
  )
}

export default PrivateNetwork
