import React from 'react';
import Menu from './../../common/Menu';
import logo from './../../images/logo.png';
import Footer from './../../common/Footer'
import './../../pages/solutions/ourclient.css'
import { Helmet } from "react-helmet";
import moto from './../../images/motorola-white-logo.png'
import tele from './../../images/televisa-white-logo.png'
import tmobile from './../../images/pngkey.png'
import united from './../../images/united-white-logo.png'
import version from './../../images/verizon.png'
import att from './../../images/jkk.png'
import erec from './../../images/ericsson-white-logo.png'
//import hp from './../../images/hp-white.svg'
import aws from './../../images/aws-logo.png'
import ibm from './../../images/ibm.png'
import google from './../../images/google-platform-logo.png'
import sales from './../../images/salesforce.png'
import del from './../../images/dell-logo.png'
import borad from './../../images/broadcom.png'
import refin from './../../images/refin.png'
import reuter from './../../images/reuters.png'
import locate from './../../images/locate-1.png'
import sony from './../../images/sony.png'
import fitech from './../../images/fitech.png'
import hp from './../../images/hp-logo.png'
import astro from './../../images/astro.png'
import os from './../../images/os.png'
import Book from './book';
// apple ani end
function Ourclient() {


  return (
    <>
<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="What We Do | Capabilities, Industries, and Partners | Procal" />
	<meta property="og:description" content="Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services." />
	<meta property="og:url" content="https://www.procal.ai/ourclient" />
	<meta property="og:site_name" content="Procal Technologies" />
</Helmet>
      <Menu />

      <section className='whatwe-client'>
                <div className='container py-5'>

                    <div className='1row mt-5'>
                        {/* <h1 className="tw-text-8xl tw-font-bold tw-line-through">
      Hello world!
    </h1> */}
                        <div className='col-12 col-lg-12 col-sm-12 what hero-head '><h1 className='new-title-outline'>Clientele</h1></div>
                        <div className='width22'>
                        <div className='new-lead'>Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services. </div>
                        </div>
                    </div>

                </div>
            </section>

      <section className='relative1  five-sec-2 start 2pt150 1clienbgall'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-11'> 


<div className="container">

<div class="wrap">
  {/* <h2 class="bigfont text-center">Clientele</h2>

  <p className="lead">Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services.</p> */}
  <ul class="clients">
    <li className='box-shadow-100'><a href=""><img src={moto} alt="Google" className="invert" /></a></li>
    <li className='box-shadow-100'><a href=""><img src={locate} alt="Microsoft" className="grayscale" /></a></li>
    <li className='box-shadow-100'><a href=""><img src={borad} alt="Microsoft" className="grayscale" /></a></li>

    <li className='box-shadow-100'><a href=""><img src={hp} alt="Google" className="grayscale" /></a></li>
  </ul>

  <ul class="clients">

    <li className='box-shadow-100'><a href=""><img src={tele} alt="Google" className="grayscale" /></a></li>
    <li className='box-shadow-100'><a href=""><img src={tmobile} alt="Apple" className="grayscale" /></a></li>
    <li className='box-shadow-100'><a href=""><img src={sony} alt="Microsoft" className="grayscale" /></a></li>
    <li className='box-shadow-100'><a href=""><img src={fitech} alt="Google" className="grayscale" /></a></li>
  </ul>
  <ul class="clients">
  <li className='box-shadow-100'><a href=""><img src={united} alt="Microsoft" className="invert" /></a></li>
          
              <li className='box-shadow-100'><a href=""><img src={reuter} alt="Google" className="grayscale" /></a></li>
   
              <li className='box-shadow-100'><a href=""><img src={astro} alt="Apple" className="grayscale" /></a></li>
              <li className='box-shadow-100'><a href=""><img src={erec} alt="Microsoft" className="grayscale" /></a></li>
  
            </ul>

            <ul class="clients">
              <li className='box-shadow-100'><a href=""><img src={refin} alt="Google" className="grayscale" /></a></li>
              <li className='box-shadow-100'><a href=""><img src={version} alt="Google" className="invert" /></a></li>
              <li className='box-shadow-100'><a href=""><img src={att} alt="Google" className="bright" /></a></li>
    
              <li className='box-shadow-100'><a href=""><img src={os} alt="Microsoft" className="grayscale" /></a></li>
    
            </ul>
</div>
{/* <div className="row align-items-center py-5 pos-20">


    <div className="col-lg-5 col-12 hero pt-5 animate-element-5"><h2 className="display-5 lh-1 mb-3 animate-text-2">Our Clients</h2>
      <p className="lead">Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services.</p>
    </div>

    <div className="col-lg-6 offset-lg-1 col-12">

      <div className="flex-container-client align-items-center animate-element-4ws">
        <div className="flex-items-client" ><img src={tmobile} className="img-fluid circle greycel" /></div>
        <div className="flex-items-client" ><img src={erec} className="img-fluid circle greycel" /></div>
        <div className="flex-items-client" ><img src={tele} className="img-fluid circle greycel" /></div>
        <div className="flex-items-client" ><img src={moto} className="img-fluid circle greycel" /></div>
      </div>
    </div>

  </div> */}
</div>
</div>
{/* <div className='col-lg-5'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={girl} className='img-fluid' />     
                </div>
              </div></div> */}


</section>
</div>





</div>


</section>
   

      <section className='1partner mt-5 2pt150'>
        <article>
          <div className='row'>


            <h2 class="bigfont text-center ribbon">Our Partnerships</h2>
            <div class="wrap">
              <ul class="clients">
                <li><a href=""><img src={aws} alt="Google" /></a></li>
                <li><a href=""><img src={ibm} alt="Google" /></a></li>
                <li><a href=""><img src={google} alt="Apple" /></a></li>
                <li><a href=""><img src={sales} alt="Microsoft" /></a></li>

              </ul>

              <ul class="clients">

                <li><a href=""><img src={del} alt="Microsoft" /></a></li>
              </ul>
            </div>
          </div>
        </article>
      </section>
      <Book/>
      <Footer />
    </>



  )

}

export default Ourclient
