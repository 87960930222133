import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/robo-glitch.png'
import car from './../../images/baby-robot-mask.png'
import Book from './book';
import fea2 from './../../images/robot-automation.jpg'

import Slider from "react-slick";
import './../solutions/robot-processing.css'
import './../solutions/automotive.css'

import cloud from './../../images/cloud-solution.jpg'

import cutting from './../../images/robo-glitch.png'



// apple ani end
function RobotProcessing() {



  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.in', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)
      .from('.image-box0 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box0",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)


      .from('.image-box1 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box1",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)

      .from('.image-box2 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box2",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)

      .from('.image-box3 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box3",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0);



  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });




  return (
    <>

<Helmet>
<title>Robotic Process Automation Solutions | RPA Technology | Procal</title>
<meta name="description" content="We deliver cutting-edge robotic solutions tailored to your needs. Unlock innovation and efficiency with our advanced robotics technology. Contact us Today!" />

<meta name="description" content="We excel in crafting and deploying automation testing solutions that enhance efficiency, productivity, and labor optimization. Book Free Consultation Now" />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Robotic Process Automation Solutions | RPA Technology | Procal" />
	<meta property="og:description" content="We deliver cutting-edge robotic solutions tailored to your needs. Unlock innovation and efficiency with our advanced robotics technology. Contact us Today!" />
	<meta property="og:url" content="https://www.procal.ai/solutions/robot-processing-automation/" />
	<meta property="og:site_name" content="Procal Technologies" />
</Helmet>
      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


              <div className='col-lg-7'>
                <div class="section-head__title flex-basis-666">

                  <h1 class="title-outline">
                    Robot
                  </h1>
                  <h2 class="title-outline">
                    Processing
                  </h2>

                  <h2 class="title-outline">
                    AUTOMATION
                  </h2>
                  <span class="title--outline green">
                    AUTOMATION
                  </span>
                  <span class="title--outline green">
                    AUTOMATION
                  </span>
                  <span class="title--outline green">
                    AUTOMATION
                  </span>
                  <span class="title--outline green">
                    AUTOMATION
                  </span>
                  <span class="title--outline green">
                    AUTOMATION
                  </span>
                  <span class="title--outline green">
                    AUTOMATION
                  </span>
                </div>
              </div>
              <div className='col-lg-5'>
                <div className='sidetext'>
                  <div class="image-box">
                    <img src={girl} className='img-fluid' />
                  </div>
                </div></div>


            </section>
          </div>





        </div>


      </section>

      <section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center1">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Robot Processing Automation</h2>
                <p>With the increasing demand for Robotic Process Automation (RPA) services, organizations face the challenge of effectively automating their business processes to improve efficiency and productivity. At Procal, we specialize in providing comprehensive RPA solutions tailored to streamline and optimize business operations for our clients. Our teams possess extensive knowledge and experience in all aspects of RPA, ensuring seamless automation, exceptional process efficiency, and maximum coverage across different business domains and industries. Traditionally, automating business processes involved manual coding and scripting, which limited scalability and efficiency. By leveraging our expertise in RPA, we guide our clients in implementing secure and reliable automation solutions with our low-code capabilities to build automation scripts that can be easily integrated with various enterprise applications and systems, ensuring accurate and comprehensive process automation.</p>

                <p>Our RPA services encompass various key areas to address the diverse needs of our clients. We provide expertise in automated process design and development, where we analyze and optimize business processes with our intelligent automation via AI/ML tools that can be trained to make judgments about future outputs. By employing advanced robots and frameworks, we automate repetitive and rule-based tasks, allowing our clients to allocate their resources towards more value-added activities reducing human error. This approach enables thorough process validation, reduce cost for enterprises, improves accuracy, reduces errors, and enhances overall process efficiency.</p>

                <p>Procal's RPA capabilities extend to cognitive automation, where we leverage artificial intelligence (AI) and machine learning (ML) technologies to enable intelligent decision-making and data processing within automated processes. By integrating cognitive capabilities, we empower our clients to automate complex tasks that involve unstructured data, natural language processing, and sentiment analysis. This enables organizations to extract valuable insights, automate data-driven decision-making, and enhance overall operational intelligence.</p>
                <p>Procal's comprehensive reporting and analysis capabilities provide our clients with actionable insights into process performance, automation metrics, and potential areas for improvement. Our detailed reports facilitate informed decision-making, highlight bottlenecks, and support continuous process optimization. We collaborate closely with our clients to fine-tune their automation solutions, ensuring optimal process efficiency and achieving their desired business outcomes.</p>



              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className='img-fluid' />
            </div>

            <div class="col-md-12 col-lg-12">

              <section class="section-byh u-bg-vpx">
                <div class="container-opd u-position-yg5">
                  <div class="row-ctw u-mb-4eh u-z-index-fyv">
                    <div class="col-zh4 col-48i"></div>
                    <div class="col-zh4 col-vkd">
                      <h2 class="style-dYtmF" id="style-dYtmF">
                        <div class="sidefont">Robotic Process </div>
                        <div className='sidefont'> Automation </div>
                        <div class="sidefont">(RPA) services</div>
                      </h2>
                    </div>
                  </div>
                  <div class="row-ctw u-mb-4eh u-z-index-fyv">
                    <div class="col-zh4 col-48i"></div>
                    <div class="col-zh4 col-zzz">
                      <h3 class="sidefont des-fpq text-axz2">Expertise in automated <br/>process design and development </h3>
                    </div>
                  </div>
                  <div class="row-kac">
                    <div class="col-zh4 col-lyc"></div>
                    <div class="col-zh4 col-48i"></div>
                    <div class="col-zh4 col-lyc">
                      <div class="u-img-559"><img src={car} class="u-img-6k2" ref={imageRef} /></div>
                    </div>
                    <div class="col-zh4 col-lyc"></div>
                  </div>
                  <div class="row-ctw u-mb-4eh u-z-index-fyv">
                    <div class="col-zh4 col-wel">   <p class="style-4qQnC" id="style-4qQnC"></p>
                      <div class="sidefont 1text-axz">Automation via </div>
                      <div class="tcilv">AI/ML tools</div>
                      <p></p></div>
                    <div class="col-zh4 col-vkd">
                      <div class="col-zh4 col-lyc"></div>
                      <div class="text-zog1 tcilv">Reduced human error</div>

                      <p></p>
                    </div>
                  </div>
                  <div class="row-ctw u-z-index-fyv">
                    <div class="col-zh4 col-48i"></div>
                    <div class="col-zh4 col-vkd">

                    </div>
                  </div>
                </div>
              </section>
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >

                <p>With the increasing demand for Robotic Process Automation (RPA) services, organizations face the challenge of effectively automating their business processes to improve efficiency and productivity. At Procal, we specialize in providing comprehensive RPA solutions tailored to streamline and optimize business operations for our clients. Our teams possess extensive knowledge and experience in all aspects of RPA, ensuring seamless automation, exceptional process efficiency, and maximum coverage across different business domains and industries. Traditionally, automating business processes involved manual coding and scripting, which limit scalability and efficiency. By leveraging our expertise in RPA, we guide our clients in implementing secure and reliable automation solutions with our low-code capabilities to build automation scripts that can be easily integrated with various enterprise applications and systems, ensuring accurate and comprehensive process automation.  </p>
                <p>Our RPA services encompass various key areas to address the diverse needs of our clients. We provide expertise in automated process design and development, where we analyze and optimize business processes with our intelligent automation via AI/ML tools that can be trained to make judgements  about future outputs. By employing advanced robots and frameworks, we automate repetitive and rule-based tasks, allowing our clients to allocate their resources towards more value-added activities reducing human error. This approach enables thorough process validation, reduce cost for enterprises, improves accuracy, reduces errors, and enhances overall process efficiency.</p>

                <p>Procal's RPA capabilities extend to cognitive automation, where we leverage artificial intelligence (AI) and machine learning (ML) technologies to enable intelligent decision-making and data processing within automated processes. By integrating cognitive capabilities, we empower our clients to automate complex tasks that involve unstructured data, natural language processing, and sentiment analysis. This enables organizations to extract valuable insights, automate data-driven decision-making, and enhance overall operational intelligence.</p>




              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-rom container-al ali-fxl">
        <div className="container-d1s">
          <div className="container-rom container-2e2">
            <div className="container-d1s">
              <div className="wrapper-ti2">
                <div className="column-o8o column-fhi">
                  <section className="container-rom container-coh ani-lcm fa-6jh">
                    <div className="container-d1s" style={{ backgroundColor: '#898989', padding: '28px' }}>
                      <h2 className="gb-headline-6nz">AI and machine learning (ML)</h2>
                      <p className="col-oe2" style={{ fontSize: 16 }}>
                      AI and machine learning (ML) are revolutionizing robotic process automation (RPA) by enabling smarter, more adaptable automation solutions. With AI and ML, RPA can not only handle repetitive tasks but also learn from data patterns and make decisions in real time. Procal Technologies specializes in integrating these advanced technologies into RPA, helping businesses improve efficiency, reduce errors, and enhance decision-making processes. By leveraging Procal's expertise in AI and ML, organizations can unlock new levels of productivity and agility, driving innovation and growth across their operations.
                      </p>


                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">


            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


          </div>


        </div>

        {/* slicky slider */}
        <div className="slider-container">

          <Slider {...settings2}>



            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Automated Process Design and Development</h4>

                  <p>Our teams leverage advanced RPA tools and frameworks to meticulously analyze, optimize, and automate complex business processes across various industries and our bots are easy to setup and simple to use. By automating repetitive and rule-based tasks, we drive significant improvements in process efficiency, accuracy, and error reduction. Our expertise in automated process design and development empowers our clients to achieve faster and more reliable process execution, resulting in streamlined operations and the ability to allocate valuable resources to strategic initiatives. With a keen focus on optimizing end-to-end workflows, we ensure that our clients maximize productivity, reduce cost, enhance customer experiences, and gain a competitive edge in their respective markets.</p>

                </div>


              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Cognitive Automation</h4>

                  <p>At Procal, we go beyond traditional Robotic Process Automation (RPA) by integrating cutting-edge Artificial Intelligence (AI) and Machine Learning (ML) technologies into our solutions. Through cognitive automation, we enable intelligent decision-making and data processing within automated processes. By harnessing the power of AI and ML, our clients can automate complex tasks that involve unstructured data, natural language processing, and sentiment analysis. This empowers organizations to extract valuable insights from vast amounts of data, automate data-driven decision-making processes, and gain actionable intelligence to drive strategic initiatives. With cognitive automation, our clients experience enhanced operational efficiency, improved customer experiences, and the ability to stay ahead of the competition in today's data-driven landscape.</p>

                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Security and Compliance</h4>

                  <p>At Procal, security is a paramount concern in every step of our RPA implementation process. Our dedicated experts take comprehensive measures to safeguard sensitive data, utilizing industry-standard encryption, robust access controls, and advanced data protection mechanisms. We adhere to stringent best practices and regulatory requirements to ensure data integrity, confidentiality, and overall compliance. By proactively addressing security risks and potential vulnerabilities, we help our clients establish a secure RPA environment that instills trust and protects valuable information. Our commitment to security is unwavering as we continuously monitor and update our security protocols to stay ahead of emerging threats and provide our clients with peace of mind.</p>

                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cutting} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Comprehensive Reporting and Analysis</h4>

                  <p>Procal's comprehensive reporting and analysis capabilities empower our clients with detailed and actionable insights into process performance, automation metrics, and potential areas for improvement. Our robust reporting tools generate comprehensive dashboards and visualizations, presenting key performance indicators and trends. These insights enable informed decision-making, allowing organizations to identify bottlenecks, optimize resource allocation, and drive continuous process improvement. By closely collaborating with our clients, we provide valuable recommendations and strategic guidance to refine their automation solutions, ensuring optimal process efficiency and alignment with their business objectives. With Procal's reporting and analysis capabilities, organizations can unlock the full potential of RPA, maximize their return on investment, and stay ahead in today's dynamic business landscape.</p>

                </div>
              </div>
            </div>

          </Slider>
        </div>

        {/* slicky slider end */}



      </section>


      {/* whats new section end */}


      <Book/>

      <Footer />
    </>
  )
}

export default RobotProcessing
