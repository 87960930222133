import React from 'react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import Menu from './../../common/Menu'
import Footer from './../../common/Footer';
import './../whatwe/whatwe.css'
import dot from './../../images/dot-world.svg'
import { Helmet } from 'react-helmet';

function Whatwe() {
    useEffect(() => {
        // Only run animations if the screen width is >= 768px
       
          let ctx = gsap.context(() => {
            gsap.registerPlugin(ScrollTrigger);
    
            // Apple animation
            gsap.fromTo('.displacement', { x: 0 }, {
              r: 300,
              repeat: -1,
              duration: 6,
              ease: 'power3.inOut',
              yoyo: true
            });
    
            // Timeline animations
            const targets = document.querySelectorAll('.list li');
            gsap.set('.list', { autoAlpha: 1 });
    
            const timeline = gsap.timeline({
              defaults: { overwrite: 'auto', ease: 'power1' },
              scrollTrigger: {
                end: '+=300%',
                pin: true,
                pinSpacing: true,
                scrub: true,
                start: 'top top',
                trigger: '.list',
              },
            });
    
            const stagger = 0.8;
            const duration = 1;
    
            timeline.set(targets, { transformOrigin: 'center bottom' })
              .from(targets, {
                duration: duration,
                opacity: 0,
                rotationX: 0,
                rotationY: 0,
                scale: 1,
                stagger: stagger,
                yPercent: 90,
              })
              .to(targets, {
                duration: duration,
                opacity: 0,
                rotationX: 20,
                rotationY: 2,
                scale: 0.75,
                stagger: stagger,
                y: -100,
              }, stagger);
          });
    
          return () => ctx.revert();
   
      }, []); // Empty dependency array to run once
    

    return (
        <>
            <Helmet>
                <title>What We Do | Capabilities, Industries, and Partners | Procal</title>
                <meta name="description" content="Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services." />
            </Helmet>
            <Menu />


            <section className='whatwe'>
                <div className='container py-5'>

                    <div className='row mt-5'>
                        {/* <h1 className="tw-text-8xl tw-font-bold tw-line-through">
      Hello world!
    </h1> */}
                        <div className='col-12 col-lg-12 col-sm-12 what hero-head '><h2 className='1ctrl-break'>What We Do</h2></div>
                        <div id='whatwe_text'>Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services. </div>
                    </div>

                </div>
            </section>

            <section className='telecom'>

                <div className='container py-5'>

                    <div className='row'>
                        <div className='col-12 col-lg-6 col-sm-5 lightlist'>
                            <h2 class="ctrl-break headingfont ">Telecommunications</h2>

                            <ul className='mt-5 '>
                                <p><b>Procal offers a comprehensive range of tailored services and solutions designed to support:</b></p>
                                <li className='mt-3'>
                                    <ul className='boldlist'>
                                        <li>Telecommunication service providers</li>
                                        <li>Network operators</li>
                                        <li>Related organizations</li>
                                    </ul>
                                </li>
                                <p className='mt-5 '><b>Our Expertise spans over:</b></p>
                                <li className='mt-3'>
                                    <ul className='boldlist'>
                                        <li>Network design, deployment, and optimization</li>
                                        <li>Infrastructure management</li>
                                        <li>Software development</li>
                                        <li>Cybersecurity</li>
                                        <li>Technical support</li>
                                    </ul>
                                </li>
                                <p className='mt-5 '><b>With Procal's assistance, clients can:</b></p>
                                <li className='mt-3'>
                                    <ul className='boldlist'>
                                        <li>Enhance network performance</li>
                                        <li>Expand coverage</li>
                                        <li>Improve service quality</li>
                                        <li>Implement cutting-edge technologies</li>
                                        <li>Ensure seamless connectivity for their customers</li>
                                    </ul>
                                </li>
                                <p className='mt-5 '> <b>We also provide:</b></p>
                                <li className='mt-3'>
                                    <ul className='boldlist'>
                                        <li>Strategic consulting</li>
                                        <li>Regulatory compliance guidance</li>
                                        <li>Innovative solutions</li>
                                    </ul>
                                </li>
                                <p className='mt-5 '><b>Procal is dedicated to:</b></p>
                                <li className='mt-3'>
                                    <ul className='boldlist'>
                                        <li>Enabling reliable and efficient communication services</li>
                                        <li>Fostering innovation</li>
                                        <li>Driving the growth and advancement of the telecommunications sector</li>
                                    </ul>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>


            </section>

            <section className='govt'>

                <div className='container py-5'>

                    <div className='row'>
                        <div className='col-12 col-lg-6 col-sm-5'>
                            <h2 class="ctrl-break headingfont">Government</h2>
                            <ul className='boldlist mt-5'>
                                <li>Procal collaborates with government agencies at all levels to drive digital transformation, improve operational efficiency, and enhance citizen services.</li>
                                <li>Our technology consulting and solutions empower governments to streamline processes, implement robust cybersecurity measures, leverage data analytics for evidence-based decision-making, and develop citizen-centric platforms.</li>
                                <li>From digital government services to smart city initiatives, Procal's expertise helps government entities navigate complex challenges, ensure data privacy and security, and deliver effective and transparent services to their constituents.</li>
                            </ul>

                        </div>
                    </div>
                </div>


            </section>

            <section className='health'>

                <div className='container py-5'>

                    <div className='row'>
                        <div className='col-12 col-lg-6 col-sm-5'>
                            <h2 class="ctrl-break headingfont">Healthcare and Life Sciences</h2>



                            <ul className='boldlist mt-5'>
                                <li>Procal is at the forefront of exceptional services in the healthcare and life sciences sector.</li>
                                <li>Our company offers a comprehensive suite of specialized solutions designed to meet the unique needs of healthcare providers, pharmaceutical companies, research institutions, and other industry players.</li>
                                <li>We excel in delivering innovative technology solutions from healthcare analytics to telehealth platforms.</li>
                                <li>With Procal's expertise, clients can enhance patient care, optimize clinical processes, accelerate research and development, and navigate the complex landscape of the healthcare industry.</li>
                                <li>We are committed to driving advancements in healthcare, empowering organizations to achieve their strategic objectives, improve operational efficiency, and deliver exceptional outcomes to patients and stakeholders.</li>
                            </ul>

                        </div>
                    </div>
                </div>


            </section>

            <section className='manufacturing'>

                <div className='container py-5'>

                    <div className='row'>
                        <div className='col-lg-12'>

                            <svg width="400" height="400" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <filter id="displacementFilter">
                                        <feTurbulence type="fractalNoise" baseFrequency="0.03" numOctaves="3" result="noise" />
                                        <feDisplacementMap in="SourceGraphic" in2="noise" scale="50" xChannelSelector="R" yChannelSelector="G" />
                                    </filter>
                                    <mask id="circleMask">
                                        <circle cx="200" cy="200" r="0" fill="yellow" className="displacement" />
                                    </mask>
                                </defs>
                                <image
                                    xlinkHref={dot}
                                    width="400"
                                    height="400"
                                    mask="url(#circleMask)"
                                />
                            </svg>


                        </div>
                        <div className='col-12 col-lg-6 col-sm-5'>
                            <h2 class="ctrl-break headingfont">Manufacturing and Supply Chain</h2>

                            <ul className='boldlist mt-5'>
                                <li>Procal is a trusted provider of comprehensive services tailored to meet the specific needs of the manufacturing and supply chain sector.</li>
                                <li>Our expertise and solutions are designed to optimize operations, enhance efficiency, and drive supply chain excellence.</li>
                                <li>We work closely with manufacturers and supply chain organizations to offer a wide range of services, including process automation, supply chain visibility, inventory management, demand forecasting, and supplier collaboration tools.</li>
                                <li>Through our innovative approaches and industry insights, Procal helps clients streamline processes, reduce costs, improve productivity, and ensure seamless integration across their manufacturing and supply chain operations.</li>
                                <li>With Procal's partnership, organizations gain a competitive edge, adapt to evolving market demands, and achieve sustainable growth in the dynamic manufacturing and supply chain landscape.</li>
                            </ul>


                        </div>
                    </div>
                </div>


            </section>

            <section className='retail'>

                <div className='container py-5'>

                    <div className='row'>
                        <div className='col-12 col-lg-6 col-sm-5'>
                            <h2 class="ctrl-break headingfont">Retail and Consumer Goods</h2>

                            <ul className='boldlist mt-5'>
                                <li>Procal is a leading provider of specialized solutions for companies in the retail and consumer goods sector, catering to their distinct requirements and industry-specific demands.</li>
                                <li>Our expertise spans digital transformation, operational optimization, and customer experience enhancement.</li>
                                <li>We collaborate closely with retail and consumer goods organizations to deliver solutions such as e-commerce platform development, inventory management systems, and customer analytics.</li>
                                <li>Procal helps clients transform their digital presence, streamline operations, and deliver personalized customer experiences.</li>
                                <li>By leveraging our innovative solutions, retailers and consumer goods companies can drive growth, improve operational efficiency, and stay ahead in the competitive marketplace.</li>
                            </ul>

                        </div>


                    </div>
                </div>


            </section>

            <section className='metro'>
                <div className='container py-5 '>
                    <div className='row'>
                        <div className='col-12 col-lg-6 col-sm-5 '>
                            <h2 class="ctrl-break headingfont">Our Methodologies</h2>


                            <ul className='boldlist mt-5'>
                                <li>At Procal, we understand the importance of flexibility when it comes to engaging with our clients.</li>
                                <li>Recognizing that each organization has unique needs and goals, we take a tailored approach in delivering solutions that are specifically designed to address their challenges.</li>
                                <li>We offer a variety of impactful engagement models, ensuring that our clients receive the right level of support and services that align with their requirements.</li>
                                <li>Whether it's through full-scale product development, impactful resource augmentation, or deploying our own proprietary products, our goal is to provide the most effective and customized approach that drives their success.</li>
                            </ul>

                        </div>


                    </div>


                </div>
            </section>



            <section className='endto'>



                <div className="list d-lg-block d-none" style={{ position: 'relative', top: '20px' }}>
                    <ul style={{ listStyle: 'none', padding: 0 }}>

                        <li style={{ marginBottom: '10px' }}>
                            <h2 className='scroll-font'>End to End Product Development</h2>
                            <p>Procal offers end to end comprehensive product development, leveraging our own internal resources and processes. After aligning with the client on scope and deliverables, we independently manage projects/products to deliver quality results based on customer needs.</p>
                        </li>
                        <li style={{ marginBottom: '20px' }}>
                            <h2 className='scroll-font'>Proprietary Products</h2>
                            <p>Procal offers a suite of cutting-edge proprietary products, exclusively licensed to clients, enabling them to unlock powerful solutions. Our innovative suite includes Orion, a network/device testing and optimization tool, and Snap, a remote mobile testing tool that aids in automated device testing needs. Our built in API’s seamlessly integrate with popular tools like Appium.</p>
                        </li>
                        <li style={{ marginBottom: '20px' }}>
                            <h2 className='scroll-font'>Resource Augmentation</h2>
                            <p>Procal is a leading provider of specialized staff augmentation services. With a proven track record of delivering highly niche skilled professionals to augment existing teams, we leverage our network of professionals to drive innovation and success.</p>
                        </li>

                        {/* Add more list items as needed */}
                    </ul>
                </div>


                <div className='d-block d-sm-none'>

                <div className="listM" style={{ position: 'relative', top: '20px' }}>
                    <ul style={{ listStyle: 'none', padding: 0 }}>

                        <li style={{ marginBottom: '10px' }}>
                            <h2 className='scroll-font'>End to End Product Development</h2>
                            <p>Procal offers end to end comprehensive product development, leveraging our own internal resources and processes. After aligning with the client on scope and deliverables, we independently manage projects/products to deliver quality results based on customer needs.</p>
                        </li>
                        <li style={{ marginBottom: '20px' }}>
                            <h2 className='scroll-font'>Proprietary Products</h2>
                            <p>Procal offers a suite of cutting-edge proprietary products, exclusively licensed to clients, enabling them to unlock powerful solutions. Our innovative suite includes Orion, a network/device testing and optimization tool, and Snap, a remote mobile testing tool that aids in automated device testing needs. Our built in API’s seamlessly integrate with popular tools like Appium.</p>
                        </li>
                        <li style={{ marginBottom: '20px' }}>
                            <h2 className='scroll-font'>Resource Augmentation</h2>
                            <p>Procal is a leading provider of specialized staff augmentation services. With a proven track record of delivering highly niche skilled professionals to augment existing teams, we leverage our network of professionals to drive innovation and success.</p>
                        </li>

                        {/* Add more list items as needed */}
                    </ul>
                </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default Whatwe
