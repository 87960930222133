import React from 'react'
import { useEffect, useRef, StrictMode, useState } from "react";
import { from, gsap } from "gsap";
import { useGSAP } from '@gsap/react'
//import { ScrollSmoother} from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import secone from './../images/everest.jpg';
import sectwo from './../images/comitment.jpg';
import secthree from './../images/vision.jpg';
import Menu from '../common/Menu'
import Footer from '../common/Footer';
import { _horizontal } from 'gsap/Observer';
import './../pages/whowe.css'

import { Helmet } from 'react-helmet';
gsap.registerPlugin(ScrollTrigger);
function Whowe() {

  // counter animation

  const CountUpAnimation = ({
    iconComponent,
    initialValue,
    targetValue,
    text,
  }) => {
    const [count, setCount] = useState(initialValue);
    const duration = 4000; // 4 seconds

    useEffect(() => {
      let startValue = initialValue;
      const interval = Math.floor(
        duration / (targetValue - initialValue));

      const counter = setInterval(() => {
        startValue += 1;
        setCount(startValue);
        if (startValue >= targetValue) {
          clearInterval(counter);
        }
      }, interval);

      return () => {
        clearInterval(counter);
      };
    }, [targetValue, initialValue]);

    return (
      <div className="container">
        <div className="icon">{iconComponent}</div>
        <span className="num">{count}</span>
        <span className="text">{text}</span>
      </div>
    );
  };

  // counter animation tend

  const horizontalSectionRef = useRef(null);


  useGSAP(() => {
    const horizontalSection = horizontalSectionRef.current;

    gsap.set(".photo:not(:first-child)", { opacity: 0, scale: 0.5 })

    const animation = gsap.to(".photo:not(:first-child)", {
      opacity: 1, scale: 1, duration: 1, stagger: 1
    })

    ScrollTrigger.create({
      trigger: ".gallery",
      start: "top top",
      end: "bottom bottom",
      pin: ".rightblock",
      animation: animation,
      scrub: true,
      onUpdate: (x) => {
        console.log(x.progress);
      }
    })


    // _horizontal scroll start code


    // Check if horizontalSection is available
    if (horizontalSection) {
      console.log(horizontalSection.scrollWidth);

      // GSAP animation
      gsap.to('.horizontal', {
        x: () => horizontalSection.scrollWidth * -1,
        xPercent: 100,
        scrollTrigger: {
          trigger: '.horizontal',
          start: 'center center',
          end: '+=2000px',
          pin: '.horizontal-scoll-wrapper',
          scrub: true,
          invalidateOnRefresh: true
        }
      });
    }
    // _horizontal scroll start end
  })

  return (
    <>

      <Helmet>
        <title>Procal Technolgies | Mission, Vision & Commitments</title>
        <meta name="description" content="At Procal, we strive to be catalysts for technological advancement, revolutionizing how businesses harness innovative solutions." />
      </Helmet>
      <Menu />

      <section class="intro mt-5 ">
        <div className="who">
          <div className='row'>
            <div className='col-12 col=lg-12 col-sm-12'>
              <h2>Who we are</h2>
              <h3 className='purpletext'>PROCAL BY THE NUMBERS</h3>

            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col=lg-12 col-sm-12 center-align'>




            <div className='intro-item'>
              <CountUpAnimation

                initialValue={0}
                targetValue={250}
                text="+"
              /><br />
              Certified Experts</div>


            <div className='intro-item'>
              <CountUpAnimation

                initialValue={0}
                targetValue={100}
                text="+"
              /><br />
              Fortune 500 Clients</div>
            <div className='intro-item'>  <CountUpAnimation

              initialValue={0}
              targetValue={15}
              text="+"
            /><br />
              Labs and Offices Worldwide</div>
          </div>
        </div>
      </section>
      <div className='container-fluid d-none d-lg-block d-xl-block'>
      <div className="gallery ">

        <div className="rightblock d-sm-none d-lg-block d-xl-block ">
          <div class="photos">
            <div class="photo red"><img src={sectwo} alt="img-1" className='img-fluid' /></div>
            <div class="photo green"><img src={secone} className='img-fluid' alt="img-2" /></div>
            <div class="photo blue">
              <img src={secthree} className='img-fluid' alt="img-3" /></div>

          </div>
        </div>

        <div className="left d-sm-none d-lg-block d-xl-block">
          <div className="details">
            Our Mission
            <p>At Procal, we strive to be catalysts for technological advancement, revolutionizing how businesses harness innovative solutions. We aim to shape the future of technology by providing scalable and impactful offerings, helping our clients embrace digital transformation, achieve operational excellence, and exceed customer expectations. Through our expertise, agility, and relentless pursuit of innovation, we aspire to be the preferred partner in driving our client's success and shaping the industry landscape.</p>
          </div>
          <div className="details">
            Our Vision
            <p>Our vision is to lead the digital transformation journey by providing innovative, scalable solutions that empower businesses to thrive in an ever-evolving technological landscape. We aim to be the trusted partner in driving operational excellence, customer success, and industry innovation.</p>
            <p></p>
          </div>
          <div className="details">
            Our Commitments
            <p>Procal thrives on its commitment to people-centric values, shaping a workplace where diversity is celebrated. Our team's varied talents, experiences, and characteristics enable us to deliver holistic and collaborative solutions. We champion cultural inclusion, fairness, and ethical conduct in all interactions, fostering a sustainable and successful organization. Our dedication to work-life balance and merit-based promotion creates a universally comfortable environment that values and celebrates the diversity of our team.</p>
          </div>

        </div>
      </div>
</div>
{/* mobile code */}
      <section>
        <div className='container d-lg-none d-block'>

        <div className="row d-lg-none">

    <div className="col-12 order-1">
    <img src={sectwo} alt="img-1" className='img-fluid' />
    </div>

    <div className="col-12 order-2 mt-3">
      <h2 className="text-center">   Our Mission</h2>
      <p className='text-center'>At Procal, we strive to be catalysts for technological advancement, revolutionizing how businesses harness innovative solutions. We aim to shape the future of technology by providing scalable and impactful offerings, helping our clients embrace digital transformation, achieve operational excellence, and exceed customer expectations. Through our expertise, agility, and relentless pursuit of innovation, we aspire to be the preferred partner in driving our client's success and shaping the industry landscape.</p>
    </div>




    <div className="col-12 order-2">
    <img src={secthree} className='img-fluid' alt="img-3" />
    </div>

    <div className="col-12 order-3 mt-3">
      <h2 className="text-center">   Our Vision</h2>
      <p className='text-center'>Our vision is to lead the digital transformation journey by providing innovative, scalable solutions that empower businesses to thrive in an ever-evolving technological landscape. We aim to be the trusted partner in driving operational excellence, customer success, and industry innovation.</p>
    </div>



    <div className="col-12 order-4">
    <img src={secone} className='img-fluid' alt="img-2" />
    </div>

    <div className="col-12 order-5 mt-3">
      <h2 className="text-center">  Our Commitments</h2>
      <p className='text-center'>Procal thrives on its commitment to people-centric values, shaping a workplace where diversity is celebrated. Our team's varied talents, experiences, and characteristics enable us to deliver holistic and collaborative solutions. We champion cultural inclusion, fairness, and ethical conduct in all interactions, fostering a sustainable and successful organization. Our dedication to work-life balance and merit-based promotion creates a universally comfortable environment that values and celebrates the diversity of our team.</p>
    </div>





  </div>
  

      </div>
      </section>




      {/* <section class="whatwedo">
  <h1>WHAT WE DO</h1>
</section> */}

      {/* <section id="horizontal-scoll">
  <div class="horizontal-scoll-wrapper">
    <div class="horizontal" ref={horizontalSectionRef}>
      <div>
        <div class="card">
          <h2>Card 1</h2>
        </div>
      </div>
      <div>
        <div class="card">
          <h2>Our Industries</h2>
          <p>Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services. We enable growth and efficiency by providing innovative technology solutions, operational optimization, and exceptional customer experiences. With expertise spanning multiple sectors, Procal drives advancements, fosters innovation, and ensures seamless integration for its clients in dynamic and competitive landscapes.</p>
        </div>
      </div>
      <div>
        <div class="card">
          <h2>Telecommunications</h2>
          <p>Procal offers a comprehensive range of tailored services and solutions designed to support telecommunication service providers, network operators, and related organizations. Our expertise spans network design, deployment, and optimization, infrastructure management, software development, cybersecurity, and technical support. With Procal's assistance, clients can enhance network performance, expand coverage, improve service quality, implement cutting-edge technologies, and ensure seamless connectivity for their customers. We also provide strategic consulting, regulatory compliance guidance, and innovative solutions to help telecommunication companies navigate the dynamic industry landscape. Procal is dedicated to enabling reliable and efficient communication services, fostering innovation, and driving the growth and advancement of the telecommunications sector.</p>
        </div>
      </div>
      <div>
        <div class="card">
          <h2>Government</h2>
          <p>Procal collaborates with government agencies at all levels to drive digital transformation, improve operational efficiency, and enhance citizen services. Our technology consulting and solutions empower governments to streamline processes, implement robust cybersecurity measures, leverage data analytics for evidence-based decision-making, and develop citizen-centric platforms. From digital government services to smart city initiatives, Procal's expertise helps government entities navigate complex challenges, ensure data privacy and security, and deliver effective and transparent services to their constituents.</p>
        </div>
      </div>
      <div>
        <div class="card">
          <h2>Healthcare and Life Sciences</h2>
          <p>Procal is at the forefront of exceptional services in the healthcare and life sciences sector. Our company offers a comprehensive suite of specialized solutions designed to meet the unique needs of healthcare providers, pharmaceutical companies, research institutions, and other industry players. We excel in delivering innovative technology solutions from healthcare analytics to telehealth platforms. With Procal's expertise, clients can enhance patient care, optimize clinical processes, accelerate research and development, and navigate the complex landscape of the healthcare industry. We are committed to driving advancements in healthcare, empowering organizations to achieve their strategic objectives, improve operational efficiency, and deliver exceptional outcomes to patients and stakeholders.</p>
        </div>
      </div>
      <div>
        <div class="card">
          <h2>Manufacturing and Supply Chain</h2>
          <p>Procal is a trusted provider of comprehensive services tailored to meet the specific needs of the manufacturing and supply chain sector. Our expertise and solutions are designed to optimize operations, enhance efficiency, and drive supply chain excellence. We work closely with manufacturers and supply chain organizations to offer a wide range of services, including process automation, supply chain visibility, inventory management, demand forecasting, and supplier collaboration tools. Through our innovative approaches and industry insights, Procal helps clients streamline processes, reduce costs, improve productivity, and ensure seamless integration across their manufacturing and supply chain operations. With Procal's partnership, organizations gain a competitive edge, adapt to evolving market demands, and achieve sustainable growth in the dynamic manufacturing and supply chain landscape.</p>
        </div>
      </div>
    </div>
  </div>
</section> */}




      <Footer />
    </>
  )
}

export default Whowe
