import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Book from './book';
import Footer from './../../common/Footer'
import girl from './../../images/endpoint-top.png'

import fea2 from './../../images/endpoint-left.jpg'

import abst333 from './../../images/abst-333.jpg'

import Slider from "react-slick";
import './../solutions/managed-security.css'
import './../solutions/vulnerability.css'


import cloud from './../../images/threat-slide.jpg'

import cutting from './../../images/endpoint-slide.jpg'


// apple ani end
function EndPoint() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)

.from('.image-box-center img', {
    yPercent: 15,
    ease: "none",
    scrollTrigger: {
    trigger: ".outline-box-center",
    start: "top center",
    scrub: 1,
    
    },
    ease: 'power.inOut',
    }, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>


<Helmet>
        <title>AI and machine learning  | Procal</title>
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="End Point Security" />
	<meta property="og:description" content="In today's interconnected world, where remote work and mobile devices are integral to business operations, safeguarding the endpoints of your network is of paramount importance. Endpoint security, at its core, is the practice of protecting the devices (endpoints) that access your network, such as computers, smartphones, and tablets, from a wide range of security threats and vulnerabilities. These threats can range from malware and ransomware attacks to unauthorized access and data breaches. In a world where data privacy and confidentiality are paramount, it's essential that your endpoints are equipped to defend against cyber threats, maintain compliance with industry regulations, and ensure the integrity and reliability of your network." />
	<meta property="og:url" content="https://www.procal.ai/end-point-security/" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
End Point
</h1>

<h2 class="title-outline">
Security
</h2>
<span class="title--outline green">
Security
</span>
<span class="title--outline green">
Security
</span>
<span class="title--outline green">
Security
</span>
<span class="title--outline green">
Security
</span>
<span class="title--outline green">
Security
</span>
<span class="title--outline green">
Security
</span>
</div>
</div>
<div className='col-lg-5'>
<div className='sidetext'>
<div class="image-box">
<img src={girl} className='img-fluid' alt='end point security' />     
</div>
</div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
<div class="container">

<div class="row d-flex align-items-center1">


<div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
<img
ref={imageRef}
src={cloud}
alt="Your Image"
className="img-fluid"
/>
</div>

<div class="col-md-8 col-lg-8">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">End Point Security</h2>
<p>In today's interconnected world, where remote work and mobile devices are integral to business operations, safeguarding the endpoints of your network is of paramount importance. Endpoint security, at its core, is the practice of protecting the devices (endpoints) that access your network, such as computers, smartphones, and tablets, from a wide range of security threats and vulnerabilities. These threats can range from malware and ransomware attacks to unauthorized access and data breaches. In a world where data privacy and confidentiality are paramount, it's essential that your endpoints are equipped to defend against cyber threats, maintain compliance with industry regulations, and ensure the integrity and reliability of your network.</p>

<p>At Procal, we recognize the multifaceted nature of endpoint security and its vital role in the overall success and integrity of your IT infrastructure. We are committed to delivering innovative endpoint security solutions that empower organizations to fortify their endpoints and protect their digital assets.</p>

<p>Our mission is to provide a comprehensive suite of tools, technologies, and expert services that not only identify and address security vulnerabilities but also cultivate a culture of security within your organization. By partnering with Procal, you gain the confidence and assurance that your endpoints are fortified against ever-evolving security threats, thereby safeguarding your data, productivity, and reputation.</p>




</div>
</div>

<div class="col-md-4 col-lg-4 image-box">
<img src={fea2} className='img-fluid' alt='end point security'  />
</div>

<div class="col-md-12 col-lg-12">
</div>
</div>
</div>
</section>

{/* center section */}
<div class="container-rze u-position-21c">
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <h2 class="style-GAVo4" id="style-GAVo4">
                <div class="1text-vn6 sidefont" >Endpoint security </div>
                <div class="1snipcss0-4-5-7 sidefont" >protects the devices</div>
                <div class="1text-ggq sidefont"> in today's</div>
                <div class="1text-ggq sidefont"> interconnected world</div>
            </h2>
        </div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-z42">
            <h6 class="des-5xpt-ggq" >Our mission is to provide a comprehensive suite of tools</h6>
        </div>
    </div>
    <div class="row-8oo">
        <div class="col-wjg col-b63"></div>
        <div class="col-wjg col-b63">
            <div class="u-img-a9d image-box-center"><img src={abst333} class="u-img-hcf" /></div>
        </div>
        <div class="col-wjg col-b63"></div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-s8h" ></div>
        <div class="col-wjg col-e7o">
            <p class="style-CgO1k" id="style-CgO1k"></p>
            <div class="text-vn6 ygqvp" > </div>
            <div class="text-vn6 ygqvp" >Endpoints security  </div>
            <div class="text-vn6 ygqvp" >defend against </div>
            <div class="text-vn6 ygqvp" > cyber threats,  </div>
            <div class="text-vn6 ygqvp" > maintain compliance and  </div>
            <div class="text-vn6 ygqvp" >ensure network reliability  </div>
            <p></p>
        </div>
    </div>
    <div class="row-jsz u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <p class="style-RWfkB" id="style-RWfkB"></p>
            <div class="ygqvp text-ggq" >from start</div>
            <div class="ygqvp" >to finish</div>
            <p></p>
        </div>
    </div>
</div>
{/* center section end */}
<section className="container-rom container-vul ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fhi">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
<h2 className="gb-headline-6nz">Protect sensitive data, maintain compliance</h2>
<p className="col-oe2" style={{ fontSize: 16 }}>
With Procal's Endpoint Security solutions, your organization can mitigate risks, protect sensitive data, maintain compliance, and ensure the security and reliability of your network endpoints. Our holistic approach to endpoint security empowers you to proactively defend against evolving threats and maintain the highest standards of security and trust in your IT infrastructure.
</p>



</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Endpoint Protection and Antivirus</h4>

<p>We offer state-of-the-art antivirus and endpoint protection solutions that shield your devices from malware, ransomware, and other threats. Our proactive threat detection and removal capabilities ensure that your endpoints stay secure.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Device Management and Control</h4>

<p>Procal helps you manage and control the endpoints within your network, ensuring compliance with security policies. We provide tools and expertise to track and monitor device activity, enforce access controls, and maintain a secure environment.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Security Patch Management</h4>

<p>Our team assists in keeping your endpoints up-to-date by applying security patches and updates to operating systems and software. This proactive approach helps mitigate vulnerabilities that can be exploited by attackers.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Endpoint Detection and Response (EDR)</h4>

<p>Utilizing EDR tools for real-time monitoring and rapid response to endpoint security incidents.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Firewall Configuration</h4>

<p>We assist organizations in configuring and managing firewalls to control and monitor incoming and outgoing network traffic.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Web Security Controls</h4>

<p>We implement controls to secure web browsing on endpoints, including URL filtering and content inspection.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Data Encryption and Data Loss Prevention</h4>

<p>We implement encryption solutions to protect sensitive data on endpoints and prevent unauthorized access or data leakage. Our strategies minimize the risk of data breaches and maintain regulatory compliance.</p>


</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Incident Response and Forensics</h4>

<p>In the event of a security incident, Procal offers incident response services to mitigate damage and conduct digital forensics to identify the source of the breach. Our experts help you recover swiftly and enhance your security posture.</p>


</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="End Point Security" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>User Training and Awareness</h4>

<p>We provide training and awareness programs to educate your employees on best practices for endpoint security. By raising awareness and fostering a security-conscious culture, your team becomes a proactive line of defense.</p>


</div>
</div>
</div>


</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}



<Book/>
<Footer />
</>
)
}

export default EndPoint
