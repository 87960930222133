import React from "react";
import { Link } from "react-router-dom";
import Menu from "./../../common/Menu";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import "./../../pages/solutions/modal.css";
import Footer from "./../../common/Footer";
import girl from "./../../images/big-data-left.jpg";
import "./../solutions/careers.css";
import "./../solutions/bigdata.css";
import Book from './book';
import { Helmet } from "react-helmet";
import axios from "axios";
// apple ani end
function Careers() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll(".list li");

    gsap.set(".list", { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set(".list", { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: "center bottom" });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [jobs, setJobs] = useState([]);
  const [location, setLocation] = useState([]);
  const [skills, setSkills] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [srchSkill, setSrchSkill] = useState("");
  const [srchLocation, setSrchLocation] = useState("");

  const getAllJobs = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/allJobs?nocache=true`)
      .then((res) => {
        if (res?.status === 200) {
          setJobs(res.data);
        }
      });
  };
  const getLocations = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/location?nocache=true`)
      .then((res) => {
        if (res?.status === 200) {
          setLocation(res.data);
        }
      });
  };
  const getSkills = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/skills?nocache=true`)
      .then((res) => {
        if (res?.status === 200) {
          setSkills(res.data);
        }
      });
  };
  // slick slider end
  useEffect(() => {
    getAllJobs();
    getLocations();
    getSkills();
  }, []);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent("");
  };

  const handleSearch = () => {
    const payload = {
      keyword,
      skill: srchSkill,
      location: srchLocation,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/searchJobs?nocache=true`,
        payload
      )
      .then((res) => {
        if (res?.status === 200) {
          setJobs(res.data);
        }
      });
  };

  return (
    <>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Jobs, Careers and Opportunities at Procal Technologies" />
	<meta property="og:description" content="Careers Procal&#039;s offers a unique learning and work environment. Your spark is just a step away. Take action, grab the opportunity and demonstrate that you are the right person for the job" />
	<meta property="og:url" content="https://www.procal.ai/careers" />
	<meta property="og:site_name" content="Procal Technologies" />
  </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">Careers</h1>
                  <span class="title--outline green">Careers</span>
                  <span class="title--outline green">Careers</span>
                  <span class="title--outline green">Careers</span>
                  <span class="title--outline green">Careers</span>
                  <span class="title--outline green">Careers</span>
                  <span class="title--outline green">Careers</span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      {/* center section */}

      {/* center section end */}

      <div class="search-job text-center">
        <input
          type="text"
          class="form-control"
          placeholder="Skills, Job Title, Designation"
          onChange={(e) => setKeyword(e.target.value)}
        />
        <select
          placeholder="Location"
          class="form-control"
          onChange={(e) => setSrchLocation(e.target.value)}
        >
          <option>Select Location</option>
          {location.map((loc) => (
            <option>{loc.name}</option>
          ))}
        </select>
        <select
          placeholder="Experience"
          class="form-control"
          onChange={(e) => setSrchSkill(e.target.value)}
        >
          <option>Select Skills</option>
          {skills.map((sk) => (
            <option>{sk.name}</option>
          ))}
        </select>
        {/* <input type="text" class="form-control" placeholder="Location" /> */}
        {/* <input type="text" class="form-control" placeholder="Experience" /> */}
        <input
          type="button"
          class="btn btn-primary"
          value="Search"
          onClick={() => handleSearch()}
        />
      </div>

      <section id="jobs">
        <div class="container">
          <h5>RECENT JOB UPDATES</h5>
          {jobs.map((job) => (
            <div class="company-details">
              <div class="job-update">
                <h4 className="title-box-yellow">
                  <strong> {job?.title} </strong>
                </h4>
                <h6>{job?.model}</h6>
                <i class="fa fa-briefcase"></i>
                <span>{job?.experiance} years</span>
                <br />
                <i class="fa fa-inr"></i>
                <span class="price"> {job?.price} P.A</span>
                <br />
                <i class="fa fa-map-marker"></i>
                {job?.locations?.map((loc) => (
                  <span class="location"> {loc?.name}</span>
                ))}
                <p>
                  <strong>Skills</strong>{" "}
                  <i class="fa fa-angle-double-right"></i>
                  {job?.skills?.map((sk) => (
                    <small> {sk?.name}</small>
                  ))}
                </p>
                <p class="description">
                  <strong>Description</strong>{" "}
                  <i class="fa fa-angle-double-right"></i>
                  <p
                    dangerouslySetInnerHTML={{ __html: job?.responsibilities }}
                  ></p>
                  {/* <a href="#"> Read More</a> */}
                </p>
              </div>
              <div class="apply-btn bg-white" id="career">
                <Link to={`/modal/${job.jobId}`} >
                <button class="button-17" role="button">View And Apply</button>
                </Link>

         
              </div>
            </div>
          ))}

          {/* <ul class="pagelink text-center">
            <li class="right-arrow">&#8592;</li>
            <li class="active">1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li class="right-arrow">&#8594;</li>
          </ul> */}
        </div>
      </section>

      {/* whats new section end */}
      <Book/>
      <Footer />
    </>
  );
}

export default Careers;
