import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import girl from './../../images/building-operation2.png'
import nextjn from './../../images/next-generation-resilience.jpg'
import fea2 from './../../images/resilience-assesment.jpg'
import cloud from './../../images/cloud-solution.jpg'
import Book from '././../solutions/book';
import cutting from './../../images/next-generation-resilience.jpg'
import Slider from "react-slick";

import Footer from './../../common/Footer'
import './../fintech/finser.css'


// apple ani end
function BuildingOperational() {



    const imageRef = useRef(null);
    const ref = useRef(null);

    useGSAP(() => {

        gsap.registerPlugin(ScrollTrigger);

        const targets = document.querySelectorAll('.list li');

        gsap.set('.list', { autoAlpha: 1 });

        const timeline = gsap.timeline({
            defaults: {
                overwrite: 'auto',
                ease: 'power1',
            },
            scrollTrigger: {
                end: '+=300%',
                pin: true,
                pinSpacing: true,
                scrub: true,
                start: 'top top',
                trigger: '.list',
            },
        });

        const stagger = 0.8;
        const duration = 1;

        gsap.set('.list', { autoAlpha: 1 });

        timeline.set(targets, { transformOrigin: 'center bottom' });

        timeline
            .from(targets, {
                duration: duration,
                opacity: 0,
                rotationX: 0,
                rotationY: 0,
                scale: 1,
                stagger: stagger,
                yPercent: 90,
            })
            .to(
                targets,
                {
                    duration: duration,
                    opacity: 0,
                    rotationX: 20,
                    rotationY: 2,
                    scale: 0.75,
                    stagger: stagger,
                    y: -100,
                },
                stagger
            );

        // Animation paragraph

        const element = ref.current;

        const pl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: 'top bottom-=20%',
                end: 'bottom top-=20%',
                scrub: true,
            },
        });

        pl.from(element, {
            opacity: 0,
            x: -100,

            duration: 1,
            ease: 'power2.out',
        });


        // Animation for the image reveal effect
        gsap.from(imageRef.current, {
            clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
            duration: 0.5, // Adjust duration
            ease: 'power4.in', // Adjust easing
            scrollTrigger: {
                trigger: '.erer',
                start: "top bottom",
                end: ".z-index-fyv",
                scrub: true,
                onUpdate: self => {
                    const progress = self.progress;
                    const points = [
                        `0% 0%`,
                        `${progress * 100}% 0%`,
                        `${progress * 100}% 100%`,
                        `0% 100%`
                    ];
                    gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
                },
            },
        });




        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.section-head-outline',
                start: 'top top',
                end: 'bottom top',
                scrub: 0.2,
            },
            ease: 'power.inOut',
        });

        tl.to('.title-outline', {
            yPercent: 100,
        })
            .to('.title--hello', {
                yPercent: 100,
            }, 0)
            .to('.title--outline', {
                yPercent: (i) => (i + 1) * 15 + 100,
            }, 0)
            .from('.image-box img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)
            .from('.image-box0 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box0",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)


            .from('.image-box1 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box1",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box2 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box2",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box3 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box3",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0);



    });

    // slick slider code start
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings2 = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        waitForAnimate: false
    };
    // slick slider end
    useEffect(() => {


    });




    return (
        <>


<Helmet>
<title>Building operational Resilience Across Financial Services  </title>
<meta name="description" content="By adopting these comprehensive measures, your organization can build and maintain a robust operational resilience framework that not only safeguards against potential threats but also ensures continued growth and stability in the face of adversity." />
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Building operational Resilience Across Financial Services" />
	<meta property="og:description" content="By adopting these comprehensive measures, your organization can build and maintain a robust operational resilience framework that not only safeguards against potential threats but also ensures continued growth and stability in the face of adversity." />
	<meta property="og:url" content="https://www.procal.ai/solutions/building-operational-resilience/" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
            <Menu />




            <section className='relative1  five-sec-2 start'>


                <div className='container'>
                    <div class="outline-box">
                        <section class="section-outline section-head-outline">
                            {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


                            <div className='col-lg-7'>
                                <div class="section-head__title flex-basis-666">

                                    <h1 class="title-outline">
                                    Building  
                                    </h1>
                                    <h2 class="title-outline">
                                    Operational 
                                    </h2>
                               
                                    <h2 class="title-outline">
                                    Resilience
                                    </h2>
                                    <span class="title--outline green">
                                    Resilience
                                    </span>
                                    <span class="title--outline green">
                                    Resilience
                                    </span>
                                    <span class="title--outline green">
                                    Resilience
                                    </span>
                                    <span class="title--outline green">
                                    Resilience
                                    </span>
                                    <span class="title--outline green">
                                    Resilience
                                    </span>
                                    <span class="title--outline green">
                                    Resilience
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='sidetext'>
                                    <div class="image-box">
                                        <img src={girl} className='img-fluid' />
                                    </div>
                                </div></div>


                        </section>
                    </div>





                </div>


            </section>

            <section className="container iot-sec4 ptop  pb-5 erer">
                <div class="container">

                    <div class="row d-flex align-items-center1">


                        <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
                            <img
                                ref={imageRef}
                                src={cloud}
                                alt="Your Image"
                                className="img-fluid"
                            />
                        </div>

                        <div class="col-md-8 col-lg-8">
                            <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Operational Resilience Assessment</h2>
                                <ul className='serlist'>
<li>Identify Weaknesses: Uncover and address vulnerabilities within your IT infrastructure,
personnel, processes, and third-party relationships to prevent potential disruptions and
enhance overall resilience.</li>
<li>Preparedness Evaluation: Conduct a thorough assessment of your organization&#39;s
readiness against industry best practices and regulatory standards. This evaluation
provides a detailed and actionable roadmap for strengthening your resilience measures.</li>
                                </ul>
                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Business Continuity &amp; Disaster Recovery (BCDR) Planning</h2>
                                <ul className='serlist'>
                               <li>Strategic Planning: Develop a comprehensive Business Continuity and Disaster
Recovery plan that clearly defines roles, responsibilities, and procedures for effectively
managing and mitigating disruptions. Ensure that your organization is prepared to
respond swiftly and efficiently to any crisis.</li> 
<li>Robust Solutions: Implement advanced backup and recovery solutions to guarantee the
rapid restoration of critical services. Ensure that these solutions are reliable, regularly
tested, and capable of minimizing downtime and data loss during unexpected events.</li> 
                                </ul>

                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Enhanced Resilience Measures</h2>
                                <ul className='serlist'>

<li>Continuous Monitoring: Establish continuous monitoring systems to detect and address
potential threats in real-time, ensuring your organization remains vigilant and prepared
for any eventuality.</li>

<li>Regular Training: Conduct regular training and simulations for your staff to ensure they
are well-versed in the procedures and protocols of your Business Continuity and Disaster
Recovery plan.</li>

<li>Collaboration with Stakeholders: Foster strong collaboration with internal and external
stakeholders, including third-party vendors, to ensure cohesive and coordinated responses
during disruptions.</li>
                                </ul>


 





                            </div>
                        </div>

                        <div class="col-md-4 col-lg-4 image-box">
                            <img src={fea2} className='img-fluid' />
                        </div>


                    </div>
                </div>
            </section>
            <section className="container-rom container-fin ali-fxl">
                <div className="container-d1s">
                    <div className="container-rom container-2e2">
                        <div className="container-d1s">
                            <div className="wrapper-ti2">
                                <div className="column-o8o column-fin">
                                    <section className="container-rom container-coh ani-lcm fa-6jh">
                                        <div className="container-d1s" style={{ backgroundColor: '#1b1b42', padding: '28px', borderRadius: '25px', color: '#fff' }}>
                                            <h3 className="gb-headline-6nz white-color">By adopting these comprehensive measures, your organization can build and maintain a robust
operational resilience framework that not only safeguards against potential threats but also
ensures continued growth and stability in the face of adversity.</h3>


                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>







            {/* whats new section */}
            <section className="container sec10 py-5 p-5 pb-5">
                <div className="row py-lg-5">
                    <div className="col-lg-12 col-md-8 mx-auto">


                        <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


                    </div>


                </div>

                {/* slicky slider */}
                <div className="slider-container">

                    <Slider {...settings2}>



                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Building Operational" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Next-Generation Resilience Technologies</h4>

                                    <p>Procal is at the forefront of implementing state-of-the-art backup and recovery systems, leveraging our proprietary technologies to provide unparalleled protection. We deploy advanced continuous monitoring solutions that proactively detect and mitigate threats, keeping your organization one step ahead of potential disruptions. Our resilience measures are designed with scalability in mind, ensuring that as your business grows, your protection grows with it, providing long-term security and adaptability.
                                    </p>

                                </div>


                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Building Operational" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Customized Training and Simulation Programs</h4>

                                    <p>We believe that true resilience comes from a well-prepared team. That's why we develop bespoke training modules that engage your staff in realistic scenario simulations, honing their skills in a safe environment. Our adaptive curriculum is continuously updated to address emerging threats and industry changes, ensuring your team is always prepared for the latest challenges. We utilize data-driven insights to refine and improve your organization's response capabilities over time, creating a culture of continuous improvement in resilience.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="Building Operational" />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Stakeholder Collaboration Framework</h4>

                                    <p>Recognizing that resilience extends beyond your immediate organization, we help you create a robust network of internal and external stakeholders for coordinated resilience efforts. We establish clear, efficient communication protocols to ensure swift response during crises. Our comprehensive strategies for vendor risk management help you mitigate risks associated with third-party relationships, creating a resilient ecosystem that supports your business objectives.</p>

                                </div>
                            </div>
                        </div>
                        



                    </Slider>
                </div>

                {/* slicky slider end */}



            </section>


            {/* whats new section end */}


            <Book/>

            <Footer />
        </>
    )
}

export default BuildingOperational
