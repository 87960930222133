import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Book from './book';
import Footer from './../../common/Footer'
import girl from './../../images/vul-top.jpg'

import fea2 from './../../images/vul-left.jpg'
import fea4 from './../../images/vul-center.jpg'

import Slider from "react-slick";
import './../solutions/managed-security.css'
import './../solutions/vulnerability.css'


import cloud from './../../images/network-ana.jpg'

import cutting from './../../images/vul-slide.jpg'


// apple ani end
function Vulnerability() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)

.from('.image-box-center img', {
    yPercent: 15,
    ease: "none",
    scrollTrigger: {
    trigger: ".outline-box-center",
    start: "top center",
    scrub: 1,
    
    },
    ease: 'power.inOut',
    }, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Vulnerability Management | Procal" />
	<meta property="og:description" content="Vulnerability Management is a vital practice that involves systematically identifying, assessing, prioritizing, and mitigating security vulnerabilities in an organization's IT infrastructure and software applications. This proactive approach is crucial for safeguarding sensitive data, defending against cyber threats, and ensuring compliance with industry regulations." />
	<meta property="og:url" content="https://www.procal.ai/vulnerability" />
	<meta property="og:site_name" content="Procal Technologies" />
        </Helmet>

<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
Vulnerability
</h1>

<h2 class="title-outline">
Management
</h2>
<span class="title--outline green">
Management
</span>
<span class="title--outline green">
Management
</span>
<span class="title--outline green">
Management
</span>
<span class="title--outline green">
Management
</span>
<span class="title--outline green">
Management
</span>
<span class="title--outline green">
Management
</span>
</div>
</div>
<div className='col-lg-5'>
<div className='sidetext'>
<div class="image-box">
<img src={girl} className='img-fluid' />     
</div>
</div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
<div class="container">

<div class="row d-flex align-items-center1">


<div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
<img
ref={imageRef}
src={cloud}
alt="Your Image"
className="img-fluid"
/>
</div>

<div class="col-md-8 col-lg-8">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Vulnerability Management</h2>
<p>Vulnerability Management is a vital practice that involves systematically identifying, assessing, prioritizing, and mitigating security vulnerabilities in an organization's IT infrastructure and software applications. This proactive approach is crucial for safeguarding sensitive data, defending against cyber threats, and ensuring compliance with industry regulations.</p>

<p>As a leading provider of innovative Vulnerability Management solutions, Procal offers a comprehensive suite of tools and technologies to enhance the security of your organization's digital assets. Our solutions empower businesses to identify and address vulnerabilities efficiently, thereby minimizing exposure to potential threats and fortifying overall security.</p>
<p>By adopting a proactive Vulnerability Management approach, organizations can reduce the risk of data breaches and cyberattacks, maintain a robust security posture, and comply with industry-specific security standards.</p>






</div>
</div>

<div class="col-md-4 col-lg-4 image-box">
<img src={fea2} className='img-fluid'  />
</div>

<div class="col-md-12 col-lg-12">






</div>
</div>
</div>
</section>

{/* center section */}
<div class="container-rze u-position-21c">
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <h2 class="style-GAVo4" id="style-GAVo4">
                <div class="1text-vn6 sidefont" >Leading provider of </div>
                <div class="1snipcss0-4-5-7 sidefont" > innovative Vulnerability</div>
                <div class="1text-ggq sidefont"> Management solutions</div>
            </h2>
        </div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-z42">
            <h6 class="des-5xpt-ggq" >Rrobust security posture, and comply with industry-specific security standards.</h6>
        </div>
    </div>
    <div class="row-8oo">
        <div class="col-wjg col-b63"></div>
        <div class="col-wjg col-b63">
            <div class="u-img-a9d image-box-center"><img src={fea4} class="u-img-hcf" /></div>
        </div>
        <div class="col-wjg col-b63"></div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-s8h" ></div>
        <div class="col-wjg col-e7o">
            <p class="style-CgO1k" id="style-CgO1k"></p>
            <div class="text-vn6 ygqvp" >security </div>
            <div class="text-vn6 ygqvp" >professionals </div>
            <div class="text-vn6 ygqvp" >excel </div>
            <div class="text-vn6 ygqvp" >in vulnerability</div>
            <div class="text-vn6 ygqvp" >scanning</div>
            <p></p>
        </div>
    </div>
    <div class="row-jsz u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <p class="style-RWfkB" id="style-RWfkB"></p>
            <div class="ygqvp text-ggq" >from start</div>
            <div class="ygqvp" >to finish.</div>
            <p></p>
        </div>
    </div>
</div>
{/* center section end */}
<section className="container-rom container-vul ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fhi">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
<h2 className="gb-headline-6nz">Vulnerability scanning and assessment</h2>
<p className="col-oe2" style={{ fontSize: 16 }}>
Procal's experienced security professionals excel in vulnerability scanning and assessment, using cutting-edge tools and methodologies to identify security weaknesses across networks, applications, and systems. Through thorough vulnerability assessments, we pinpoint potential risks, classify them by severity, and enable organizations to prioritize remediation efforts. By identifying vulnerabilities before exploitation, we assist organizations in staying ahead of cyber threats and maintaining data integrity.
</p>



</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Identification</h4>

<p>Procal initiates the process by actively discovering and cataloging potential vulnerabilities in the organization's networks, systems, and applications. This involves utilizing automated scanning tools, conducting manual assessments, and maintaining continuous monitoring.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Assessment</h4>

<p>Once identified, Procal assesses vulnerabilities to comprehend their severity and potential impact on the organization's security. This includes evaluating the risk associated with each vulnerability and determining the likelihood of exploitation.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Prioritization</h4>

<p>Recognizing that not all vulnerabilities pose the same level of risk, Procal prioritizes them based on factors such as severity, potential impact, and the likelihood of exploitation. This enables organizations to focus on addressing the most critical issues first.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Mitigation</h4>

<p>Following prioritization, Procal assists organizations in developing and implementing strategies to mitigate or remediate vulnerabilities. This may involve applying security patches, reconfiguring systems, updating software, or introducing additional security controls to reduce the risk of exploitation.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Verification</h4>

<p>Procal emphasizes the importance of verifying that implemented mitigations are effective and successfully address identified vulnerabilities. This often entails retesting systems to ensure that the security measures in place are functioning as intended.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Monitoring and Continuous Improvement</h4>

<p>Procal's expertise ensures that Vulnerability Management remains an ongoing, adaptive process. Continuous monitoring of the IT environment for new vulnerabilities, changes in the threat landscape, and emerging risks is vital, allowing organizations to adjust their strategies and stay ahead of evolving cybersecurity threats.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Reporting and Documentation</h4>

<p>Procal excels in maintaining detailed records of identified vulnerabilities, assessments, prioritization decisions, and mitigation efforts. Comprehensive reporting provides valuable insights into the organization's security posture, aids in compliance efforts, and supports effective communication with stakeholders.</p>


</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Vulnerability"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Vulnerability Management program</h4>

<p>Procal aids organizations in implementing a proactive Vulnerability Management program, strengthening cybersecurity resilience, and reducing the risk of successful cyberattacks. Leveraging expertise in vulnerability scanning, risk prioritization, compliance, and continuous monitoring, Procal ensures organizations are well-equipped to tackle security threats. Our solutions provide assurance, enabling businesses to concentrate on core objectives while upholding a secure digital environment.</p>


</div>
</div>
</div>
</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}



<Book/>
<Footer />
</>
)
}

export default Vulnerability
