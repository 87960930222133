import React from 'react';
import Menu from '../../common/Menu';
import  { useEffect } from 'react';
import Footer from '../../common/Footer'
import './telecom.css';
import tone from './../../images/tone.png'
import sme from './../../images/smes.png'
import emerg from './../../images/emreg-tech.avif'
import auto from './../../images/automation-left.jpg'
import iphone from './../../images/iphone-mock.png'
import telcometone from './../../images/telecom-tone.png'
import Location from '../../common/contact/Location';
import cybersml from './../../images/lock.jpg'
import fintect from './../../images/fintech-solution.jpg'
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Map from '../../subpages/Map';
import Book from './book';
function Financial() {


    
  
return (
<>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Financial IT Services: Digital Transformation in Finance | Procal" />
	<meta property="og:description" content="By embracing technology modernization and automation, your organization can achieve significant improvements in operational efficiency, security, and innovation" />
	<meta property="og:url" content="https://www.procal.ai/solutions/financial-services/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>
<Menu />



<section id="content-1" class="telecom-sec-7 pt100 pb-5">
<div class="container" id='finantech'>
<div class="row d-flex align-items-center">


<div class="col-md-6 col-lg-6 order-last order-md-2">
<div class="txt-block left-column wow fadeInRight white-color" >


<h2 class="h2-xs" >Finantech Solutions</h2>

<h5>At Finantech, we are committed to delivering comprehensive and innovative solutions tailored to meet the complex needs of today’s financial institutions. Our expert team combines industry knowledge with cutting-edge technology to help your organization thrive in an ever-evolving landscape. Explore our suite of services designed to enhance your operational efficiency, security, and regulatory compliance.
</h5>
<button class="pill" type="button"> <Link to="/solutions/building-operational-resilience/">Building Operational Resilience</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/ffiec-assessment/">FFIEC Assessment</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/financial-services/">Financial Services</Link></button>
<button class="pill" type="button"> <Link to="/solutions/regulatory-remediation/">Regulatory Remediation</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/robust-controls-design/">Robust Controls Design</Link></button>
<button class="pill" type="button"> <Link to="/solutions/technology-modernization-and-automation/">Technology Modernization and Automation</Link></button>


</div>
</div>

<div className="col-md-5 col-lg-6">
    <div className="rel img-block left-column video-preview wow fadeInRight imgadjust1">
      <img
        src={fintect}
        alt="video-preview"
        className="img-fluid "
      />
    </div>
  </div>



</div>



</div>
</section>

<Book/>
<Footer />
</>
)
}

export default Financial
