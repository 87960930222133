import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import girl from './../../images/robust-lead.png'
import Book from '././../solutions/book';
import fea2 from './../../images/risk-factor.jpg'

import cloud from './../../images/cloud-solution.jpg'

import cutting from './../../images/stream-line.jpg'
import Slider from "react-slick";

import Footer from './../../common/Footer'
import './../fintech/finser.css'


// apple ani end
function RobustControls() {



    const imageRef = useRef(null);
    const ref = useRef(null);

    useGSAP(() => {

        gsap.registerPlugin(ScrollTrigger);

        const targets = document.querySelectorAll('.list li');

        gsap.set('.list', { autoAlpha: 1 });

        const timeline = gsap.timeline({
            defaults: {
                overwrite: 'auto',
                ease: 'power1',
            },
            scrollTrigger: {
                end: '+=300%',
                pin: true,
                pinSpacing: true,
                scrub: true,
                start: 'top top',
                trigger: '.list',
            },
        });

        const stagger = 0.8;
        const duration = 1;

        gsap.set('.list', { autoAlpha: 1 });

        timeline.set(targets, { transformOrigin: 'center bottom' });

        timeline
            .from(targets, {
                duration: duration,
                opacity: 0,
                rotationX: 0,
                rotationY: 0,
                scale: 1,
                stagger: stagger,
                yPercent: 90,
            })
            .to(
                targets,
                {
                    duration: duration,
                    opacity: 0,
                    rotationX: 20,
                    rotationY: 2,
                    scale: 0.75,
                    stagger: stagger,
                    y: -100,
                },
                stagger
            );

        // Animation paragraph

        const element = ref.current;

        const pl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: 'top bottom-=20%',
                end: 'bottom top-=20%',
                scrub: true,
            },
        });

        pl.from(element, {
            opacity: 0,
            x: -100,

            duration: 1,
            ease: 'power2.out',
        });


        // Animation for the image reveal effect
        gsap.from(imageRef.current, {
            clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
            duration: 0.5, // Adjust duration
            ease: 'power4.in', // Adjust easing
            scrollTrigger: {
                trigger: '.erer',
                start: "top bottom",
                end: ".z-index-fyv",
                scrub: true,
                onUpdate: self => {
                    const progress = self.progress;
                    const points = [
                        `0% 0%`,
                        `${progress * 100}% 0%`,
                        `${progress * 100}% 100%`,
                        `0% 100%`
                    ];
                    gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
                },
            },
        });




        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.section-head-outline',
                start: 'top top',
                end: 'bottom top',
                scrub: 0.2,
            },
            ease: 'power.inOut',
        });

        tl.to('.title-outline', {
            yPercent: 100,
        })
            .to('.title--hello', {
                yPercent: 100,
            }, 0)
            .to('.title--outline', {
                yPercent: (i) => (i + 1) * 15 + 100,
            }, 0)
            .from('.image-box img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)
            .from('.image-box0 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box0",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)


            .from('.image-box1 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box1",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box2 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box2",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box3 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box3",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0);



    });

    // slick slider code start
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings2 = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        waitForAnimate: false
    };
    // slick slider end
    useEffect(() => {


    });




    return (
        <>
        <Helmet>
<title>Robust Control Design Software Solutions | Finance Solutions</title>
<meta name="description" content="By leveraging our robust controls design services, your organization can achieve a heightened level of security, ensure compliance with regulatory standards, and operate more efficiently." />


<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Robotic Process Automation Solutions | RPA Technology | Procal" />
	<meta property="og:description" content="We deliver cutting-edge robotic solutions tailored to your needs. Unlock innovation and efficiency with our advanced robotics technology. Contact us Today!" />
	<meta property="og:url" content="https://www.procal.ai/solutions/robust-controls-design/" />
	<meta property="og:site_name" content="Procal Technologies" />
</Helmet>
            <Menu />




            <section className='relative1  five-sec-2 start'>


                <div className='container'>
                    <div class="outline-box">
                        <section class="section-outline section-head-outline">
                            {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


                            <div className='col-lg-7'>
                                <div class="section-head__title flex-basis-666">

                                    <h1 class="title-outline">
                                    Robust  
                                    </h1>
                                    <h2 class="title-outline">
                                    Control 
                                    </h2>
                               
                                    <h2 class="title-outline">
                                    Design
                                    </h2>
                                    <span class="title--outline green">
                                    Design
                                    </span>
                                    <span class="title--outline green">
                                   Design
                                    </span>
                                    <span class="title--outline green">
                                    Design
                                    </span>
                                    <span class="title--outline green">
                                   Design
                                    </span>
                                    <span class="title--outline green">
                                    Design
                                    </span>
                                    <span class="title--outline green">
                                   Design
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='sidetext'>
                                    <div class="image-box">
                                        <img src={girl} className='img-fluid' />
                                    </div>
                                </div></div>


                        </section>
                    </div>





                </div>


            </section>

            <section className="container iot-sec4 ptop  pb-5 erer">
                <div class="container">

                    <div class="row d-flex align-items-center1">


                        <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
                            <img
                                ref={imageRef}
                                src={cloud}
                                alt="Your Image"
                                className="img-fluid"
                            />
                        </div>

                        <div class="col-md-8 col-lg-8">
                            <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Identify &amp; Mitigate Risks</h2>
                                <ul className='serlist'>
                                <li>Comprehensive Risk Assessment: We begin by conducting a thorough assessment of
your unique vulnerabilities, both internal and external, to pinpoint critical risks. Our
experts analyze factors such as your operational processes, technology infrastructure, and
external threat landscape. This comprehensive analysis allows us to identify potential
weaknesses and areas of exposure.</li>

<li>Tailored Controls Implementation: Based on our detailed analysis, we design and
implement effective controls specifically tailored to safeguard your data, systems, and
operations. These controls are crafted to address specific threats and minimize potential
impact, ensuring a fortified defense against cyber risks. Our solutions are designed to be
proactive, providing robust protection against both known and emerging threats.</li>
                                </ul>
                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Compliance &amp; Efficiency</h2>
                                <ul className='serlist'>
 <li>Regulatory Alignment: Our controls are not only designed to mitigate risks but also to
promote operational efficiency and ensure compliance with relevant regulations. Whether
it&#39;s data privacy laws, access management standards, or industry-specific compliance
requirements, our solutions are customized to your specific risk profile. By aligning
controls with regulatory frameworks, we help you avoid costly penalties and enhance
your organization’s reputation for security and reliability.</li>

<li>REfficiency Enhancement: In addition to compliance, our controls are designed to
streamline your operations, reducing redundancies and improving overall efficiency. This
dual focus ensures that your organization remains secure while also operating at peak
performance.</
li>
                                </ul>

                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Seamless Integration &amp; Optimization</h2>
                                <ul className='serlist'>

                                <li>Smooth Integration: We ensure that the integration of new controls into your existing
workflows is smooth and minimally disruptive. Our implementation process is designed
to work seamlessly with your current operations, allowing for a quick and efficient
transition. We take into account your organization&#39;s unique operational dynamics to
ensure that the new controls enhance rather than hinder your business processes.</li>

<li>Ongoing Monitoring &amp; Optimization: To further optimize your security posture, we
offer optional ongoing monitoring services. This continuous oversight allows you to
adapt and refine controls over time, responding to emerging threats and evolving
regulatory requirements. Our proactive approach ensures your controls remain effective
and your operations stay resilient in the face of new challenges.</li>

<li>Continuous Improvement: We provide regular assessments and updates to your
controls, ensuring they evolve with your organization and the threat landscape. This
commitment to continuous improvement helps maintain a high level of security and
compliance, providing long-term protection and peace of mind.</li>
                                </ul>


                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Comprehensive Security Solution</h2>
                                <ul className='serlist'>

<li>Holistic Approach: By focusing on these key areas, our robust controls design services
provide a comprehensive solution that enhances your security, compliance, and
operational efficiency. We offer a holistic approach that integrates risk management,
compliance, and operational excellence, ensuring that your organization is well-protected
and positioned for sustained success.</li>
<li>Expert Support: Our team of experts is dedicated to supporting your organization
throughout the entire process, from initial assessment to ongoing management. We
provide the expertise and resources needed to maintain a robust</li>
                                </ul>





                            </div>
                        </div>

                        <div class="col-md-4 col-lg-4 image-box">
                            <img src={fea2} className='img-fluid' />
                        </div>


                    </div>
                </div>
            </section>
            <section className="container-rom container-fin ali-fxl">
                <div className="container-d1s">
                    <div className="container-rom container-2e2">
                        <div className="container-d1s">
                            <div className="wrapper-ti2">
                                <div className="column-o8o column-fin">
                                    <section className="container-rom container-coh ani-lcm fa-6jh">
                                        <div className="container-d1s" style={{ backgroundColor: '#1b1b42', padding: '28px', borderRadius: '25px', color: '#fff' }}>
                                            <h3 className="gb-headline-6nz white-color">By leveraging our robust controls design services, your organization can achieve a heightened
level of security, ensure compliance with regulatory standards, and operate more efficiently. Our
solutions are designed to provide lasting value, helping you navigate the complex landscape of
cybersecurity with confidence.</h3>


                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>






{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Risk Management </h4>

<p>Our team conducts an in-depth analysis of your vulnerabilities, examining both internal processes and external threats. This helps us uncover critical risks and areas needing attention. </p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Custom Security Solutions </h4>

<p>We design tailored security measures to protect your data and operations, addressing specific threats while ensuring robust defense against evolving cyber risks. </p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Seamless Implementation </h4>

<p>We prioritize smooth integration of new controls into your workflows, ensuring minimal disruption and enhancing operational efficiency throughout the transition. </p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Adaptation </h4>

<p>Our ongoing monitoring services keep your security posture strong. We regularly assess and refine your controls, adapting to new threats and regulatory changes. </p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Expert Guidance and Support </h4>

<p>With our dedicated team, you gain access to the expertise required for maintaining a strong security framework, allowing you to focus on what you do best.</p>

</div>
</div>
</div>



</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


            <Book/>

            <Footer />
        </>
    )
};

export default RobustControls;
