import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Book from './book';
import Footer from './../../common/Footer'
import girl from './../../images/circuit-manhold.png'
import car from './../../images/circuit-circle.png'

import fea2 from './../../images/robot-automation.jpg'

import abst222 from './../../images/abst-222.jpg'

import Slider from "react-slick";
import './../solutions/robot-processing.css'
import './../solutions/aiml.css'


import cloud from './../../images/cloud-solution.jpg'

import cutting from './../../images/skull.jpg'


// apple ani end
function Aiml() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Security Frameworks &amp; Compliance Solutions | Cybersecurity" />
	<meta property="og:description" content="Procal’s process begins with a thorough assessment of your current security posture and regulatory obligations. We work closely with your team to identify potential gaps and vulnerabilities, ensuring a solid foundation for compliance efforts." />
	<meta property="og:url" content="https://www.procal.ai/application-security/" />
	<meta property="og:site_name" content="Procal Technologies" />



<Helmet>
<title>AI and ML Consulting Services & Solutions | Advanced Analytics</title>
<meta name="description" content="we recognize the immense potential of AI and ML in transforming businesses and driving growth. Our AI/ML driven data insights offer a comprehensive suite of tools and expertise to help businesses fully harness the power of their data and make informed decisions" />
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="AI and ML Consulting Services &amp; Solutions | Advanced Analytics" />
	<meta property="og:description" content="we recognize the immense potential of AI and ML in transforming businesses and driving growth. Our AI/ML driven data insights offer a comprehensive suite of tools and expertise to help businesses fully harness the power of their data and make informed decisions" />
	<meta property="og:url" content="https://www.procal.ai/solutions/ai-ml-insights/" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />
<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">



<h2 class="title-outline">
AI/ML
</h2>
<span class="title--outline green">
AI/ML
</span>
<span class="title--outline green">
AI/ML
</span>
<span class="title--outline green">
AI/ML
</span>
<span class="title--outline green">
AI/ML
</span>
<span class="title--outline green">
AI/ML
</span>
<span class="title--outline green">
AI/ML
</span>
</div>
</div>
<div className='col-lg-5'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={girl} className='img-fluid' />     
                </div>
              </div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center1">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">AI/ML Insights</h2>
                <p>In today's data-driven world, businesses are seeking innovative ways to leverage artificial intelligence (AI) and machine learning (ML) to unlock valuable insights from their data. At Procal, we recognize the immense potential of AI and ML in transforming businesses and driving growth. Our AI/ML driven data insights offer a comprehensive suite of tools and expertise to help businesses fully harness the power of their data and make informed decisions.</p>

                <p>Procal's solutions are built upon cutting-edge techniques and advanced algorithms that enable the extraction of actionable insights from both structured and unstructured data sources. With a team of experienced AI and ML experts, we provide customized and scalable solutions tailored to meet the unique needs of each customer. Our end-to-end solutions cover the entire data pipeline, from data collection and preprocessing to model development and deployment, ensuring a seamless and efficient process.</p>

                <p>Our team provides valuable insights that enable businesses to make data-driven decisions by uncovering hidden patterns, trends, and correlations within their data. This newfound understanding empowers businesses to gain deeper insights into their customers, market dynamics, and operational processes, leading to more informed and strategic decision-making. Additionally, our solutions offer a substantial advantage in improving operational efficiency. Through the automation of repetitive tasks and the implementation of advanced techniques like predictive maintenance and anomaly detection, we help businesses optimize their operations, saving valuable time and resources.</p>

                <p>Enhancing our client’s customer experience is a top priority, and our AI/ML models play a vital role in achieving this goal. By analyzing customer data, our solutions provide valuable insights that enable businesses to personalize their products, services, and marketing efforts, resulting in improved customer satisfaction and loyalty, ultimately driving business growth. Additionally, our AI/ML solutions excel in fraud detection and risk mitigation, analyzing vast amounts of data in real-time to identify suspicious behaviors and empower businesses to take proactive measures.</p>




              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className='img-fluid'  />
            </div>

            <div class="col-md-12 col-lg-12">

            <section class="section-byh u-bg-vpx">
    <div class="container-opd u-position-yg5">
        <div class="row-ctw u-mb-4eh u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-vkd">
                <h2 class="style-dYtmF" id="style-dYtmF">
                    <div class="1text-zog sidefont">AI and ML  in  </div>
                    <div className='sidefont'>transforming  </div>
                    <div class="1text-axz sidefont">Businesses</div>
                </h2>
            </div>
        </div>
        <div class="row-ctw u-mb-4eh u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-zzz">
                <h3 class="des-fpq text-axz2"> The Impact of AI and Machine Learning on Business Transformation</h3>
            </div>
        </div>
        <div class="row-kac">
            <div class="col-zh4 col-lyc"> <div class="u-img-559"><img src={car} class="u-img-6k2" style={{opacity:'0.3'}} ref={imageRef}/></div></div>
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-lyc">
                <div class="u-img-559"><img src={abst222} class="u-img-6k2"  ref={imageRef}/></div>
            </div>
            <div class="col-zh4 col-lyc"></div>
        </div>
  
        <div class="row-ctw u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-vkd">
            
            </div>
        </div>
    </div>
</section>


              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
          
          

              <p>At Procal, we design our AI/ML solutions to be scalable and future-ready, capable of handling increasing data volumes and adapting to evolving business needs. We stay at the forefront of emerging technologies and industry best practices to ensure our solutions can incorporate new data sources and leverage the latest AI/ML techniques. With Procal's AI/ML data driven solutions, businesses can unlock the true value of their data, drive informed decision-making, optimize operations, enhance the customer experience, and effectively mitigate risks.
              
              </p>

              <p>We cater to businesses in diverse industries, including finance, healthcare, e-commerce, and manufacturing, and work closely with each customer, gaining a deep understanding of their unique requirements and crafting tailored solutions that align with their specific business goals.</p>

       


              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-rom container-aiml ali-fxl">
  <div className="container-d1s">
    <div className="container-rom container-2e2">
      <div className="container-d1s">
        <div className="wrapper-ti2">
          <div className="column-o8o column-fhi">
            <section className="container-rom container-coh ani-lcm fa-6jh">
              <div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
                <h2 className="gb-headline-6nz">AI and machine learning (ML)</h2>
                <p className="col-oe2" style={{ fontSize: 16 }}>
                Procal's expertise in feature engineering, NLP, computer vision, anomaly detection, predictive analytics and Deep learning allows us to provide comprehensive and tailored solutions to our clients. By leveraging these areas of expertise, we empower businesses to unlock valuable insights, improve decision-making, and drive growth in their respective industries.
                </p>
    
           
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="AIML"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Feature Engineering</h4>

<p>Feature engineering is a critical process in data analysis that involves transforming raw data into meaningful features for machine learning models. At Procal, we excel in feature engineering techniques, enabling us to extract the most relevant and informative features from your data. By leveraging our expertise in this area, we ensure that the features used in our models capture the essential aspects of your business and lead to accurate and robust insights.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="AIML"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Natural Language Processing (NLP)</h4>

<p>NLP is a field of AI that focuses on the interaction between computers and human language. Procal harnesses the power of NLP to extract insights from textual data, such as customer reviews, social media comments, and documents. By applying advanced NLP techniques, including sentiment analysis, entity recognition, and topic modeling, we help businesses gain valuable insights from text data, enhancing customer understanding and driving informed decision-making.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="AIML"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Computer Vision</h4>

<p>Computer vision involves extracting meaningful information from visual data, such as images and videos. Procal utilizes state-of-the-art computer vision algorithms and deep learning techniques to analyze and interpret visual content. By leveraging computer vision, we assist businesses in tasks such as object recognition, image classification, and video analysis. This enables our clients to unlock valuable insights from visual data, opening up new opportunities for innovation and automation.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="AIML"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Anomaly Detection</h4>

<p>Anomaly detection is a crucial technique for identifying unusual patterns or outliers in data that deviate significantly from the norm. Procal specializes in anomaly detection algorithms that can uncover anomalies and potentially fraudulent activities, even in large-scale and complex datasets. By implementing advanced anomaly detection methods, we help businesses detect and mitigate risks, ensuring the integrity and security of their operations.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="AIML"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Predictive Analytics</h4>

<p>Predictive analytics involves using historical data to make informed predictions and forecasts about future events or trends. Procal leverages cutting-edge predictive analytics techniques, including machine learning and time series analysis, to help businesses anticipate customer behavior, demand patterns, market trends, and other critical factors. By accurately predicting future outcomes, we enable businesses to make proactive decisions, optimize resource allocation, and stay ahead of the competition.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="AIML"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Deep Learning</h4>

<p>Deep Learning is a subfield of machine learning concerned with algorithms inspired by the structure and function of the brain called artificial neural networks. Deep Learning is an important part of its’ broader family which includes deep neural networks, deep belief networks, and recurrent neural networks. At Procal, we have good team of data scientists and engineers who can extrapolate meaningful insights from supervised learning or learning from labeled data sets with neural networks. Whether its autonomous vehicle use cases or XR/VR/AR use cases Procal can help achieve results that was not possible before.</p>

</div>
</div>
</div>

</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default Aiml
