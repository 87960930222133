import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import girl from './../../images/fin-ser-63.png'
import { Helmet } from "react-helmet";
import fea2 from './../../images/industries-left.jpg'
import cloud from './../../images/cloud-solution.jpg'
import Book from '././../solutions/book';
import cutting from './../../images/personalized-financial-services.jpg'
import Slider from "react-slick";

import Footer from './../../common/Footer'
import './../fintech/finser.css'


// apple ani end
function FinancialServices() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>


<Helmet>
<title>Digital Transformation in Financial Services | Procal Technologies </title>
<meta name="description" content="Digital transformation in financial services refers to integrating digital technologies into financial services, enabling the industry to meet. Read more" />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="eSim Technology Solutions | Customised to your needs | Procal" />
	<meta property="og:description" content="Digital transformation in financial services refers to integrating digital technologies into financial services, enabling the industry to meet. Read more" />
	<meta property="og:url" content="https://www.procal.ai/solutions/financial-services/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
financial 
</h1>

<h2 class="title-outline">
services
</h2>
<span class="title--outline green">
services
</span>
<span class="title--outline green">
services
</span>
<span class="title--outline green">
services
</span>
<span class="title--outline green">
services
</span>
<span class="title--outline green">
services
</span>
<span class="title--outline green">
services
</span>
</div>
</div>
<div className='col-lg-5'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={girl} className='img-fluid' />     
                </div>
              </div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center1">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Navigate Financial Complexities with Procal Technologies</h2>
                <p>In a rapidly evolving financial landscape, institutions face constant challenges from geopolitical
shifts, economic fluctuations, complex regulations, cyberattacks, and natural disasters. At Procal
Technologies, we understand these challenges and offer a comprehensive suite of services to help
you adapt swiftly and operate smarter.</p>

<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Proactive FFIEC CAT Assessments</h2>
<ul className='serlist'>
<li>Identify and Prioritize Vulnerabilities: Our FFIEC Cybersecurity Assessment Tool
(CAT) assessments are designed to identify and prioritize cybersecurity vulnerabilities,
enabling you to address them proactively before they escalate into major problems.</li>
<li>Robust Controls Implementation: We design and implement robust controls to
safeguard your data and systems, ensuring compliance with FFIEC regulations. Our
tailored solutions provide a strong defense against potential threats.</li>
<li>Efficient Remediation: Our experts also help remediate any control deficiencies
identified during examinations, ensuring a smooth and efficient resolution process. We
guide you through corrective actions to achieve and maintain compliance seamlessly.</li>
</ul>


              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className='img-fluid'  />
            </div>

      
          </div>
        </div>
      </section>
<section className="container-rom container-fin ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fin">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
<h2 className="gb-headline-6nz">Strengthen Operational Resilience</h2>

<ul className='finul'>
<li><b>Build Robust Resilience Posture:</b> Operational resilience is crucial in a financial
landscape riddled with disruptions. Cyberattacks, natural disasters, and internal IT
failures can cripple your operations, damage customer trust, and expose you to regulatory
scrutiny. Procal Technologies empowers you to build a robust operational resilience
posture, minimizing the impact of these events.</li>

<li><b>Comprehensive Planning and Support:</b> We provide comprehensive planning and
support to ensure your organization can withstand and quickly recover from any
disruption. Our strategies are designed to protect critical functions and maintain business
continuity.</li>
</ul>

</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>


<section className="container-rom container-fin-2 ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fin">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#1b1b42',padding:'28px',borderRadius:'25px' }}>
<h2 className="gb-headline-6nz">Comprehensive Control Design and Implementation</h2>

<ul className='finul'>
<li><b>Strong Risk Management Foundation:</b> Our control design and implementation services
establish a strong foundation for managing risk and ensuring smooth operations across all
departments. We identify critical internal and external risks and design a comprehensive
set of controls tailored to your needs.</li>

<li><b>Enhance Efficiency and Security:</b> These controls enhance operational efficiency,
security, and compliance with relevant regulations, seamlessly integrating into your
existing workflows to minimize disruption. Our goal is to provide solutions that not only
protect but also streamline your operations.</li>

</ul>

</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>

<section className="container-rom container-fin-3 ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fin">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
<h2 className="gb-headline-6nz">Gain a Competitive Edge with Procal Technologies</h2>

<ul className='finul'>
<li><b>Reduce Risk and Enhance Efficiency:</b> Partnering with Procal Technologies gives you a
competitive edge through reduced risk, enhanced efficiency, and peace of mind. Our
expertise helps you navigate complex regulations, efficiently remediate identified issues,
and ensure your technology infrastructure adapts to the ongoing challenges of the
financial landscape.</li>

<li><b>Sustainable Success:</b> We focus on long-term sustainability, helping you build a resilient
organization that can thrive in an ever-changing environment. Our solutions are designed
to support your strategic goals and drive continuous improvement.</li>

</ul>

</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>


<section className="container-rom container-fin-2 ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fin">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#1b1b42',padding:'28px',borderRadius:'25px' }}>
<h2 className="gb-headline-6nz">Discover the Procal Technologies Advantage</h2>

<ul className='finul'>
<li><b>Empower Your Institution:</b> Discover how Procal Technologies can empower your
institution to thrive in a complex and ever-changing financial world. Our comprehensive
services are designed to provide the support and expertise you need to stay ahead of the
curve and achieve lasting success.</li>


</ul>

<h2 className="gb-headline-6nz">Areas of focus:</h2>
<ul className='finul'>
<li>FFIEC Cybersecurity Assessment</li>
<li>Regulatory Remediation</li>
<li>Operational Resilience</li>
<li>Technology Modernization &amp; Automation</li>
<li>Controls Design and Implementation</li>
</ul>
</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>

{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Financial Services" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Innovative Digital Solutions</h4>

<p>Procal Technologies provides state-of-the-art digital banking platforms that enhance user experience and streamline operations. Our solutions are designed to meet the evolving needs of financial institutions, ensuring they remain competitive in a fast-paced environment.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Financial Services" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Robust Security Measures</h4>

<p>We prioritize cybersecurity by implementing comprehensive protection protocols. Procal’s expertise helps safeguard sensitive financial data, ensuring compliance with regulations and building trust with your customers.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Financial Services" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Personalized Financial Services</h4>

<p>Our tailored solutions leverage data analytics to deliver customized banking experiences. By understanding individual customer needs, Procal helps financial institutions foster stronger relationships and drive customer loyalty.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Financial Services" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Continuous Support and Optimization</h4>

<p>Procal is committed to providing ongoing support and continuous improvement for your banking solutions. Our team regularly assesses performance and adapts strategies to keep your operations efficient and secure.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Financial Services" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Predictive Analytics</h4>

<p>Predictive analytics involves using historical data to make informed predictions and forecasts about future events or trends. Procal leverages cutting-edge predictive analytics techniques, including machine learning and time series analysis, to help businesses anticipate customer behavior, demand patterns, market trends, and other critical factors. By accurately predicting future outcomes, we enable businesses to make proactive decisions, optimize resource allocation, and stay ahead of the competition.</p>

</div>
</div>
</div>



</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default FinancialServices
