import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/server-top.png'
import Book from './book';

import fea2 from './../../images/server-left.jpg'
import fea4 from './../../images/server-sec1.svg'

import Slider from "react-slick";
import './../solutions/managed-security.css'
import './../solutions/server-security.css'



import cloud from './../../images/threat-slide.jpg'

import cutting from './../../images/server-slide.jpg'


// apple ani end
function ServerSecurity() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)

.from('.image-box-center img', {
    yPercent: 15,
    ease: "none",
    scrollTrigger: {
    trigger: ".outline-box-center",
    start: "top center",
    scrub: 1,
    
    },
    ease: 'power.inOut',
    }, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Security Frameworks &amp; Compliance Solutions | Cybersecurity" />
	<meta property="og:description" content="Procal’s process begins with a thorough assessment of your current security posture and regulatory obligations. We work closely with your team to identify potential gaps and vulnerabilities, ensuring a solid foundation for compliance efforts." />
	<meta property="og:url" content="https://www.procal.ai/server" />
	<meta property="og:site_name" content="Procal Technologies" />
    </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
Server
</h1>

<h2 class="title-outline">
 Security
</h2>
<span class="title--outline green">
 Security
</span>
<span class="title--outline green">
 Security
</span>
<span class="title--outline green">
 Security
</span>
<span class="title--outline green">
 Security
</span>
<span class="title--outline green">
 Security
</span>
<span class="title--outline green">
 Security
</span>
</div>
</div>
<div className='col-lg-5'>
<div className='sidetext'>
<div class="image-box">
<img src={girl} className='img-fluid' />     
</div>
</div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
<div class="container">

<div class="row d-flex align-items-center1">


<div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
<img
ref={imageRef}
src={cloud}
alt="Your Image"
className="img-fluid"
/>
</div>

<div class="col-md-8 col-lg-8">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Server Security</h2>
<p>Server security is the practice of safeguarding your server environments from a wide range of security threats and vulnerabilities. These threats can include unauthorized access, data breaches, denial-of-service attacks, and vulnerabilities in server configurations. In a world where data integrity and availability are paramount, it is crucial that your server infrastructure be fortified to protect sensitive information, maintain compliance with industry regulations, and ensure the continuous and reliable operation of your digital assets.</p>

<p>At Procal, we understand the multifaceted nature of server security and its pivotal role in the overall success and reliability of your digital infrastructure. We are committed to delivering innovative server security solutions that empower organizations to secure their servers and protect their digital resources.</p>

<p>Our mission is to provide a comprehensive suite of tools, technologies, and expert services that not only identify and address security vulnerabilities but also cultivate a culture of security within your organization. By partnering with Procal, you gain the confidence and assurance that your server infrastructure is equipped to withstand ever-evolving security threats, thereby ensuring the stability and trustworthiness of your digital operations.</p>




</div>
</div>

<div class="col-md-4 col-lg-4 image-box">
<img src={fea2} className='img-fluid'  />
</div>

<div class="col-md-12 col-lg-12">






</div>
</div>
</div>
</section>

{/* center section */}
<div class="container-rze u-position-21c">
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <h2 class="style-GAVo4" id="style-GAVo4">
                <div class="1text-vn6 sidefont" >we understand </div>
                <div class="1snipcss0-4-5-7 sidefont" >the multifaceted</div>
                <div class="1text-ggq sidefont">  nature of server</div>
            </h2>
        </div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-z42">
            <h6 class="des-5xpt-ggq" >Our mission is to provide a comprehensive suite of tools, technologies, and expert services</h6>
        </div>
    </div>
    <div class="row-8oo">
        <div class="col-wjg col-b63"></div>
        <div class="col-wjg col-b63">
            <div class="u-img-a9d image-box-center"><img src={fea4} class="u-img-hcf" /></div>
        </div>
        <div class="col-wjg col-b63"></div>
    </div>
    <div class="row-jsz u-mb-17o u-z-index-vag">
        <div class="col-wjg col-s8h" ></div>
        <div class="col-wjg col-e7o">
            <p class="style-CgO1k" id="style-CgO1k"></p>
            <div class="text-vn6 ygqvp" >Elevate your  </div>
            <div class="text-vn6 ygqvp" >organization's  </div>
            <div class="text-vn6 ygqvp" >security posture</div>
            <p></p>
        </div>
    </div>
    <div class="row-jsz u-z-index-vag">
        <div class="col-wjg col-phw"></div>
        <div class="col-wjg col-e7o">
            <p class="style-RWfkB" id="style-RWfkB"></p>
            <div class="ygqvp text-ggq" >from start</div>
            <div class="ygqvp" >to finish</div>
            <p></p>
        </div>
    </div>
</div>
{/* center section end */}
<section className="container-rom container-server ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fhi">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
<h2 className="gb-headline-6nz">Safeguard your Digital Infrastructure
</h2>
<p className="col-oe2" style={{ fontSize: 16 }}>
Elevate your organization's security posture with Procal's Server Security solutions. Mitigate risks, safeguard sensitive data, ensure server availability, uphold compliance, and fortify the security and reliability of your digital infrastructure. Our comprehensive approach enables you to proactively counter evolving threats, maintaining the utmost standards of security and trust in your server operations.
</p>



</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Server Hardening</h4>

<p>Procal assists in managing and applying security patches and updates to your server infrastructure. Regular patch management is critical to addressing known vulnerabilities and protecting your servers from exploitation.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Security Patch Management</h4>

<p>Procal helps you manage and control the endpoints within your network, ensuring compliance with security policies. We provide tools and expertise to track and monitor device activity, enforce access controls, and maintain a secure environment.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Access Control and Authentication</h4>

<p>We help you establish stringent access controls and robust authentication mechanisms to ensure that only authorized personnel can access your servers. This helps prevent unauthorized access and data breaches.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Intrusion Detection and Prevention</h4>

<p>Procal's solutions include intrusion detection and prevention systems to monitor server activity in real-time, detect suspicious behavior, and respond promptly to potential security incidents.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Security Training</h4>

<p>Our security awareness training equips your team with the knowledge and skills required to foster a culture of security within your organization, making your employees integral to your server security strategy.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Web Application Firewall (WAF)</h4>

<p>Implementing WAF solutions to protect web applications hosted on servers from various cyber threats.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Backup and Disaster Recovery Planning</h4>

<p>Developing robust backup and disaster recovery plans to ensure data integrity and availability in case of security incidents.</p>


</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Compliance and Regulatory Assistance</h4>

<p>We offer guidance and support to ensure that your server security practices align with industry regulations, thereby reducing legal and reputational risks associated with non-compliance.</p>


</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="server security"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Threat Intelligence and Monitoring</h4>

<p>Procal's real-time monitoring and threat intelligence services help organizations detect and respond to server security incidents proactively. Our experts deliver insights into emerging threats and provide actionable measures to safeguard your server infrastructure.</p>


</div>
</div>
</div>


</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default ServerSecurity
