import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import girl from './../../images/technology-globe.png'
import Book from '././../solutions/book';
import fea2 from './../../images/infrastructure-cloud.jpg'
import cloud from './../../images/cloud-solution.jpg'

import cutting from './../../images/cutting-edge-tech-modernization.jpg'
import Slider from "react-slick";

import Footer from './../../common/Footer'
import './../fintech/finser.css'


// apple ani end
function TechnologyModernization() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<title>Technology Modernization and IT Automation Solutions | Procal</title>
<meta name="description" content="By embracing technology modernization and automation, your organization can achieve significant improvements in operational efficiency, security, and innovation." />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Robotic Process Automation Solutions | RPA Technology | Procal" />
	<meta property="og:description" content="With a team of automotive experts at the forefront of their field, we specialize in developing, producing, and building customer-centric solutions that leverage the latest advancements in technology" />
	<meta property="og:url" content="https://www.procal.ai/solutions/technology-modernization-and-automation/" />
	<meta property="og:site_name" content="Procal Technologies" />
</Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
Technology  
</h1>
<h2 class="title-outline">
Modernization   
</h2>
<h2 class="title-outline">
 and  
</h2>
<h2 class="title-outline">
 Automation
</h2>
<span class="title--outline green">
Automation
</span>
<span class="title--outline green">
Automation
</span>
<span class="title--outline green">
Automation
</span>
<span class="title--outline green">
Automation
</span>
<span class="title--outline green">
Automation
</span>
<span class="title--outline green">
Automation
</span>
</div>
</div>
<div className='col-lg-5'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={girl} className='img-fluid' />     
                </div>
              </div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center1">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Upgrade IT Infrastructure</h2>
                <ul className='serlist'>
<li>Enhance Reliability and Scalability: Elevate the performance and dependability of your
financial services operations by upgrading your IT infrastructure. Modernizing your
systems ensures they can efficiently handle increased loads, facilitate faster transaction
processing, and adhere to the latest security protocols. This upgrade also prepares your
infrastructure for seamless integration with cutting-edge technologies such as artificial
intelligence (AI) and machine learning (ML), fostering innovation and growth.</li>

<li>Future-Proof Systems: Invest in infrastructure that not only meets current demands but
is also scalable for future requirements. By adopting modular and flexible systems, your
organization can easily adapt to technological advancements and evolving business
needs.</li>
</ul>
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Automate Processes</h2>
<ul className='serlist'>
<li>Increase Operational Efficiency: Boost productivity and minimize human error by
automating critical financial processes. Automation streamlines routine tasks like
transaction processing, compliance reporting, and customer service, freeing your staff to
focus on strategic and higher-value activities. This leads to more efficient operations and
a significant reduction in operational costs.</li>

<li>Advanced AI and ML Integration: Leverage AI and ML technologies to further
enhance automation efforts. These technologies enable predictive analytics, automated
decision-making, and sophisticated fraud detection, providing deeper insights and
improving overall process efficiency. Implementing AI-driven chatbots can also enhance</li>
</ul>

<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Leverage Cloud Computing</h2>
<ul className='serlist'>

<li>Gain Flexibility and Robust Disaster Recovery: Harness the power of cloud computing
to achieve greater flexibility and robust disaster recovery capabilities. Cloud solutions
offer seamless data access, scalability to meet fluctuating demands, and efficient backup
options, ensuring business continuity and resilience against disruptions.</li>

<li>Enhanced Data Analytics and Cybersecurity: Integrate AI-driven cloud services to
unlock advanced data analytics capabilities, which can provide valuable business insights
and drive informed decision-making. Additionally, cloud-based solutions enhance
cybersecurity measures, protecting sensitive financial data against cyber threats.</li>

<li>Support for Innovative Financial Products: Utilize cloud platforms to develop and
deploy innovative financial products and services. The agility of cloud computing enables
faster time-to-market for new offerings, keeping your organization competitive in a
rapidly evolving financial landscape.</li>
</ul>







              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className='img-fluid' alt='Technology Modernization'  />
            </div>

      
          </div>
        </div>
      </section>
<section className="container-rom container-fin ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fin">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#1b1b42',padding:'28px',borderRadius:'25px',color:'#fff' }}>
<h3 className="gb-headline-6nz white-color">By embracing technology modernization and automation, your organization can achieve
significant improvements in operational efficiency, security, and innovation. This strategic

approach not only addresses current challenges but also positions your business for sustained
success in the future.</h3>


</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>







{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Cutting-Edge Tech Modernization</h4>

<p>Procal Technologies specializes in updating legacy systems to leverage the latest technologies. Our modernization strategies enhance performance, scalability, and security, enabling your organization to stay ahead in a competitive landscape.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Streamlined Automation Solutions</h4>

<p>We implement automation tools that optimize workflows and reduce manual tasks. Procal’s solutions help increase efficiency and accuracy, allowing your team to focus on strategic initiatives rather than routine operations.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Enhanced Data Integration</h4>

<p>Our expertise ensures seamless integration of disparate systems, allowing for real-time data sharing and improved decision-making. Procal’s solutions facilitate a unified view of operations, driving insights and innovation.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Anomaly Detection</h4>

<p>Anomaly detection is a crucial technique for identifying unusual patterns or outliers in data that deviate significantly from the norm. Procal specializes in anomaly detection algorithms that can uncover anomalies and potentially fraudulent activities, even in large-scale and complex datasets. By implementing advanced anomaly detection methods, we help businesses detect and mitigate risks, ensuring the integrity and security of their operations.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Ongoing Support and Adaptation</h4>

<p>Procal is dedicated to providing continuous support post-implementation. We monitor performance and adapt strategies to ensure your technology remains relevant and effective in meeting evolving business needs.</p>

</div>
</div>
</div>



</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default TechnologyModernization
